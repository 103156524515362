import { partition } from "lodash-es";

import { useBackgroundLayers } from "../../../hooks/backgroundLayersHooks";

import { Theme } from "../../../types";
import { BackgroundLayerRenderer } from "../BackgroundLayerRenderer";
import { OverLayerRenderer } from "../OverLayerRenderer";

interface Props {
  currentTheme: Theme;
  themes: Theme[];
}
export function BackgroundLayerTiles(props: Props) {
  const { currentTheme, themes } = props;
  const { data: layers } = useBackgroundLayers();
  const [backgroundLayers, overLayers] = partition(layers, {
    type: "achtergrond",
  });

  return (
    <>
      <BackgroundLayerRenderer backgroundLayers={backgroundLayers} />
      <OverLayerRenderer
        overLayers={overLayers}
        currentTheme={currentTheme}
        themes={themes}
      />
    </>
  );
}
