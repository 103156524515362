import { forwardRef } from "react";
import {
  NavLink as NavLinkBase,
  NavLinkProps,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

interface Props extends NavLinkProps {}

export const NavLink = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const location = useLocation();
  const { to, ...rest } = props;
  const path = useResolvedPath(to);
  const mergedSearch = [
    path.search.split("?").join(""),
    location.search
      .split("?")
      .join("")
      .split("&")
      .filter(
        (param) => !param.startsWith("reffer") && !param.startsWith("?reffer")
      )
      .join("&"),
  ]
    .filter((queryParams) => !!queryParams)
    .join("&");

  const mergedLocation =
    typeof to === "string"
      ? {
          ...location,
          ...path,
          search: mergedSearch ? `?${mergedSearch}` : "",
        }
      : { ...location, ...to };

  return <NavLinkBase to={{ ...mergedLocation }} ref={ref} {...rest} />;
});
