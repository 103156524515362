import { forwardRef, ReactNode, useState } from "react";
import { Portal } from "@mui/material";
import { createControlComponent } from "@react-leaflet/core";
import { Control, ControlOptions, DomEvent, DomUtil } from "leaflet";

interface Props extends ControlOptions {
  children: ReactNode;
  className?: string;
}

export const MapControlBase = forwardRef<Control, Props>((props, ref) => {
  const { children, className, ...controlProps } = props;
  const [isAdded, setIsAdded] = useState(false);
  const [container] = useState<HTMLDivElement>(
    DomUtil.create("div", `custom-control${className ? ` ${className}` : ""}`)
  );

  return (
    <>
      <ControlContainer
        {...controlProps}
        container={container}
        ref={ref}
        setIsAdded={setIsAdded}
      ></ControlContainer>
      {isAdded && <Portal container={container}>{children}</Portal>}
    </>
  );
});

interface ControlContainerProps extends ControlOptions {
  container: HTMLElement;
  setIsAdded: (value: boolean) => void;
}

const ControlContainer = createControlComponent<Control, ControlContainerProps>(
  (props) => {
    const { container, setIsAdded, ...forwardProps } = props;
    const ForwardControl = Control.extend({
      onAdd: () => {
        setIsAdded(true);
        DomEvent.disableClickPropagation(container);
        DomEvent.disableScrollPropagation(container);
        return container;
      },
      onRemove: () => {
        setIsAdded(false);
      },
    });
    return new ForwardControl(forwardProps);
  }
);
