import { useEffect } from "react";

import { useDownloadProgress } from "../../hooks/indicatorDataHooks";

import { DownloadState } from "../../types";

interface Props {
  progressId: number;
  toggleDownloadState: (downloadValue: DownloadState) => void;
}

export function DownloadProgress(props: Props) {
  const { progressId, toggleDownloadState } = props;
  const { data: progress } = useDownloadProgress(progressId);

  useEffect(() => {
    if (progress?.exportStatus === "FINISHED") {
      toggleDownloadState("FINISHED");
    }
  }, [progress, toggleDownloadState]);

  return null;
}
