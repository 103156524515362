import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useLocationFlanders } from "../../hooks/locationHooks";

export function LocationFlandersLink() {
  const { data: flanders } = useLocationFlanders();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const { themeId } = useParams();
  const { t } = useTranslation();

  function generateLink() {
    params.delete("addressMarker");
    if (pathname.includes("/location/")) {
      const [, , , , ...restPath] = pathname.split("/");
      return `/${themeId}/location/${flanders?.id}/${restPath.join(
        "/"
      )}?${params.toString()}`;
    }
    return `location/${flanders?.id}/graphs?${params.toString()}`;
  }

  return (
    <Link
      component={NavLink}
      to={generateLink()}
      sx={{ transform: "translateY(1px)" }}
    >
      {t("label.orViewFlanders")}
    </Link>
  );
}
