import { useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { usePersonalMapSets } from "../../hooks/mapSetHooks";
import { LayerSet, MapSetType, Selection } from "../../types";

import { MapSetPersonalItem } from "./MapSetPersonalItem";
import { MapSetPersonalLayerDialog } from "./MapSetsPersonalLayerDialog";

interface Props {
  mapSetSelections: Selection[];
  onAddMapSet: (mapSet: LayerSet, mapSetType: MapSetType) => void;
  onRemoveMapSet: (mapSetId: number) => void;
  onChangeMapSetOpacity: (mapSetId: number, opacity: number) => void;
}
export function MapSetsPersonalAccordion(props: Props) {
  const {
    mapSetSelections,
    onAddMapSet,
    onRemoveMapSet,
    onChangeMapSetOpacity,
  } = props;
  const { data: fetchedPersonalMapSets } = usePersonalMapSets();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const accordionDetailsRef = useRef<HTMLDivElement | null>();
  const { t } = useTranslation();
  const personalMapSets = fetchedPersonalMapSets || [];

  function scrollToRef() {
    accordionDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  function toggleAccordion() {
    setIsExpanded((prev) => {
      if (prev === false) {
        setTimeout(scrollToRef, 300);
      }
      return !prev;
    });
  }

  return (
    <StyledAccordion
      elevation={0}
      disableGutters
      onChange={toggleAccordion}
      expanded={isExpanded}
    >
      <AccordionSummary expandIcon={<ArrowRight />}>
        <Typography variant="subtitle1">{t("label.myMaps")}</Typography>
      </AccordionSummary>
      <AccordionDetails ref={accordionDetailsRef}>
        <MapSetPersonalLayerDialog />
        {personalMapSets &&
          personalMapSets.map((mapSet) => (
            <MapSetPersonalItem
              key={mapSet.id}
              personalMapSets={personalMapSets}
              mapSetSelections={mapSetSelections}
              mapSet={mapSet}
              onAddMapSet={onAddMapSet}
              onRemoveMapSet={onRemoveMapSet}
              onChangeMapSetOpacity={onChangeMapSetOpacity}
            />
          ))}
      </AccordionDetails>
    </StyledAccordion>
  );
}

const StyledAccordion = styled(Accordion)`
  .MuiAccordionDetails-root {
    min-height: ${({ theme }) => theme.spacing(4)};
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  &.MuiAccordion-root::before {
    visibility: hidden;
  }
  &.MuiAccordion-root {
    background-color: transparent;
  }
  .MuiAccordionSummary-expandIconWrapper {
    transition: transform 320ms ease-out;
    &.Mui-expanded {
      transform: rotate(90deg);
    }
  }

  .MuiAccordionSummary-root {
    padding: 0;
    flex-direction: row-reverse;
  }
`;
