import {
  Checkbox,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { List as VirtualizedList, ListRowProps } from "react-virtualized";

interface Props {
  distinctValues: string[];
  filterValues: string[];
  selectAll: boolean;
  handleFilter: (value: string) => void;
}

export function DataFilterMenuList(props: Props) {
  const { distinctValues, filterValues, selectAll, handleFilter } = props;
  const { t } = useTranslation();
  const rowHeight: number = 45;

  function calcHeight(rowCount: number, rowHeight: number) {
    const height = rowCount * rowHeight;
    if (height > 300) return 300;
    return height;
  }

  const renderRow = ({ index, key, style, parent }: ListRowProps) => (
    <ListItem
      key={key}
      onClick={() => handleFilter(distinctValues[index])}
      style={style}
    >
      <Checkbox
        checked={filterValues.indexOf(distinctValues[index]) > -1 || selectAll}
      />
      <Tooltip title={distinctValues[index]}>
        <Typography noWrap>
          {distinctValues[index]
            ? distinctValues[index]
            : t("label.notApplicable")}
        </Typography>
      </Tooltip>
    </ListItem>
  );

  return (
    <>
      {distinctValues.length > 0 && (
        <VirtualizedList
          height={calcHeight(distinctValues.length, rowHeight)}
          width={300}
          rowRenderer={renderRow}
          rowCount={distinctValues.length}
          rowHeight={rowHeight}
        />
      )}
      {distinctValues.length === 0 && (
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography>{t("label.noResultsFound")}</Typography>}
          />
        </ListItem>
      )}
    </>
  );
}
