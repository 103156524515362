import { Skeleton, Stack } from "@mui/material";

interface Props {}
export function LegendItemSkeleton(props: Props) {
  return (
    <Stack>
      <Skeleton variant="text" height={30} />
      <Skeleton variant="text" height={30} />
    </Stack>
  );
}
