import { Typography } from "@mui/material";
import { partition } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useBackgroundLayers } from "../../../hooks/backgroundLayersHooks";
import { SelectBackgroundLayer } from "./SelectBackgroundLayer";
import { SelectBorderLayer } from "../SelectBorderLayer";

export function BackgroundLayerHandler() {
  const { data: layers } = useBackgroundLayers();
  const { t } = useTranslation();
  const [backgroundLayers, borderLayers] = partition(layers, {
    type: "achtergrond",
  });
  return (
    <>
      {layers && (
        <>
          <Typography>{t("label.backgroundLayer")}</Typography>
          <SelectBackgroundLayer backgroundLayers={backgroundLayers} />
          <Typography>{t("label.borders")}</Typography>
          <SelectBorderLayer borderLayers={borderLayers} />
        </>
      )}
    </>
  );
}
