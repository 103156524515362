import { useState } from "react";
import { styled, Typography, Stack } from "@mui/material";
import { Block } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useCustomLegend } from "../../hooks/legendHooks";
import { Layer } from "../../types";

interface Props {
  layer: Layer;
}

export function LegendItemErrorImageFallBack(props: Props) {
  const { layer } = props;

  const { t } = useTranslation();

  const legendUrl = useCustomLegend(layer);

  const [loadStatus, setLoadStatus] = useState<"init" | "complete" | "error">(
    "init"
  );
  function onLoad() {
    setLoadStatus("complete");
  }

  function onError() {
    setLoadStatus("error");
  }

  return (
    <>
      <StyledImage
        className={`image-fallback ${loadStatus}`}
        onError={onError}
        onLoad={onLoad}
        src={legendUrl}
        alt="legend fallback"
      />
      {loadStatus === "error" && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Block className="error-icon" />
          <Typography>{t("label.noLegendFound")}</Typography>
        </Stack>
      )}
    </>
  );
}

const StyledImage = styled("img")`
  &.init,
  &.completed {
    display: block;
  }

  &.init {
    visibility: hidden;
  }

  &.error {
    display: none;
  }
`;
