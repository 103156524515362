import { Fab, Tooltip } from "@mui/material";
import { ControlOptions, LatLngLiteral } from "leaflet";
import { useTranslation } from "react-i18next";

import { useMapPoint } from "../../hooks/mapHooks";

import { Theme } from "../../types";

import { ReactComponent as PushPin } from "../../assets/icons/map-pin.svg";
import { InfoPopup } from "./info/InfoPopup";
import { MapClickHandler } from "./MapClickHandler";
import { MapControlBase } from "./MapControlBase";

interface Props extends ControlOptions {
  currentTheme: Theme;
  themes: Theme[];
  isActive: boolean;
  toggleActiveMapMode: () => void;
}

export function LayerPointControl(props: Props) {
  const {
    currentTheme,
    isActive,
    themes,
    toggleActiveMapMode,

    ...controlProps
  } = props;
  const [point, setPoint] = useMapPoint();
  const { t } = useTranslation();

  function onClick(latlng: LatLngLiteral) {
    setPoint(latlng);
  }

  return (
    <>
      <MapControlBase {...controlProps}>
        <Tooltip placement="left" title={t<string>("label.pointInformation")}>
          <Fab
            size="small"
            variant="circular"
            onClick={toggleActiveMapMode}
            color={isActive ? "primary" : "default"}
            data-tour="step-5"
          >
            <PushPin />
          </Fab>
        </Tooltip>
      </MapControlBase>
      {isActive && (
        <>
          <MapClickHandler onClick={onClick} />
          {point && (
            <InfoPopup
              point={point}
              currentTheme={currentTheme}
              themes={themes}
            />
          )}
        </>
      )}
    </>
  );
}
