import { LegendSymbolizerPoint } from "../../../hooks/legendHooks";
import { LegendSymbolizerItemPointGraphics } from "./LegendSymbolizerItemPointGraphics";

interface Props {
  symbolizer: LegendSymbolizerPoint;
}

export function LegendSymbolizerItemPoint(props: Props) {
  const { symbolizer } = props;
  return (
    <svg x="0" y="0" width="10" height="10">
      {symbolizer.Point.graphics.map((graphics, i) => (
        <LegendSymbolizerItemPointGraphics
          key={`point-graphics-${i}`}
          graphics={graphics}
        />
      ))}
    </svg>
  );
}
