import { JoyrideContextProvider } from "./JoyrideContext";
import { ReactNode, useContext } from "react";
import ReactJoyride from "react-joyride";
import { JoyrideContext } from "./JoyrideContext";
import { JoyrideTooltip } from "./JoyrideTooltip";
interface Props {
  children: ReactNode;
}

export function JoyrideProvider(props: Props) {
  const { children } = props;
  return (
    <JoyrideContextProvider>
      <JoyrideConsumer>{children}</JoyrideConsumer>
    </JoyrideContextProvider>
  );
}

function JoyrideConsumer(props: Props) {
  const { children } = props;
  const { steps, callbackFunction, stepIndex, runJoyride } =
    useContext(JoyrideContext);

  return (
    <>
      <ReactJoyride
        tooltipComponent={JoyrideTooltip}
        steps={steps}
        stepIndex={stepIndex}
        run={runJoyride}
        callback={callbackFunction}
        showProgress={true}
        continuous
      />
      {children}
    </>
  );
}
