import { Box, styled } from "@mui/material";
interface Props {
  setPortalContainer: (container: HTMLDivElement) => void;
}
export function LoadingBox(props: Props) {
  const { setPortalContainer } = props;
  return <StyledBox ref={(ref: HTMLDivElement) => setPortalContainer(ref)} />;
}

const StyledBox = styled(Box)`
  position: absolute;
  width: 100%;
  z-index: 9999;

  background-color: coral;
`;
