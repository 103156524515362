import { Skeleton, styled } from "@mui/material";

export function LocationSearchSkeleton() {
  return <StyledSkeleton height="70px" width="100%" />;
}

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  max-width: 60vw;
  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 35vw;
  }
  ${({ theme }) => theme.breakpoints.down("xl")} {
    max-width: 40vw;
  }
  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 50vw;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 60vw;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 80vw;
  }
`;
