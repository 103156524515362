import { useState } from "react";
import {
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
  styled,
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";

import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as FlemishLionLogo } from "../../assets/icons/Vlaanderen_leeuwtje-01.svg";

export function VMMFooter() {
  const { t } = useTranslation();
  const [showLinks, setShowLinks] = useState<boolean>(false);
  return (
    <StyledAppBar>
      <div className="first-section">
        <div className="logo-with-text">
          <FlemishLionLogo className="lion" />
          <div className="logo-labels">
            <div className="top">
              <Typography className="logo-main">
                <Trans
                  i18nKey="label.officialWebsiteFlemishGovernment"
                  components={{
                    link1: (
                      <Link
                        className="link"
                        href="https://www.vmm.be/over-vmm"
                        target="_blank"
                      ></Link>
                    ),
                  }}
                />
              </Typography>
              -
              <Link
                className={`more-info-link ${showLinks ? "rotate" : ""}`}
                onClick={() => {
                  setShowLinks((prev) => {
                    if (prev !== true)
                      setTimeout(function () {
                        window.scrollTo({
                          top: document.documentElement.scrollHeight,
                          behavior: "smooth",
                        });
                      }, 200);

                    return !prev;
                  });
                }}
              >
                {t("label.moreInfoFooter")}
                <KeyboardArrowUp />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Collapse in={showLinks}>
        <Typography gutterBottom>
          {t("label.everydayTheVMMWorksOnTheEnvironmentOfTomorrow")}
        </Typography>
        <Divider />
        <List dense disablePadding>
          <ListItem disablePadding>
            <Link href="https://www.vmm.be/over-vmm" target="_blank">
              {t("label.aboutVMM")}
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link href="https://www.vmm.be/disclaimer" target="_blank">
              {t("label.disclaimer")}
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link href="https://www.vmm.be/privacy" target="_blank">
              {t("label.privacy")}
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link href="https://www.vmm.be/cookie-policy" target="_blank">
              {t("label.cookieExplanation")}
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link href="https://www.vmm.be/toegankelijkheid" target="_blank">
              {t("label.access")}
            </Link>
          </ListItem>
        </List>
      </Collapse>
    </StyledAppBar>
  );
}

const StyledAppBar = styled("footer")`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  align-items: flex-start;
  justify-content: space-between;
  color: #333332;
  border-top: 1px solid #cbd2da;
  background-color: ${({ theme }) => theme.palette.background.paper};

  .MuiDivider-root {
    width: 100%;
  }

  .more-info-link {
    display: inline-flex;
    align-items: center;
    color: #0055cc;
    text-transform: uppercase;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    :hover {
      color: #0055cc;
    }
    &.rotate {
      transition: transform 0.8s ease-in-out;

      .MuiSvgIcon-root {
        transform: rotate(180deg);
      }
    }
  }

  .top {
    display: flex;
    flex-direction: row;

    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
  }

  .title {
    margin-right: 20px;
    font-weight: 600;
    font-size: 18px;
  }

  .logo-with-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .link {
    color: #2676e7;
    line-height: 1;
    text-decoration: none;

    :hover {
      color: #0055cc;
    }
  }
  .MuiCollapse-root {
    width: 100%;
    .MuiCollapse-wrapperInner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .MuiList-root {
    display: flex;
    flex-direction: row;
    padding-bottom: ${(props) => props.theme.spacing(1)};

    .MuiListItem-root {
      white-space: nowrap;
      border-right: 1px solid #cbd2da;
      padding: 0 ${(props) => props.theme.spacing(2)};
      :last-child {
        border-right: none;
      }
      :first-of-type {
        padding-left: 0;
      }
      .MuiLink-root {
        font-size: 13px;
        text-decoration: none;
        text-transform: uppercase;
        color: #333332;
        font-weight: 600;
        :hover {
          color: #0055cc;
        }
      }
    }
  }

  .logo-labels {
    display: flex;
    flex-direction: column;
    .logo-main {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .MuiList-root {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  .lion {
    width: auto;
    height: 35px;
    margin-right: 8px;
    fill: #333332;
  }

  .first-section {
    width: 100%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(0.5)};
  }

  @media print {
    display: none;
  }
`;
