import { Link, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";

interface Props {
  content: string;
}

export function StyledMarkdown(props: Props) {
  const { content } = props;
  return (
    <ReactMarkdown
      components={{
        h1: ({ children }) => <Typography variant="h1">{children}</Typography>,
        h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
        h3: ({ children }) => <Typography variant="h3">{children}</Typography>,
        h4: ({ children }) => <Typography variant="h4">{children}</Typography>,
        h5: ({ children }) => <Typography variant="h5">{children}</Typography>,
        h6: ({ children }) => <Typography variant="h6">{children}</Typography>,
        p: ({ children }) => (
          <Typography gutterBottom variant="body1">
            {children}
          </Typography>
        ),
        a: ({ href, children }) => (
          <Link href={href} rel="norefferer noopener" target="_blank">
            {children}
          </Link>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
