import { Stack, Typography } from "@mui/material";

import {
  isLegendSymbolizerLine,
  isLegendSymbolizerPoint,
  isLegendSymbolizerPolygon,
  isLegendSymbolizerRaster,
  isLegendSymbolizerRasterRamp,
  isLegendSymbolizerRasterValues,
  LegendSymbolizer,
  LegendSymbolizerRaster,
} from "../../../hooks/legendHooks";

import { LegendSymbolizerItemLine } from "./LegendSymbolizerItemLine";
import { LegendSymbolizerItemPoint } from "./LegendSymbolizerItemPoint";
import { LegendSymbolizerItemPolygon } from "./LegendSymbolizerItemPolygon";
import { LegendSymbolizerItemRaster } from "./LegendSymbolizerItemRaster";
import { LegendSymbolizerItemRasterRamp } from "./LegendSymbolizerItemRasterRamp";
import { LegendSymbolizerItemRasterValues } from "./LegendSymbolizerItemRasterValues";

interface Props {
  title: string;
  symbolizer: LegendSymbolizer;
}

export function LegendSymbolizerItem(props: Props) {
  const { title, symbolizer } = props;

  function renderRasterSymbolizer(symbolizer: LegendSymbolizerRaster) {
    if (isLegendSymbolizerRasterRamp(symbolizer))
      return <LegendSymbolizerItemRasterRamp symbolizer={symbolizer} />;
    if (isLegendSymbolizerRasterValues(symbolizer))
      return <LegendSymbolizerItemRasterValues symbolizer={symbolizer} />;
    return <LegendSymbolizerItemRaster symbolizer={symbolizer} />;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {isLegendSymbolizerPolygon(symbolizer) && (
        <LegendSymbolizerItemPolygon symbolizer={symbolizer} />
      )}
      {isLegendSymbolizerRaster(symbolizer) &&
        renderRasterSymbolizer(symbolizer)}
      {isLegendSymbolizerLine(symbolizer) && (
        <LegendSymbolizerItemLine symbolizer={symbolizer} />
      )}
      {isLegendSymbolizerPoint(symbolizer) && (
        <LegendSymbolizerItemPoint symbolizer={symbolizer} />
      )}
      <Typography>{title}</Typography>
    </Stack>
  );
}
