import { Outlet, useParams } from "react-router-dom";
import { useSelectedTheme, useThemesContext } from "../../hooks/themeHooks";

export function SelectedThemeGuard() {
  const { themes } = useThemesContext();
  const { themeId } = useParams();
  const currentTheme = useSelectedTheme(themes);

  return (
    <>
      {currentTheme && themeId && <Outlet context={{ themes, currentTheme }} />}
    </>
  );
}
