import { Suspense } from "react";
import { Paper, Skeleton, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

import { useSelectedThemeContext } from "../../hooks/themeHooks";
import { useMapSetSelection } from "../../hooks/mapSetHooks";

import { MapSetHorizontalCard } from "./MapSetHorizontalCard";

export function MapSetsOverviewActive() {
  const { t } = useTranslation();

  const { currentTheme, themes } = useSelectedThemeContext();

  const { mapSetSelections, updateMapSetSelection } =
    useMapSetSelection(currentTheme);

  function reorder(startIndex: number, endIndex: number) {
    const result = [...mapSetSelections];

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    updateMapSetSelection(result);
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) {
      return;
    }
    reorder(result.source.index, result.destination.index);
  }

  return (
    <Styles>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Paper
              className="mapSet-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
              elevation={0}
            >
              {mapSetSelections.length === 0 && (
                <Typography>{t("label.noMapSetsFound")}</Typography>
              )}
              {mapSetSelections.length > 0 &&
                mapSetSelections.map((selection, index) => (
                  <Suspense
                    fallback={<Skeleton width="100%" height="60px" />}
                    key={selection.id}
                  >
                    <MapSetHorizontalCard
                      index={index}
                      themes={themes}
                      currentTheme={currentTheme}
                      mapSetSelection={selection}
                      mapSetSelections={mapSetSelections}
                      updateMapSetSelection={updateMapSetSelection}
                      isPersonalMapSet={selection.type === "pl"}
                    />
                  </Suspense>
                ))}

              {provided.placeholder}
            </Paper>
          )}
        </Droppable>
      </DragDropContext>
    </Styles>
  );
}

const Styles = styled("div")`
  .mapSet-container {
    gap: ${({ theme }) => theme.spacing(2)};
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(2)};
  }
  .search-map-button {
    align-self: center;
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .header {
    margin: ${({ theme }) => theme.spacing(2)};
  }

  .collapsedButton {
    position: absolute;
    right: -6%;
    top: 50%;
    z-index: 9999;
    background-color: white;
    transform: translate(0%, -50%);
  }
`;
