import { FunctionComponent, Suspense, useState } from "react";

import { ErrorBoundary } from "react-error-boundary";

import {
  useLocationTypes,
  useUrlLocationType,
} from "../../hooks/locationHooks";

import { LocationType, Location, LocationTypeProps } from "../../types";

import { LocationSearchSkeleton } from "../skeletons/LocationSearchSkeleton";
import { LocationAutocomplete } from "./LocationAutocomplete";

interface LoaderProps {
  greyBackground?: boolean | true;
  onSubmitLocation: (location: Location) => void;
  components: {
    type: FunctionComponent<LocationTypeProps>;
    typeRender: "after" | "between";
  };
}

interface Props extends LoaderProps {
  types: LocationType[];
  selectedLocationType?: LocationType;
}

export function LocationSearch(props: LoaderProps) {
  return (
    <ErrorBoundary fallbackRender={() => null}>
      <Suspense fallback={<LocationSearchSkeleton />}>
        <TypeLoader {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}

function LocationSearchRender(props: Props) {
  const {
    types,
    greyBackground,
    selectedLocationType,
    onSubmitLocation,
    components: { type: TypeComponent, typeRender },
  } = props;
  const [firstType] = types;

  const currentSelectedLocationType =
    selectedLocationType?.key !== "vlaanderen" &&
    selectedLocationType !== undefined
      ? selectedLocationType
      : firstType;

  const [selectedType, setSelectedType] = useState<LocationType>(
    currentSelectedLocationType
  );

  function onChange(type: LocationType) {
    setSelectedType(type);
  }

  return (
    <LocationAutocomplete
      onSubmitLocation={onSubmitLocation}
      greyBackground={greyBackground}
      selectedType={selectedType}
      className="location-form"
      components={{
        type: (
          <TypeComponent
            selectedType={selectedType}
            onChange={onChange}
            types={types}
          />
        ),
        typeRender: typeRender,
      }}
    />
  );
}

function TypeLoader(props: LoaderProps) {
  const { data: locationTypes } = useLocationTypes();

  const { locationType: selectedUrlLocationType } = useUrlLocationType();
  const selectedLocationType = locationTypes?.find(
    (locationType) => locationType.key === selectedUrlLocationType
  );

  if (locationTypes)
    return (
      <LocationSearchRender
        {...props}
        types={locationTypes}
        selectedLocationType={selectedLocationType}
      />
    );
  return null;
}
