import { Breadcrumbs, Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  NavLink as ResetURLNavLink,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import { useThemesContext } from "../hooks/themeHooks";

import { NavLink } from "../components/navigation/NavLink";
import { Location } from "../types";

interface Props {
  currentLocation: Location | undefined;
}

export function AppBreadcrumbs(props: Props) {
  const { currentLocation } = props;
  const { t } = useTranslation();
  const { themeId } = useParams();
  const {
    themes: [firstTheme],
  } = useThemesContext();

  return (
    <Styles>
      <Button
        className="main-button"
        variant="text"
        component={ResetURLNavLink}
        to={`/${themeId || firstTheme.id}`}
      >
        {t("label.effectsAndImpacts")}
      </Button>
      <Breadcrumbs separator="›">
        <ResetURLNavLink to={`/${themeId || firstTheme.id}`} end>
          {t("label.themeOverview")}
        </ResetURLNavLink>
        {currentLocation && (
          <NavLink
            to={`/${themeId || firstTheme.id}/location/${currentLocation.id}`}
          >
            {t("label.searchAreaLocation", {
              type: currentLocation.type.name,
              name: currentLocation.name,
            })}
          </NavLink>
        )}

        <Routes>
          <Route path="/:themeId">
            <Route path="location">
              <Route path=":locationId">
                <Route
                  path="graphs"
                  element={
                    <Typography variant="breadcrumb">
                      {t("label.graphs")}
                    </Typography>
                  }
                />
                <Route path="layers">
                  <Route
                    path="all"
                    element={
                      <Typography variant="breadcrumb">
                        {t("label.cards")}
                      </Typography>
                    }
                  />
                  <Route
                    path="active"
                    element={
                      <Typography variant="breadcrumb">
                        {t("label.cards")}
                      </Typography>
                    }
                  />
                </Route>
                <Route path="info">
                  <Route
                    path="layerSets"
                    element={
                      <Typography variant="breadcrumb">
                        {t("label.info")}
                      </Typography>
                    }
                  />
                  <Route
                    path="indicators"
                    element={
                      <Typography variant="breadcrumb">
                        {t("label.info")}
                      </Typography>
                    }
                  />
                </Route>

                <Route
                  path="overview"
                  element={
                    <Typography variant="breadcrumb">
                      {t("label.tableView")}
                    </Typography>
                  }
                />
              </Route>
            </Route>

            <Route
              index
              element={
                <Typography variant="breadcrumb">
                  {t("label.detailOverview")}
                </Typography>
              }
            />
          </Route>
        </Routes>
      </Breadcrumbs>
    </Styles>
  );
}

const Styles = styled("div")`
  flex-grow: 1;
  a {
    text-decoration: none;
    font-size: 13px;
    color: ${({ theme }) => theme.palette.text.primary};
    &:hover {
      text-decoration: underline;
    }
    &.active {
      color: ${({ theme }) => theme.palette.primary.main};
      font-weight: bold;
    }
  }
  .main-button {
    margin-right: ${({ theme }) => theme.spacing(2)};
    text-transform: none;
    font-size: 1rem;
  }

  .MuiBreadcrumbs-root {
    display: inline-block;
  }
`;
