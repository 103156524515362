import { useEffect } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Location } from "../../../types";

interface Props {
  locations: Location[];
  resetLatLng: () => void;
}

export function LocationTypeNavigate(props: Props) {
  const { locations, resetLatLng } = props;
  const location = useLocation();
  const { themeId, locationId } = useParams();
  const [params] = useSearchParams();

  const [firstLocation] = locations;

  function generatePath(locationId: string) {
    const newSearchParams = new URLSearchParams(params);
    newSearchParams.delete("addressMarker");
    const [, , , , ...restPath] = location.pathname.split("/");
    return `${[`/${themeId}/location/${locationId}`, ...restPath].join(
      "/"
    )}?${newSearchParams.toString()}`;
  }

  useEffect(() => {
    resetLatLng();
  }, [resetLatLng]);

  return (
    <>
      {firstLocation && firstLocation.id.toString() !== locationId && (
        <Navigate to={generatePath(firstLocation.id.toString())} />
      )}
    </>
  );
}
