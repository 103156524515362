import { useEffect, useRef, useState } from "react";

import { Button, styled } from "@mui/material";
import { FileOpen, Loop } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { useDownloadAllExcel } from "../../hooks/indicatorDataHooks";

export function DownloadAllButton() {
  const [downloadState, setDownloadState] = useState<boolean>(false);
  const { t } = useTranslation();
  function toggleDownloadState() {
    setDownloadState(false);
  }
  return (
    <Styles>
      <Button
        onClick={() => setDownloadState(true)}
        disabled={downloadState}
        variant="textSmall"
        startIcon={
          downloadState ? (
            <Loop fontSize="small" className="rotate" />
          ) : (
            <FileOpen fontSize="small" />
          )
        }
      >
        {t("label.exportAllData")}
      </Button>
      {downloadState && (
        <StartDownload toggleDownloadState={toggleDownloadState} />
      )}
    </Styles>
  );
}

const Styles = styled("div")`
  .MuiButton-root {
    text-transform: none;
  }
  .rotate {
    animation: rotation 1s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

interface StartDownloadProps {
  toggleDownloadState: () => void;
}

function StartDownload(props: StartDownloadProps) {
  const { toggleDownloadState } = props;
  const { data } = useDownloadAllExcel();

  return (
    <>
      {data && (
        <TriggerDownload
          excelUrl={data.excelUrl}
          toggleDownloadState={toggleDownloadState}
        />
      )}
    </>
  );
}

interface TriggerDownloadProps {
  excelUrl: string;
  toggleDownloadState: () => void;
}

function TriggerDownload(props: TriggerDownloadProps) {
  const { excelUrl, toggleDownloadState } = props;
  const linkRef = useRef<HTMLAnchorElement>(null!!);

  useEffect(() => {
    if (linkRef) {
      linkRef.current.click();
      toggleDownloadState();
    }
  }, [toggleDownloadState]);

  return (
    <a href={excelUrl} style={{ display: "hidden" }} ref={linkRef}>
      download
    </a>
  );
}
