import { ChangeEvent } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useBackgroundLayerParams,
  useSelectedBackgroundLayer,
} from "../../../hooks/backgroundLayersHooks";
import { BackgroundLayer } from "../../../types";

interface Props {
  backgroundLayers: BackgroundLayer[];
}

export function SelectBackgroundLayer(props: Props) {
  const { backgroundLayers } = props;
  const selectedBackgroundLayer = useSelectedBackgroundLayer(backgroundLayers);
  const { t } = useTranslation();
  const { updateBackgroundLayerParam } = useBackgroundLayerParams();

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    updateBackgroundLayerParam(+(event.target.value || ""));
  }
  return (
    <>
      {backgroundLayers.length > 0 && (
        <RadioGroup
          value={selectedBackgroundLayer.id}
          name="backgroundLayers"
          onChange={onChange}
        >
          {backgroundLayers.map((backgroundLayer, index) => (
            <FormControlLabel
              key={`${backgroundLayer.name + index}`}
              value={backgroundLayer.id}
              control={<Radio />}
              label={backgroundLayer.name}
            />
          ))}
        </RadioGroup>
      )}
      {backgroundLayers.length === 0 && (
        <Typography> {t("label.noBackgroundlayersFound")}</Typography>
      )}
    </>
  );
}
