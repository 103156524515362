import { Fragment } from "react";
import { TileLayer, WMSTileLayer } from "react-leaflet";
import { useSelectedBorderLayers } from "../../hooks/backgroundLayersHooks";
import { useLayers } from "../../hooks/mapSetHooks";
import { BackgroundLayer, Theme } from "../../types";
import { getExtendedWMSParams } from "../../utils/LayerUtils";

interface Props {
  overLayers: BackgroundLayer[];
  currentTheme: Theme;
  themes: Theme[];
}

export function OverLayerRenderer(props: Props) {
  const { overLayers, currentTheme, themes } = props;
  const activeOverLayers = useSelectedBorderLayers(overLayers);
  const activeLayers = useLayers(currentTheme, themes);

  return (
    <>
      {activeOverLayers &&
        activeOverLayers.map((overLayer, index) => (
          <Fragment key={overLayer.id}>
            {overLayer.wms.type === "WMS" && (
              <WMSTileLayer
                {...getExtendedWMSParams(overLayer.wms)}
                key={`${overLayer.name}-${index + 1}`}
                zIndex={activeLayers.length + index + 1}
              />
            )}
            {overLayer.wms.type === "TILE" && (
              <TileLayer
                key={`${overLayer.name}-${index + 1}`}
                url={overLayer.wms.url}
                zIndex={activeLayers.length + index + 1}
              />
            )}
          </Fragment>
        ))}
    </>
  );
}
