import { common } from "@mui/material/colors";
import {
  createTheme,
  ThemeOptions,
  SimplePaletteColorOptions,
} from "@mui/material/styles";
import { CommonColors } from "@mui/material/styles/createPalette";
import { SansRegularFont } from "./fonts";

interface TextColors {
  primary: string;
  secondary: string | undefined;
  disabled: string;
  hint: string;
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    textGreen: true;
    textSmall: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    breadcrumb: true;
    "panel-title": true;
    "small-italic": true;
  }
}

export const theme = (() => {
  const sansFont =
    "FlandersSans ,-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;";

  const defaultTheme = createTheme({
    typography: {
      fontFamily: sansFont,
    },
  });
  const borderRadius: number = 0;
  const commonColors: CommonColors = {
    black: "#000",
    white: "#fff",
  };
  const primary: SimplePaletteColorOptions = {
    main: "#227799",
    contrastText: commonColors.white,
  };
  const secondary: SimplePaletteColorOptions = {
    main: "#26b8eb",
    contrastText: commonColors.white,
  };
  const success: SimplePaletteColorOptions = {
    main: "#84ac3f",
    dark: "#6c981f",
    light: "#84ac3f45",
    contrastText: commonColors.black,
  };
  const error: SimplePaletteColorOptions = {
    light: "rgb(230 ,49 ,31)",
    main: "#C63131",
    dark: "rgb(145 ,45 ,45)",
    contrastText: commonColors.white,
  };
  const warning: SimplePaletteColorOptions = {
    light: "rgb(183 ,171 ,31)",
    main: "rgb(150 ,140 ,25)",
    dark: "rgb(108 ,101 ,18)",
    contrastText: commonColors.white,
  };
  const text: TextColors = {
    primary: "rgba(0, 0, 0, 1)",
    secondary: "rgba(0, 0, 0, 0.8)",
    disabled: "rgba(0, 0, 0, 0.4)",
    hint: "rgba(0, 0, 0, 0.4)",
  };

  const overrides: ThemeOptions = {
    palette: {
      primary,
      secondary,
      text,
      success,
      warning,
      error,
      background: {
        paper: commonColors.white,
        default: commonColors.white,
      },
      common: commonColors,
    },

    typography: {
      fontFamily: sansFont,
      h1: {
        fontSize: "16px",
        fontWeight: 700,
      },
      h2: {
        fontSize: "18px",
        color: primary.main,
        fontWeight: 700,
      },
      h3: {
        fontSize: "13px",
        color: primary.main,
        fontWeight: 700,
      },
      h6: {
        fontSize: "13px",
        color: primary.main,
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: "13px",
      },
      subtitle2: {
        color: primary.main,
      },
      body1: {
        fontSize: "14px",
      },
      body2: {
        fontSize: "11px",
      },
    },
    shape: {
      borderRadius: borderRadius,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": SansRegularFont,
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            margin: 0,
            fontSize: "13px",
          },
        },
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: "breadcrumb" },
            style: {
              fontSize: "13px",
              color: defaultTheme.palette.grey[900],
            },
          },
          {
            props: { variant: "panel-title" },
            style: {
              fontSize: "13px",
              fontWeight: 600,
              textTransform: "uppercase",
              color: defaultTheme.palette.grey[900],
            },
          },
          {
            props: { variant: "small-italic" },
            style: {
              fontSize: "12px",
              color: defaultTheme.palette.grey[600],
              fontStyle: "italic",
            },
          },
        ],
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: commonColors.black,
          },
        },
      },
      MuiListItemText: {
        defaultProps: { disableTypography: true },
        styleOverrides: {
          root: {
            fontWeight: 700,
            fontSize: "15px",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: primary.main,
          },
        },
      },
      // MuiTableCell: {
      //   styleOverrides: {
      //     root: {
      //       fontSize: "13px",
      //       padding: defaultTheme.spacing(1),
      //     },
      //   },
      // },
      MuiFab: {
        styleOverrides: {
          root: {
            backgroundColor: commonColors.white,
          },
          primary: {
            backgroundColor: primary.main,
            color: commonColors.white,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: common.white,
            borderBottom: `1px solid ${defaultTheme.palette.grey[200]}`,
          },
        },
        variants: [
          {
            props: { variant: undefined },
            style: {
              zIndex: defaultTheme.zIndex.drawer + 1,
            },
          },
        ],
      },
      MuiLink: {
        variants: [
          {
            props: { variant: "breadcrumb" },
            style: {
              "&.active": {
                textDecoration: "none",
                fontWeight: 700,
                color: primary.main,
              },
              textDecoration: "none",
              fontWeight: "inhertit",
              color: defaultTheme.palette.grey[900],
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "textGreen" },
            style: {
              color: success.main,
              fontWeight: 700,
              fontSize: "13px",
              "&:hover": {
                backgroundColor: success.light,
              },
            },
          },
          {
            props: { variant: "contained" },
            style: {
              backgroundColor: success.main,
              fontWeight: 700,
              fontSize: "13px",
              "&:hover": {
                backgroundColor: success.dark,
              },
            },
          },
          {
            props: { variant: "textSmall" },
            style: {
              textTransform: "none",
              color: primary.main,
              fontSize: "13px",
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              borderColor: success.main,
              color: success.main,
              fontWeight: 700,
              fontSize: "13px",
              "&:hover": {
                borderColor: success.dark,
                color: success.dark,
              },
            },
          },
        ],
      },
    },
  };

  return createTheme(defaultTheme, overrides);
})();
