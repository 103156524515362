import { ChangeEvent } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { BackgroundLayer } from "../../types";
import { useBorderLayerParams } from "../../hooks/backgroundLayersHooks";

interface Props {
  borderLayers: BackgroundLayer[];
}

export function SelectBorderLayer(props: Props) {
  const { borderLayers } = props;
  const { updateBorderLayerParam, selectedBorderLayerIds } =
    useBorderLayerParams();
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    updateBorderLayerParam(+(event.target.value || ""), event.target.checked);
  }

  return (
      <FormGroup onChange={handleChange}>
        {borderLayers.map((borderLayer, index) => (
          <FormControlLabel
            key={`${borderLayer}-${index}`}
            control={<Checkbox />}
            checked={selectedBorderLayerIds.indexOf(borderLayer.id) !== -1}
            value={borderLayer.id}
            label={borderLayer.name}
          />
        ))}
      </FormGroup>
  );
}
