import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  styled,
  CardHeader,
  IconButton,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { TooltipRenderProps } from "react-joyride";

interface Props extends TooltipRenderProps {}
export function JoyrideTooltip(props: Props) {
  const {
    tooltipProps,
    step,
    index,
    backProps,
    primaryProps,
    closeProps,
    isLastStep,
  } = props;
  const { t } = useTranslation();
  return (
    <StyledCard {...tooltipProps}>
      <CardHeader
        title={step.title}
        action={
          <IconButton {...closeProps} size="small">
            <Clear fontSize="small" />
          </IconButton>
        }
      />
      <CardContent>
        <Typography>{step.content}</Typography>
      </CardContent>
      <CardActions>
        {index > 0 && (
          <Button color="primary" variant="text" {...backProps}>
            {t("label.prevStep")}
          </Button>
        )}
        <Button color="primary" variant="text" {...primaryProps}>
          {!isLastStep && t("label.nextStep")}
          {isLastStep && t("label.finish")}
        </Button>
      </CardActions>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  max-width: ${({ theme }) => theme.spacing(50)};
  .MuiCardActions-root {
    display: flex;
    justify-content: flex-end;
  }
  .MuiCardHeader-root {
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }
  .MuiCardContent-root {
    padding-bottom: 0;
  }
`;
