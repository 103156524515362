import { divIcon } from "leaflet";
import { useAddressLatLng } from "../../hooks/locationHooks";
import { Marker } from "react-leaflet";
import { useTheme } from "@mui/material";

export function AddressMarker() {
  const latlng = useAddressLatLng();
  const { palette } = useTheme();
  const fillColor = palette.success.main;
  if (!latlng) return null;

  const svgIcon = divIcon({
    html: `<svg
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="RoomIcon"
      >
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" fill="${fillColor}"></path>
      </svg>`,
    className: "",
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  });
  return <Marker position={latlng} icon={svgIcon} />;
}
