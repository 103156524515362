import { alpha, Link, Paper, styled } from "@mui/material";
import { ControlOptions, GeoJSON as LGeoJSON } from "leaflet";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";
import { GeoJsonProperties } from "geojson";

import { initialBounds } from "../../../hooks/mapHooks";

import { MapControlBase } from "../MapControlBase";
import { MetricScale } from "./MetricScale";

interface Props extends ControlOptions {}

export function AttributionAndScale(props: Props) {
  const { ...controlProps } = props;
  const { t } = useTranslation();
  const flandersBounds = initialBounds;
  const map = useMap();

  function onResetClick() {
    map.eachLayer((layer) => {
      const typedLayer = layer as LGeoJSON<GeoJsonProperties>;
      if (
        typedLayer &&
        "options" in typedLayer &&
        "fill" in typedLayer.options &&
        "stroke" in typedLayer.options &&
        //@ts-ignore
        typedLayer.options.fill === false
      ) {
        map.flyToBounds(typedLayer.getBounds(), { animate: true });
      }
    });
  }

  function onResetToFlanders() {
    map.flyToBounds(flandersBounds, { animate: true });
  }

  return (
    <MapControlBase className="flush-to-edge" {...controlProps}>
      <Styles elevation={0}>
        <Link onClick={onResetToFlanders}>{t("label.zoomToFlanders")}</Link>
        <Link onClick={onResetClick}>{t("label.zoomToLocation")}</Link>
        <MetricScale maxWidth={60} map={map} />
      </Styles>
    </MapControlBase>
  );
}

const Styles = styled(Paper)`
  display: flex;
  gap: 10px;

  padding: ${({ theme }) => theme.spacing(0.5, 2, 0.2, 2)};
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.8)};

  .MuiLink-root {
    cursor: pointer;
  }
`;
