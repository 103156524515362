import { ReactNode } from "react";
import { List, ListProps, styled } from "@mui/material";
import { Theme } from "../../types";
import { ThemeListItem } from "./ThemeListItem";

interface Props {
  themes: Theme[];
  currentTheme?: Theme;
  navigationPath: string;
  children?: ReactNode;
  resetURLStateOnNavigate?: true;
}

export function ThemeSelectionList(props: Props) {
  const {
    themes,
    currentTheme,
    children,
    navigationPath,
    resetURLStateOnNavigate,
  } = props;
  const lastIndex = themes.length - 1;
  return (
    <StyledList>
      {themes.map((theme: Theme, i: number) => (
        <ThemeListItem
          key={theme.id}
          theme={theme}
          selected={currentTheme?.id === theme.id}
          navigationPath={navigationPath}
          resetURLStateOnNavigate={resetURLStateOnNavigate === true}
          showDivider={i !== lastIndex}
        />
      ))}
      {children}
    </StyledList>
  );
}

const StyledList = styled((props: ListProps<"div">) => (
  <List component="div" {...props} />
))`
  padding-right: ${({ theme }) => theme.spacing(4)};
  height: 100%;
  overflow: auto;
  padding-bottom: 0;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.grey[100]};
    width: 100%;
    padding-top: 11px;
  }

  .MuiListItemAvatar-root {
    min-width: ${({ theme }) => theme.spacing(2)};
    margin-right: ${({ theme }) => theme.spacing(1)};

    & > .MuiAvatar-root {
      max-width: ${({ theme }) => theme.spacing(2)};
      max-height: ${({ theme }) => theme.spacing(2)};
    }
  }

  .MuiSvgIcon-root {
    transition: transform 320ms ease-out;

    &.rotate-180 {
      transform: rotateZ(180deg);
    }
  }

  .MuiListItemButton-root {
    font-weight: bold;
    font-size: ${({ theme }) => theme.spacing(1.6)};
  }

  .MuiListItemButton-root {
    &.active {
      background-color: ${({ theme }) => theme.palette.grey[100]};
      &.Mui-disabled {
        background-color: ${({ theme }) => theme.palette.grey[100]};
        opacity: 1;
      }
    }
  }
`;
