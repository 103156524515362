import { styled, TableCell, TableRow } from "@mui/material";
import { LatLngLiteral } from "leaflet";
import { LayerSet, ThemeScenario } from "../../../types";
import { InfoTableCell } from "./InfoTableCell";

interface Props {
  mapSet: LayerSet;
  themeScenarios: ThemeScenario[];
  point: LatLngLiteral;
}

export function InfoTableRow(props: Props) {
  const { mapSet, themeScenarios, point } = props;
  return (
    <StyledTableRow>
      <TableCell className="header-cell">{mapSet.name}</TableCell>
      {themeScenarios.map((scenario) => (
        <InfoTableCell
          key={scenario.scenario.year}
          nbDigits={mapSet.nbDigits}
          point={point}
          layer={mapSet.layers.find(
            (layer) =>
              layer.scenario?.year === scenario.scenario.year ||
              (layer.scenario?.present && scenario.scenario.present)
          )}
        />
      ))}
      <TableCell className="header-cell">{mapSet.unit}</TableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)`
  .header-cell {
    min-width: ${({ theme }) => theme.spacing(25)};
  }
`;
