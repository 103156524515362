import { Suspense, useState } from "react";
import { LinearProgress, Portal } from "@mui/material";
import { LatLng } from "leaflet";
import { Trans } from "react-i18next";

import {
  useClickableLocationType,
  useLocationLatLng,
  useLocationTypes,
} from "../../../hooks/locationHooks";

import { ClickOnMapAlert } from "../ClickOnMapAlert";
import { MapClickHandler } from "../MapClickHandler";
import { LocationTypePopup } from "./LocationTypePopup";
import { LocationTypeNavigate } from "./LocationTypeNavigate";

interface Props {
  mapOverlayContainer: HTMLElement | null;
}

export function LocationTypeSearchClickHandler(props: Props) {
  const { mapOverlayContainer } = props;
  const [latLng, setLatLng] = useState<LatLng>();
  const [prevLatLng, setPrevLatLng] = useState<LatLng>();
  const { data: locationTypes } = useLocationTypes();
  const [isActive, setIsActive] = useState(true);
  const clickableLocationType = useClickableLocationType();
  const foundLocationType = locationTypes?.find(
    (locationType) => locationType.key === clickableLocationType
  );

  function onAlertClick() {
    return setIsActive(false);
  }

  function onClick(latlng: LatLng) {
    setLatLng((prev) => {
      setPrevLatLng(prev);
      return latlng;
    });
  }

  function resetLatLng() {
    setLatLng(undefined);
  }

  return (
    <>
      <ClickOnMapAlert
        isActive={isActive}
        onAlertClick={onAlertClick}
        alertText={
          <Trans
            i18nKey="label.clickOnTheMapToSelectALocationType"
            tOptions={{ locationType: foundLocationType?.name }}
            components={{ bold: <strong></strong> }}
          />
        }
      />
      <Suspense
        fallback={
          <Portal container={mapOverlayContainer}>
            <LinearProgress />
          </Portal>
        }
      >
        <MapClickHandler onClick={onClick} />
        {latLng && foundLocationType && (
          <LocationTypeSearchClickProcessor
            locationType={foundLocationType.key}
            latLng={latLng}
            prevLatLng={prevLatLng}
            resetLatLng={resetLatLng}
          />
        )}
      </Suspense>
    </>
  );
}

interface ClickProcessorProps {
  latLng: LatLng;
  prevLatLng?: LatLng;
  locationType: string;
  resetLatLng: () => void;
}

function LocationTypeSearchClickProcessor(props: ClickProcessorProps) {
  const { latLng, prevLatLng, locationType, resetLatLng } = props;

  const { data: locations } = useLocationLatLng(
    latLng,
    locationType,
    prevLatLng
  );

  return (
    <>
      {locationType !== "waterlichaam" && locations && (
        <LocationTypeNavigate locations={locations} resetLatLng={resetLatLng} />
      )}
      {locationType === "waterlichaam" && locations && (
        <LocationTypePopup
          latLng={latLng}
          locations={locations}
          resetLatLng={resetLatLng}
        />
      )}
    </>
  );
}
