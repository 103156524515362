import { debounce } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";

export function MapSizeObserver() {
  const map = useMap();
  const ro = useMemo(
    () =>
      new ResizeObserver(
        debounce(() => {
          map.invalidateSize();
        }, 500)
      ),
    [map]
  );

  useEffect(() => {
    ro.observe(map.getContainer());

    return () => ro.disconnect();
  }, [map, ro]);

  return null;
}
