import { LayerSet, MapSetFormData, MapSetType, Selection } from "../types";

export function formatLayerSetsToSelection(layerSets: LayerSet[]): Selection[] {
  return layerSets.reduce((selection: Selection[], mapSet: LayerSet) => {
    const [layer] = mapSet.layers;
    const formattedSelection: Selection = {
      id: mapSet.id,
      opacity: layer.wms.opacity,
      type: "ms",
      added: 0,
      visible: 1,
    };
    return [...selection, formattedSelection];
  }, []);
}

export function isMapSetType(keyInput: string): keyInput is MapSetType {
  return ["ms", "pl"].includes(keyInput);
}

export function parseMapSetUrlParams(urlParam: string): Selection {
  const splittedUrlParam = urlParam.split("|");
  const [id, opacity, type, added, visible] = splittedUrlParam;
  return {
    id: +id,
    opacity: +opacity,
    type: isMapSetType(type) ? type : "ms",
    added: +added,
    visible: +visible,
  };
}
// TODO: Might not be used anymore
export function getMapSetUrlParams(
  urlMapSetSelection: Selection[],
  defaultTheme: Selection[]
) {
  const filteredParams = urlMapSetSelection.filter(
    (selectionItem) =>
      selectionItem.added !== 0 ||
      defaultTheme.findIndex(
        (defaultThemeSelection) => defaultThemeSelection.id === selectionItem.id
      ) > -1
  );

  const defaultParamsToBeAdded = defaultTheme.reduce(
    (toBeAddedSelection: Selection[], selectionItem: Selection) => {
      if (
        filteredParams.findIndex(
          (filteredSelectionItem) =>
            filteredSelectionItem.id === selectionItem.id
        ) === -1
      )
        return [...toBeAddedSelection, selectionItem];
      return [...toBeAddedSelection];
    },
    []
  );

  const newSelection = [...filteredParams, ...defaultParamsToBeAdded];

  return newSelection;
}

export function formatToMapSet(values: MapSetFormData): LayerSet {
  const currentTime = Date.now();
  return {
    id: values.id === 0 ? currentTime : values.id,
    unit: "",
    name: values.name,
    serialNumber: 0,
    description: "",
    defaultSelection: false,
    attribution: "",
    roundingMode: "HALF_UP",
    nbDigits: 2,
    layers: [
      {
        id: values.id === 0 ? currentTime : values.id,
        wms: {
          type: "WMS",
          url: values.url,
          opacity: 1,
          layers: values.layerName,
          transparent: true,
          format: "image/png",
        },
        legend: {
          url: values.legendUrl ? values.legendUrl : undefined,
        },
      },
    ],
  };
}

export function formatToMapSetFormData(mapSet: LayerSet): MapSetFormData {
  const [layer] = mapSet.layers;
  return {
    id: mapSet.id,
    name: mapSet.name,
    url: layer.wms.url,
    layerName: layer.wms.layers,
    legendUrl: layer.legend?.url,
  };
}
