import { LegendSymbolizerLine } from "../../../hooks/legendHooks";

interface Props {
  symbolizer: LegendSymbolizerLine;
}

export function LegendSymbolizerItemLine(props: Props) {
  const { symbolizer } = props;

  return (
    <svg x="0" y="0" width="10" height="10">
      <path
        d="M0 10 L10 0"
        {...symbolizer.Line}
        x="0"
        y="0"
        width="10"
        height="10"
      />
    </svg>
  );
}
