import FlandersSansBold from "../assets/fonts/flanders-sans-bold.ttf";
import FlandersSansLight from "../assets/fonts/flanders-sans-light.ttf";
// import FlandersSansMedium from "../assets/fonts/flanders-sans-medium.ttf";
import SansRegular from "../assets/fonts/2ca7342.woff2"; //"../assets/fonts/flanders-sans-regular.woff";
import SansMedium from "../assets/fonts/ee18de6.woff2"; //"../assets/fonts/flanders-sans-medium.woff";
import { AtRule } from "csstype";
import { CSSProperties } from "@mui/styled-engine";

type JSSFontface = AtRule.FontFace;

const SansBoldFont: JSSFontface = {
  fontFamily: "FlandersSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `local('flanders-sans-bold'), url(${FlandersSansBold}) format('truetype')`,
};
const SansLightFont: JSSFontface = {
  fontFamily: "FlandersSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `local('flanders-sans-light'), url(${FlandersSansLight}) format('truetype')`,
};

export const SansRegularFont: JSSFontface = {
  fontFamily: "FlandersSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `local('2ca7342'), url(${SansRegular}) format('woff2')`,
};

const SansMediumFont: JSSFontface = {
  fontFamily: "FlandersSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `local('ee18de6'), url(${SansMedium}) format('woff2')`,
};

export const fontFaces: CSSProperties[] = [
  SansBoldFont,
  SansLightFont,
  SansMediumFont,
  SansRegularFont,
];
