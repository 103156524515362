import { Button, styled, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMapSetValidation } from "../../hooks/mapSetHooks";
import { MapSetFormData } from "../../types";
import { TextField } from "../form/TextField";

interface Props {
  initialValues: MapSetFormData;
  isEditing?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (values: MapSetFormData) => void;
  onDelete?: () => void;
}

export function MapSetForm(props: Props) {
  const { initialValues, isEditing, onSubmit, onDelete, setIsOpen } = props;
  const validationSchema = useMapSetValidation();
  const { t } = useTranslation();

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <Typography>{t("label.toBeAddedMapHasToBeWMSProtocol")}</Typography>
          <Field
            component={TextField}
            name="name"
            type="text"
            label={`${t("label.mapName")}:`}
          />
          <Typography>
            {t("label.WMSInfoFoundInTheGetCapabilitiesDocumentOfCurrentWMS")}
          </Typography>
          <Field
            component={TextField}
            name="url"
            type="text"
            label={`${t("label.wmsUrl")}:`}
          />
          <Field
            component={TextField}
            name="layerName"
            type="text"
            label={`${t("label.wmsLayerName")}:`}
          />
          <Field
            component={TextField}
            name="legendUrl"
            type="text"
            label={`${t("label.urlGetLegendRequest")}:`}
          />
          <div className="action-buttons">
            {isEditing && (
              <Button color="error" onClick={onDelete}>
                {t("label.delete")}
              </Button>
            )}
            <Button onClick={() => setIsOpen(false)}>
              {t("label.cancel")}
            </Button>
            <Button type="submit" variant="contained">
              {!isEditing && t("label.add")}
              {isEditing && t("label.save")}
            </Button>
          </div>
        </StyledForm>
      )}
    </Formik>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.spacing(90)};
  gap: ${({ theme }) => theme.spacing(1)};
  .action-buttons {
    display: flex;
    justify-content: end;
    gap: ${({ theme }) => theme.spacing(1)};
  }
`;
