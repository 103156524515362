import { Box, Dialog, Link, styled, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MapSetAdd } from "./MapSetAdd";

export function MapSetPersonalLayerDialog() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <Styles>
      <Link onClick={() => setIsOpen(true)} gutterBottom>
        {t("label.addAWMSLayer")}
      </Link>
      <StyledDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Box>
          <Typography variant="h2" component="h2">
            {t("label.addPersonalLayer")}
          </Typography>
          <MapSetAdd setIsOpen={setIsOpen} />
        </Box>
      </StyledDialog>
    </Styles>
  );
}

const Styles = styled("div")`
  .MuiLink-root {
    cursor: pointer;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(2, 2)};
  }
`;
