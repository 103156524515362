import { useLegend } from "../../../hooks/legendHooks";
import { ExtendedWMSParams } from "../../../types";

import { LegendItem } from "./LegendItem";

interface Props {
  id: number;
  url: string;
  wms: ExtendedWMSParams;
  shouldDisplayTitle?: boolean;
}

export function LegendData(props: Props) {
  const { url, wms, id, shouldDisplayTitle = false } = props;

  const { data: legendData } = useLegend(url, wms);
  return (
    <>
      {legendData &&
        legendData.map((definition, i) => (
          <LegendItem
            shouldDisplayTitle={shouldDisplayTitle}
            definition={definition}
            key={`legend-data-${i}-${id || -1}`}
          />
        ))}
    </>
  );
}
