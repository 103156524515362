import { useQueryClient } from "react-query";
import { EMPTY_LAYER, usePersonalMapSets } from "../../hooks/mapSetHooks";
import { MapSetFormData } from "../../types";
import { formatToMapSet } from "../../utils/MapSetUtils";
import { MapSetForm } from "./MapSetForm";

interface Props {
  setIsOpen: (isOpen: boolean) => void;
}

export function MapSetAdd(props: Props) {
  const { setIsOpen } = props;
  const { data: mapSets } = usePersonalMapSets();
  const queryClient = useQueryClient();

  const emptyLayer = EMPTY_LAYER;

  function onSubmit(values: MapSetFormData) {
    const mapSet = formatToMapSet(values);
    const currentMapSets = mapSets || [];
    localStorage.setItem(
      "mapSets",
      JSON.stringify([...currentMapSets, { ...mapSet, scrollTo: true }])
    );
    queryClient.invalidateQueries("personalMapSets");
    setIsOpen(false);
  }

  return (
    <MapSetForm
      initialValues={emptyLayer}
      onSubmit={onSubmit}
      setIsOpen={setIsOpen}
    />
  );
}
