import {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useState,
} from "react";
import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  Skeleton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, FormikFormProps } from "formik";
import * as Yup from "yup";
import { Autocomplete } from "formik-mui";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDebounce } from "../../hooks/debounceHooks";
import { useLocation, useLocationNamed } from "../../hooks/locationHooks";

import { Location, LocationType } from "../../types";
import { LocationInitialValuesCheck } from "./LocationInitialValuesCheck";

interface Props extends FormikFormProps {
  selectedType: LocationType;
  greyBackground?: boolean | true;
  components: { type: ReactElement; typeRender: "after" | "between" };
  onSubmitLocation: (location: Location) => void;
}

export function LocationAutocomplete(props: Props) {
  const {
    selectedType,
    greyBackground,
    onSubmitLocation,
    components: { type: TypeComponent, typeRender },
    ...formProps
  } = props;
  const { locationId } = useParams();
  const { t } = useTranslation();

  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);

  const { data: location } = useLocation(+(locationId || ""), {
    enabled: locationId !== undefined && locationId !== "",
  });
  const { data: locations, isLoading } = useLocationNamed(
    debouncedQuery,
    selectedType.key,
    {
      enabled: debouncedQuery.length > 2,
    }
  );

  const locationOptions: Location[] = locations ? locations : [];

  const emptyLocation: Location = {
    id: -1,
    code: "",
    nameAndCodeFilter: "",
    geometry: {
      type: "Polygon",
      coordinates: [[[]]],
    },
    boundingBox: [
      [0, 0],
      [0, 0],
    ],
    inverseGeometry: {
      type: "Polygon",
      coordinates: [[[]]],
    },
    name: "",
    type: {
      name: "",
      key: "",
    },
  };

  const initialValues = {
    searchResult:
      locationId !== undefined &&
      locationId !== "" &&
      location?.type.key !== "vlaanderen" &&
      location
        ? location
        : emptyLocation,
  };

  function onChange(
    event: React.SyntheticEvent,
    value: Location,
    reason: string,
    details?: string
  ) {
    if (reason === "selectOption") {
      onSubmit({ searchResult: value });
    }
  }

  async function onSubmit(values: { searchResult: Location }) {
    const { searchResult } = values;
    if (searchResult.id === -1) return null;
    return onSubmitLocation(searchResult);
  }

  const validationSchema = Yup.object().shape({
    searchResult: Yup.object().required(),
  });

  function isPopperOpen() {
    if (locationOptions.length === 0 && !isLoading && debouncedQuery.length > 0)
      return true;
    if (locationOptions.length > 0) return true;
    if (isLoading) return true;
    return false;
  }

  function getPaperContent(children: ReactNode) {
    if (locationOptions.length === 0 && debouncedQuery.length > 2 && !isLoading)
      return (
        <Typography className="no-results-found">
          {t("label.noResultsFound")}
        </Typography>
      );
    return children;
  }

  if (locationId !== undefined && locationId !== "" && !location)
    return <Skeleton height={53} width="100%" />;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {() => (
        <>
          <StyledForm
            {...formProps}
            className={`${greyBackground ? "grey " : ""}${
              formProps.className || ""
            }`}
          >
            <LocationInitialValuesCheck initialValues={initialValues} />
            <Field
              component={Autocomplete}
              name="searchResult"
              freeSolo
              inputValue={query}
              loading={isLoading}
              loadingText={t("label.loading") + "..."}
              options={locations || []}
              filterOptions={(options: Location[], params: any) => {
                const arrayOfSplitString: string[] = params.inputValue
                  .trim()
                  .split(/\W+/);
                const filtered = options.filter((option) => {
                  return arrayOfSplitString.some((word) => {
                    return option.name
                      .toLowerCase()
                      .includes(word.toLowerCase());
                  });
                });
                return filtered;
              }}
              onChange={onChange}
              PopperComponent={({ open, children, ...props }: PopperProps) => (
                <Popper open={isPopperOpen()} {...props} disablePortal>
                  {children}
                </Popper>
              )}
              PaperComponent={({ children, ...props }: PaperProps) => (
                <Paper {...props}>{getPaperContent(children)}</Paper>
              )}
              noOptionsText={t("label.noResultsFound")}
              onInputChange={(
                event: SyntheticEvent<Element, Event>,
                newInputValue: string
              ) => {
                setQuery(newInputValue);
              }}
              disablePortal
              getOptionLabel={(searchItem: Location | string) => {
                if (typeof searchItem === "string") return "";
                return searchItem.name;
              }}
              renderOption={(
                props: HTMLAttributes<HTMLLIElement>,
                option: Location
              ) => {
                return (
                  <Box
                    {...props}
                    key={`${option.id}-${option.name}`}
                    component="li"
                  >
                    <div>{option.name}</div>
                    <div className="align-right">{option.type.name}</div>
                  </Box>
                );
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  value={query}
                  autoFocus
                  placeholder={t("label.enterASearchTerm")}
                />
              )}
            />
            {typeRender === "between" && TypeComponent}
            <Button
              variant="contained"
              disabled={isLoading}
              disableElevation
              type="submit"
            >
              {t("label.search")}
            </Button>
          </StyledForm>
          {typeRender === "after" && TypeComponent}
        </>
      )}
    </Formik>
  );
}

const StyledForm = styled(Form)`
  height: ${({ theme }) => theme.spacing(5)};
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  .no-results-found {
    margin: ${({ theme }) => theme.spacing(1)};
  }
  &.grey {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  .MuiOutlinedInput-notchedOutline {
    border-style: none;
  }
  .MuiButton-root {
    align-self: stretch;
  }
  .MuiAutocomplete-root {
    flex-grow: 1;
  }
  .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .MuiAutocomplete-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px ${({ theme }) => theme.palette.grey[100]} solid;
    gap: ${({ theme }) => theme.spacing(2)};
  }
  .align-right {
    margin-left: auto;
    flex-shrink: 0;
    flex-wrap: nowrap;
  }

  .load-more-button {
    justify-content: center;
  }
`;
