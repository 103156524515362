import { ArrowRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { LayerSet, MapSetType, Selection, Theme } from "../../types";
import { MapSetOverviewAllItem } from "./MapSetOverviewAllItem";

interface Props {
  themes: Theme[];
  searchValue: string;
  mapSetSelections: Selection[];
  onAddMapSet: (mapSet: LayerSet, mapSetType: MapSetType) => void;
  onRemoveMapSet: (mapSetId: number) => void;
  onChangeMapSetOpacity: (mapSetId: number, opacity: number) => void;
}
export function MapSetsOverviewAllFiltered(props: Props) {
  const {
    themes,
    searchValue,
    mapSetSelections,
    onAddMapSet,
    onRemoveMapSet,
    onChangeMapSetOpacity,
  } = props;

  const filteredThemes = themes
    .map((theme) => ({
      ...theme,
      layerSets: [
        ...theme.layerSets.filter((layerSet) =>
          layerSet.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
      ],
    }))
    .filter((theme) => theme.layerSets.length > 0);

  return (
    <>
      {filteredThemes.map((theme) => (
        <MyAccordion
          theme={theme}
          key={theme.id}
          onAddMapSet={onAddMapSet}
          onRemoveMapSet={onRemoveMapSet}
          onChangeMapSetOpacity={onChangeMapSetOpacity}
          mapSetSelections={mapSetSelections}
        />
      ))}
    </>
  );
}

interface Propsies {
  theme: Theme;
  mapSetSelections: Selection[];
  onAddMapSet: (mapSet: LayerSet, mapSetType: MapSetType) => void;
  onRemoveMapSet: (mapSetId: number) => void;
  onChangeMapSetOpacity: (mapSetId: number, opacity: number) => void;
}

export function MyAccordion(props: Propsies) {
  const {
    theme,
    mapSetSelections,
    onAddMapSet,
    onRemoveMapSet,
    onChangeMapSetOpacity,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  function toggleAccordion() {
    setIsExpanded((prev) => {
      return !prev;
    });
  }

  return (
    <StyledAccordion
      elevation={0}
      disableGutters
      onChange={toggleAccordion}
      expanded={isExpanded}
    >
      <AccordionSummary expandIcon={<ArrowRight />}>
        <Typography variant="subtitle1">{theme.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {theme.layerSets.map((mapSet) => {
          return (
            <MapSetOverviewAllItem
              key={mapSet.id}
              mapSetSelections={mapSetSelections}
              mapSet={mapSet}
              onAddMapSet={onAddMapSet}
              onRemoveMapSet={onRemoveMapSet}
              onChangeMapSetOpacity={onChangeMapSetOpacity}
            />
          );
        })}
      </AccordionDetails>
    </StyledAccordion>
  );
}

const StyledAccordion = styled(Accordion)`
  .MuiAccordionDetails-root {
    min-height: ${({ theme }) => theme.spacing(4)};
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  &.MuiAccordion-root::before {
    visibility: hidden;
  }
  &.MuiAccordion-root {
    background-color: transparent;
  }
  .MuiAccordionSummary-expandIconWrapper {
    transition: transform 320ms ease-out;
    &.Mui-expanded {
      transform: rotate(90deg);
    }
  }

  .MuiAccordionSummary-root {
    padding: 0;
    flex-direction: row-reverse;
  }
`;
