import { ArrowRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LayerSet, MapSetType, Selection, Theme } from "../../types";
import { MapSetOverviewAllItem } from "./MapSetOverviewAllItem";

interface Props {
  theme: Theme;
  currentTheme: Theme;
  mapSetSelections: Selection[];
  defaultExpanded: boolean;
  onAddMapSet: (mapSet: LayerSet, mapSetType: MapSetType) => void;
  onRemoveMapSet: (mapSetId: number) => void;
  onChangeMapSetOpacity: (mapSetId: number, opacity: number) => void;
}

export function MapSetsOverviewAllAccordion(props: Props) {
  const {
    theme,
    mapSetSelections,
    defaultExpanded,
    onAddMapSet,
    onRemoveMapSet,
    onChangeMapSetOpacity,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  function toggleAccordion() {
    setIsExpanded((prev) => {
      return !prev;
    });
  }

  useEffect(() => {
    if (defaultExpanded === true) {
      return setIsExpanded(true);
    }
    return setIsExpanded(false);
  }, [defaultExpanded]);

  return (
    <StyledAccordion
      elevation={0}
      disableGutters
      onChange={toggleAccordion}
      expanded={isExpanded}
    >
      <AccordionSummary expandIcon={<ArrowRight />}>
        <Typography variant="subtitle1">{theme.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {theme.layerSets.map((mapSet) => {
          return (
            <MapSetOverviewAllItem
              key={mapSet.id}
              mapSetSelections={mapSetSelections}
              mapSet={mapSet}
              onAddMapSet={onAddMapSet}
              onRemoveMapSet={onRemoveMapSet}
              onChangeMapSetOpacity={onChangeMapSetOpacity}
            />
          );
        })}
      </AccordionDetails>
    </StyledAccordion>
  );
}

const StyledAccordion = styled(Accordion)`
  .MuiAccordionDetails-root {
    min-height: ${({ theme }) => theme.spacing(4)};
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  &.MuiAccordion-root::before {
    visibility: hidden;
  }
  &.MuiAccordion-root {
    background-color: transparent;
  }
  .MuiAccordionSummary-expandIconWrapper {
    transition: transform 320ms ease-out;
    &.Mui-expanded {
      transform: rotate(90deg);
    }
  }

  .MuiAccordionSummary-root {
    padding: 0;
    flex-direction: row-reverse;
  }
`;
