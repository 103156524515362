import { ArrowRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LayerSet, ThemeScenario } from "../../../types";
import { LegendListItem } from "./LegendListItem";

interface Props {
  mapSet: LayerSet;
  activeScenario: ThemeScenario;
}

export function LegendAccordion(props: Props) {
  const { mapSet, activeScenario } = props;
  const isPresent = activeScenario.scenario.climateScenario === "Huidig";
  const { t } = useTranslation();
  return (
    <StyledAccordion elevation={0} disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<ArrowRight />}>
        <Typography
          variant="subtitle1"
          color={mapSet.layers.length === 0 ? "error" : ""}
        >
          {mapSet.name} {mapSet.unit ? `(${mapSet.unit})` : ""}
        </Typography>
        {mapSet.attribution && (
          <Typography variant="small-italic">{mapSet.attribution}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {mapSet.layers.length > 0 && <LegendListItem mapSet={mapSet} />}
        {mapSet.layers.length === 0 && (
          <Typography>
            {isPresent &&
              t("label.NoActiveLayerForScenarioPresent", {
                scenarioName: activeScenario.scenario.climateScenario,
              })}

            {!isPresent &&
              t("label.NoActiveLayerForScenario", {
                scenarioName: activeScenario.scenario.climateScenario,
                scenarioYear: activeScenario.scenario.year,
              })}
          </Typography>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
}

const StyledAccordion = styled(Accordion)`
  .MuiAccordionDetails-root {
    min-height: ${({ theme }) => theme.spacing(4)};
    padding: 0;
  }
  &.MuiAccordion-root::before {
    visibility: hidden;
  }
  &.MuiAccordion-root {
    background-color: transparent;
  }
  .MuiAccordionSummary-expandIconWrapper {
    transition: transform 320ms ease-out;
    margin-top: 10px;
    &.Mui-expanded {
      transform: rotate(90deg);
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: column;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
