import { Skeleton, styled } from "@mui/material";
import { PaddedPaper } from "../surfaces/PaddedPaper";

export function GraphSkeleton() {
  return (
    <StyledPaddedPaper elevation={0}>
      <div className="column">
        <Skeleton width={250} height={250} />
        <Skeleton width={250} height={250} />
      </div>
      <div className="column">
        <Skeleton width={250} height={250} />
        <Skeleton width={250} height={250} />
      </div>
    </StyledPaddedPaper>
  );
}

const StyledPaddedPaper = styled(PaddedPaper)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing(6)};
  &.MuiSkeleton-root {
    transform-origin: unset;
    transform: unset;
  }
  .chart-column {
    height: 100%;
    flex-grow: 1;
    display: flex;
    height: 1px;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
  }
`;
