import { Suspense, useContext, useEffect, useState } from "react";
import { LinearProgress, Portal } from "@mui/material";
import { GeoJSON as LGeoJSON } from "leaflet";
import { GeoJSON, useMap } from "react-leaflet";
import { GeoJsonProperties } from "geojson";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { useLocation } from "../../hooks/locationHooks";
import { BoundsContext } from "../store/BoundsContext";

interface Props {
  locationId: number;
}
interface FlyToLocationProps {
  mapOverlayContainer: HTMLElement | null;
}
export function FlyToLocation(props: FlyToLocationProps) {
  const { mapOverlayContainer } = props;
  const { locationId } = useParams();

  if (!locationId) return null;
  return (
    <ErrorBoundary
      fallbackRender={() => (
        <small className="sr-only">Cannot load location</small>
      )}
    >
      <Suspense
        fallback={
          <Portal container={mapOverlayContainer}>
            <LinearProgress />
          </Portal>
        }
      >
        {<DoFlyToLocation locationId={+locationId} />}
      </Suspense>
    </ErrorBoundary>
  );
}
function DoFlyToLocation(props: Props) {
  const { locationId } = props;

  const map = useMap();
  const { data: currentLocation } = useLocation(locationId, {
    enabled: locationId > 0,
  });

  const [layerRef, setLayerRef] = useState<LGeoJSON<GeoJsonProperties> | null>(
    null
  );
  const { locBounds, setLocBounds } = useContext(BoundsContext);

  useEffect(() => {
    if (map && layerRef && currentLocation && locBounds) {

      map.flyToBounds(locBounds, { animate: false });

      setLocBounds(null);
    }
  }, [map, layerRef, currentLocation, locBounds, setLocBounds]);

  return (
    <>
      {currentLocation &&
        currentLocation.geometry &&
        currentLocation.inverseGeometry && (
          <GeoJSON
            key={currentLocation.id}
            data={currentLocation.geometry}
            style={{
              fill: false,
              stroke: false,
            }}
            ref={(ref) => setLayerRef(ref)}
          />
        )}
      {currentLocation &&
        currentLocation.geometry &&
        currentLocation.inverseGeometry && (
          <GeoJSON
            key={`inverse-${currentLocation.id}`}
            data={currentLocation.inverseGeometry}
            style={{
              fillColor: "#000",
              color: "#fff",
              fillOpacity: 0.6,
              weight: 2,
              stroke: false,
            }}
          />
        )}
    </>
  );
}
