import { Paper, styled, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../../hooks/debounceHooks";
import { useMapSetSelection } from "../../hooks/mapSetHooks";
import { useSelectedThemeContext } from "../../hooks/themeHooks";
import { LayerSet, MapSetType, Selection } from "../../types";
import { MapSetsOverviewAllAccordion } from "./MapSetsOverviewAllAccordion";
import { MapSetsPersonalAccordion } from "./MapSetsPersonalAccordion";
import { MapSetsOverviewAllFiltered } from "./MapSetTestAccordion";

export function MapSetsOverviewAll() {
  const { currentTheme, themes } = useSelectedThemeContext();
  const { mapSetSelections, updateMapSetSelection } =
    useMapSetSelection(currentTheme);

  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  function onChangeMapSetOpacity(mapSetId: number, value: number) {
    const updatedMapSelection: Selection[] = mapSetSelections.map((selection) =>
      selection.id === mapSetId
        ? { ...selection, opacity: value / 100 }
        : selection
    );
    updateMapSetSelection(updatedMapSelection);
  }

  function onAddMapSet(mapSet: LayerSet, mapSetType: MapSetType) {
    const [layer] = mapSet.layers;

    updateMapSetSelection([
      {
        id: mapSet.id,
        opacity: layer.wms.opacity,
        type: mapSetType,
        added: 1,
        visible: 1,
      },
      ...mapSetSelections,
    ]);
  }

  function onRemoveMapSet(mapSetId: number) {
    updateMapSetSelection(
      mapSetSelections.filter((selection) => selection.id !== mapSetId)
    );
  }

  return (
    <>
      <StyledPaper elevation={0}>
        <TextField
          label={t("label.searchByName")}
          onChange={(e) => setSearch(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </StyledPaper>
      {debouncedSearch !== "" && (
        <MapSetsOverviewAllFiltered
          themes={themes}
          searchValue={debouncedSearch}
          mapSetSelections={mapSetSelections}
          onAddMapSet={onAddMapSet}
          onRemoveMapSet={onRemoveMapSet}
          onChangeMapSetOpacity={onChangeMapSetOpacity}
        />
      )}
      {debouncedSearch === "" &&
        themes.map((theme) => (
          <MapSetsOverviewAllAccordion
            key={theme.id}
            theme={theme}
            currentTheme={currentTheme}
            defaultExpanded={theme.id === currentTheme.id}
            mapSetSelections={mapSetSelections}
            onChangeMapSetOpacity={onChangeMapSetOpacity}
            onAddMapSet={onAddMapSet}
            onRemoveMapSet={onRemoveMapSet}
          />
        ))}
      {debouncedSearch === "" && (
        <MapSetsPersonalAccordion
          mapSetSelections={mapSetSelections}
          onChangeMapSetOpacity={onChangeMapSetOpacity}
          onAddMapSet={onAddMapSet}
          onRemoveMapSet={onRemoveMapSet}
        />
      )}
    </>
  );
}

const StyledPaper = styled(Paper)`
  width: 100%;
  z-index: 1;
  position: sticky;
  top: 0;
  padding-top: ${({ theme }) => theme.spacing(1)};
  margin-top: 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;
