import { Box, BoxProps, Slider, styled } from "@mui/material";

interface Props extends BoxProps {
  opacity: number;
  onChangeOpacity: (value: number | number[]) => void;
}
export function MapSetOpacitySlider(props: Props) {
  const { opacity, onChangeOpacity, ...rest } = props;

  return (
    <StyledBox {...rest}>
      <Slider
        size="small"
        value={opacity * 100}
        step={10}
        onChange={(e, value) => onChangeOpacity(value)}
        valueLabelDisplay="auto"
      />
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;
