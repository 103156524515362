import { Suspense, useRef, useState } from "react";
import { Fab, styled, Popover, Tooltip } from "@mui/material";
import { Layers } from "@mui/icons-material";
import { ControlOptions } from "leaflet";
import { MapControlBase } from "../MapControlBase";

import { BackgroundLayerHandler } from "./BackgroundLayerHandler";
import { ListSkeleton } from "../../skeletons/ListSkeleton";
import { useTranslation } from "react-i18next";

interface Props extends ControlOptions {}

export function SelectBackgroundLayerControl(props: Props) {
  const { ...controlProps } = props;
  const { t } = useTranslation();
  const SelectLayerRef = useRef<HTMLButtonElement>(null!);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MapControlBase {...controlProps}>
      <Tooltip title={t<string>("label.backgroundAndExtraLayers")} placement="left">
        <Fab
          size="small"
          variant="circular"
          onClick={() => setIsOpen((prev) => !prev)}
          ref={SelectLayerRef}
          color={isOpen ? "primary" : "default"}
        >
          <Layers />
        </Fab>
      </Tooltip>

      <StyledPopover
        className="transform-popover"
        anchorEl={SelectLayerRef.current}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsOpen((prev) => !prev)}
      >
        <Suspense fallback={<ListSkeleton />}>
          <BackgroundLayerHandler />
        </Suspense>
      </StyledPopover>
    </MapControlBase>
  );
}

const StyledPopover = styled(Popover)`
  transform: translateX(-30px);
  .MuiPaper-root {
    padding: ${({ theme }) => theme.spacing(2, 2)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
  }
`;
