import { Button, Typography, styled, Paper } from "@mui/material";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useQueryErrorResetBoundary } from "react-query";

export function GraphErrorFallback(props: FallbackProps) {
  const { resetErrorBoundary } = props;
  const { reset } = useQueryErrorResetBoundary();
  const { t } = useTranslation();

  function onClick() {
    reset();
    resetErrorBoundary();
  }

  return (
    <Styles>
      <Typography>{t("label.unableToLoadData")}</Typography>
      <Button variant="text" onClick={onClick}>
        {t("label.tryAgain")}
      </Button>
    </Styles>
  );
}

const Styles = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 250px;

  align-items: center;
  justify-content: center;
`;
