import { Fragment, ReactNode } from "react";
import { styled, TableCell } from "@mui/material";

interface Props {
  label: string;
  actions?: ReactNode[];
}

export function DataTableHeaderCell(props: Props) {
  const { label, actions } = props;
  return (
    <StyledTableCell>
      {label}

      {actions?.map((action, index) => (
        <Fragment key={index}>{action}</Fragment>
      ))}
    </StyledTableCell>
  );
}

const StyledTableCell = styled(TableCell)``;
