import { styled } from "@mui/material";
import { Outlet, useOutletContext } from "react-router-dom";

import { SideNav } from "../components/layout/SideNav";
import { PaddedPaper } from "../components/surfaces/PaddedPaper";

export function SideNavLayout() {
  const outletContext = useOutletContext();

  return (
    <Styles>
      <SideNav className="sidenav" />
      <PaddedPaper className="flex-container" elevation={0}>
        <Outlet context={outletContext} />
      </PaddedPaper>
    </Styles>
  );
}

const Styles = styled("main")`
  flex-grow: 1;
  display: flex;
  .flex-container {
    margin-left: ${({ theme }) => theme.spacing(8)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;
