import { useTheme } from "@mui/material";
import { LegendSymbolizerRaster } from "../../../hooks/legendHooks";

interface Props {
  symbolizer: LegendSymbolizerRaster;
}

export function LegendSymbolizerItemRaster(props: Props) {
  const { symbolizer } = props;
  const theme = useTheme();
  const { main: primaryColor } = theme.palette.primary;

  return (
    <svg x="0" y="0" width="10" height="10">
      <rect
        {...symbolizer.Raster}
        fill={primaryColor}
        x="0"
        y="0"
        width="2.5"
        height="2.5"
      />
      <rect
        fill={primaryColor}
        {...symbolizer.Raster}
        x="5"
        y="0"
        width="2.5"
        height="2.5"
      />
      <rect
        fill={primaryColor}
        {...symbolizer.Raster}
        x="2.5"
        y="2.5"
        width="2.5"
        height="2.5"
      />
      <rect
        fill={primaryColor}
        {...symbolizer.Raster}
        x="7.5"
        y="2.5"
        width="2.5"
        height="2.5"
      />
      <rect
        fill={primaryColor}
        {...symbolizer.Raster}
        x="0"
        y="5"
        width="2.5"
        height="2.5"
      />
      <rect
        fill={primaryColor}
        {...symbolizer.Raster}
        x="5"
        y="5"
        width="2.5"
        height="2.5"
      />
      <rect
        fill={primaryColor}
        {...symbolizer.Raster}
        x="2.5"
        y="7.5"
        width="2.5"
        height="2.5"
      />
      <rect
        fill={primaryColor}
        {...symbolizer.Raster}
        x="7.5"
        y="7.5"
        width="2.5"
        height="2.5"
      />
      <rect
        width="10"
        height="10"
        x="0"
        y="0"
        strokeWidth={0.5}
        stroke={primaryColor}
        fillOpacity={0}
      />
    </svg>
  );
}
