import { styled, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DataFilter,
  IndicatorDataFilter,
  IndicatorDataResponse,
  SortingList,
} from "../../types";
import { DataFilterMenu } from "./DataFilterMenu";
import { DataTableHeaderCell } from "./DataTableHeaderCell";
import { DataTableSortAction } from "./DataTableSortAction";

interface Props {
  tableData: IndicatorDataResponse;
  sortingList: SortingList[];
  filterData: IndicatorDataFilter;
  isLoading: boolean;
  onSort: (sortingList: SortingList[]) => void;
  onToggleFilter: (
    value: DataFilter,
    property: keyof IndicatorDataFilter
  ) => void;
}
export function DataTableHeader(props: Props) {
  const {
    tableData: { indicatorDataHeader },
    sortingList,
    filterData,
    isLoading,
    onToggleFilter,
    onSort,
  } = props;
  const { t } = useTranslation();
  return (
    <StyledTableHead>
      <TableRow>
        <DataTableHeaderCell
          label={t("label.theme")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"theme"}
            />,
            <DataFilterMenu
              filterValues={filterData.themeFilter}
              filterPropertyName={"themeFilter"}
              distinctValues={indicatorDataHeader.themeDistinct}
              onToggleFilter={onToggleFilter}
            />,
          ]}
        />
        <DataTableHeaderCell
          label={t("label.indicator")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"indicator"}
            />,
            <DataFilterMenu
              filterValues={filterData.indicatorFilter}
              filterPropertyName={"indicatorFilter"}
              distinctValues={indicatorDataHeader.indicatorDistinct}
              onToggleFilter={onToggleFilter}
            />,
          ]}
        />
        <DataTableHeaderCell
          label={t("label.locationType")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"locationType"}
            />,
            <DataFilterMenu
              filterValues={filterData.locationTypeFilter}
              filterPropertyName={"locationTypeFilter"}
              distinctValues={indicatorDataHeader.locationTypeDistinct}
              onToggleFilter={onToggleFilter}
            />,
          ]}
        />
        <DataTableHeaderCell
          label={t("label.locationName")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"locationNameAndCode"}
            />,
            <DataFilterMenu
              filterValues={filterData.locationNameAndCodeFilter}
              filterPropertyName={"locationNameAndCodeFilter"}
              distinctValues={indicatorDataHeader.locationNameAndCodeDistinct}
              onToggleFilter={onToggleFilter}
            />,
          ]}
        />
        <DataTableHeaderCell
          label={t("label.monthSeason")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"period"}
            />,
            <DataFilterMenu
              filterValues={filterData.periodFilter}
              filterPropertyName={"periodFilter"}
              distinctValues={indicatorDataHeader.periodDistinct}
              onToggleFilter={onToggleFilter}
            />,
          ]}
        />
        <DataTableHeaderCell
          label={t("label.scenarioPeriod")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"scenario"}
            />,
            <DataFilterMenu
              filterValues={filterData.scenarioFilter}
              filterPropertyName={"scenarioFilter"}
              distinctValues={indicatorDataHeader.scenarioDistinct}
              onToggleFilter={onToggleFilter}
            />,
          ]}
        />
        <DataTableHeaderCell
          label={t("label.value")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"value"}
            />,
          ]}
        />
        <DataTableHeaderCell
          label={t("label.unit")}
          actions={[
            <DataTableSortAction
              sortingList={sortingList}
              isLoading={isLoading}
              onSort={onSort}
              sortPropertyName={"unit"}
            />,
            <DataFilterMenu
              filterValues={filterData.unitFilter}
              filterPropertyName={"unitFilter"}
              distinctValues={indicatorDataHeader.unitDistinct}
              onToggleFilter={onToggleFilter}
            />,
          ]}
        />
      </TableRow>
    </StyledTableHead>
  );
}

const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;
