import { ArrowBack } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";

interface Props {
  open?: boolean;
  togglePanel: () => void;
}

export function CollapsePanelButton(props: Props) {
  const { open, togglePanel } = props;
  return (
    <StyledIconButton
      onClick={togglePanel}
      className={`toggle-button  ${open ? "open" : "closed"}`}
    >
      <ArrowBack />
    </StyledIconButton>
  );
}

const StyledIconButton = styled(IconButton)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  background-color: ${({ theme }) => theme.palette.background.paper};
  position: absolute;
  right: -10px;
  top: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  & > .MuiSvgIcon-root {
    transition: transform 320ms ease-in-out;
    font-size: 20px;
  }

  &.closed {
    /* transition: background-color 500ms ease-in-out; */
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    box-shadow: ${({ theme }) => theme.shadows[3]};

    right: -24px;

    & > .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }
  transform: translateY(-50%);
`;
