import { memo, Suspense } from "react";
import { Box, Skeleton, styled } from "@mui/material";
import { Room } from "@mui/icons-material";
import { LatLngLiteral } from "leaflet";
import { Popup } from "react-leaflet";
import { Theme } from "../../../types";
import { InfoTable } from "./InfoTable";

interface Props {
  point: LatLngLiteral;
  currentTheme: Theme;
  themes: Theme[];
}
export const InfoPopup = memo((props: Props) => {
  const { point, currentTheme, themes } = props;

  return (
    <StyledPop
      position={point}
      autoPan={true}
      closeButton={false}
      autoPanPadding={[point.lat, point.lng]}
    >
      <Suspense
        fallback={
          <Box className="skeleton-style">
            <Skeleton height={50} />
            <Skeleton height={50} />
          </Box>
        }
      >
        <InfoTable point={point} currentTheme={currentTheme} themes={themes} />
      </Suspense>
      <Room fontSize="large" color="success" className="room-icon" />
    </StyledPop>
  );
});

const StyledPop = styled(Popup)`
  .leaflet-popup-content {
    width: ${({ theme }) => theme.spacing(100)} !important;
    margin: 0;
  }
  .leaflet-popup-content p {
    margin: 0;
  }
  .leaflet-popup-tip-container {
    visibility: hidden;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }
  .room-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: -18px;
  }
  .skeleton-style {
    padding: ${({ theme }) => theme.spacing(1)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    .MuiSkeleton-root {
      transform: unset;
      transform-origin: unset;
    }
  }
`;
