import { ChangeEvent, ReactNode } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";

import { LocationTypeProps } from "../../types";
import { useUrlLocationType } from "../../hooks/locationHooks";

interface Props extends LocationTypeProps {
  extraOption?: ReactNode;
}

export function LocationTypeRadioForm(props: Props) {
  const { types, selectedType, onChange, extraOption } = props;
  const { updateUrlLocationType } = useUrlLocationType();
  function onRadioChange(event: ChangeEvent<HTMLInputElement>) {
    const [type] = types.filter(
      (current) => current.key === event.target.value
    );
    updateUrlLocationType(type.key);
    onChange(type);
  }

  return (
    <StyledFormControl>
      <RadioGroup row onChange={onRadioChange}>
        {types.map((locationType) => (
          <FormControlLabel
            key={locationType.key}
            value={locationType.key}
            control={<Radio size="small" />}
            label={locationType.name}
            checked={selectedType === locationType}
          />
        ))}
      </RadioGroup>
      {extraOption}
    </StyledFormControl>
  );
}

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  .MuiFormControlLabel-root {
    .MuiTypography-root {
      font-size: ${({ theme }) => theme.spacing(1.7)};
    }
  }
`;
