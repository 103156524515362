import axios, { AxiosError } from "axios";
import { APIError } from "../types/Error";

export function parseServerError(error: APIError) {
  const replaceRegEx = /\{([0-9]+)\}/gi;
  return error.message.replace(
    replaceRegEx,
    (matched, digit) => error.arguments[digit]
  );
}

export function isAPIError(error: APIError | unknown): error is APIError {
  if (typeof error !== "object" || error === null) return false;
  return (
    error.hasOwnProperty("key") &&
    error.hasOwnProperty("message") &&
    error.hasOwnProperty("arguments")
  );
}

export interface ErrorFormat {
  title: string;
  message: string;
}

export function deserializeErrors(
  errors: APIError[] | AxiosError | Error | unknown
): ErrorFormat[] {
  if (errors instanceof Array) {
    return errors.map((error) => {
      return deserializeError(error);
    });
  } else {
    return [deserializeError(errors)];
  }
}

function deserializeError(
  error: APIError | AxiosError | Error | unknown
): ErrorFormat {
  if (isAPIError(error)) {
    const message = parseServerError(error);
    return {
      title: error.key,
      message: message,
    };
  }

  if (axios.isAxiosError(error)) {
    return { title: error.name, message: error.message };
  }

  if (error instanceof Error) {
    return { title: error.name, message: error.message };
  }
  return { title: "Unknown error", message: "Woops, something went wrong..." };
}
