import { memo, Suspense, useState } from "react";
import { styled } from "@mui/material";
import { Room } from "@mui/icons-material";
import { LatLng } from "leaflet";
import { Popup } from "react-leaflet";
import { useTranslation } from "react-i18next";

import { useMapPoint } from "../../../hooks/mapHooks";

import { ListSkeleton } from "../../skeletons/ListSkeleton";
import { SearchList } from "./SearchList";
import { MapClickHandler } from "../MapClickHandler";
import { ClickOnMapAlert } from "../ClickOnMapAlert";

interface Props {
  onAlertClick?: () => void;
}

export function SearchLocationPopup(props: Props) {
  const { onAlertClick: onSideNavMapAlertClick } = props;
  const [, setPoint] = useMapPoint();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(true);

  const [latLng, setLatLng] = useState<LatLng>();

  function onClick(latlng: LatLng) {
    setPoint(latlng);
    setLatLng(latlng);
  }

  function onAlertClick() {
    setIsActive(false);
    if (onSideNavMapAlertClick) {
      onSideNavMapAlertClick();
    }
  }

  return (
    <>
      <MapClickHandler onClick={onClick} />
      <ClickOnMapAlert
        isActive={isActive}
        onAlertClick={onAlertClick}
        alertText={t("label.clickOnTheMapToSelectAnArea")}
      />
      {latLng && <SearchPopup latLng={latLng} />}
    </>
  );
}

interface SearchPopupProps {
  latLng: LatLng;
}

const SearchPopup = memo((props: SearchPopupProps) => {
  const { latLng } = props;
  return (
    <StyledPop position={latLng}>
      <Suspense fallback={<ListSkeleton />}>
        <SearchList point={latLng} />
      </Suspense>
      <Room fontSize="large" color="success" />
    </StyledPop>
  );
});

const StyledPop = styled(Popup)`
  .leaflet-popup-tip-container {
    visibility: hidden;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    width: ${({ theme }) => theme.spacing(33)};
  }
  .MuiSvgIcon-root {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: -18px;
  }
`;
