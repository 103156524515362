import { LatLngBounds } from "leaflet";

import { useMap, useMapEvents } from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import { mapURLSearchParams } from "../../utils/SearchParamUtils";

export function PersistMapPosition() {
  const [queryParams, setQueryParams] = useSearchParams();
  const map = useMap();

  useMapEvents({
    moveend: (event) => {
      persistBounds(map.getBounds());
    },
  });

  function persistBounds(bounds: LatLngBounds) {
    const formattedBounds = bounds.toBBoxString();
    const updatedParams = mapURLSearchParams(queryParams, ["bounds"]);
    updatedParams.set("bounds", formattedBounds);
    setQueryParams(updatedParams);
  }

  // render the component empty
  return null;
}
