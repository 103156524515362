import { FormControl, FormLabel, styled } from "@mui/material";
import { TextField as FormikTextField, TextFieldProps } from "formik-mui";

interface Props extends TextFieldProps {
  customHelperText?: string;
}
export function TextField(props: Props) {
  const { label, fullWidth, customHelperText, className, ...formProps } = props;

  return (
    <StyledFormControl fullWidth={fullWidth} className={className}>
      <FormLabel>{label}</FormLabel>
      <FormikTextField
        fullWidth={fullWidth}
        {...formProps}
        helperText={customHelperText ? customHelperText : " "}
      />
    </StyledFormControl>
  );
}
const StyledFormControl = styled(FormControl)`
  .MuiFormLabel-root {
    font-weight: bolder;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
  .MuiFormHelperText-root {
    margin-left: 0;
  }
`;
