//Generate imports

import { Popover, styled } from "@mui/material";
import { useActiveScenario } from "../../hooks/scenarioHooks";
import { useSelectedThemeContext } from "../../hooks/themeHooks";
import { LayerSet } from "../../types";
import { MapSetVerticalCard } from "./MapSetVerticalCard";

interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  mapSet: LayerSet;
  // currentTheme: Theme;
}
export default function MapSetPopover(props: Props) {
  const { anchorEl, mapSet, handlePopoverClose } = props;
  const { currentTheme } = useSelectedThemeContext();
  const { data: scenario } = useActiveScenario(currentTheme);

  const open = Boolean(anchorEl);

  return (
    <StyledPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      className="map-set-popover"
    >
      <MapSetVerticalCard mapSet={mapSet} scenario={scenario} />
    </StyledPopover>
  );
}
const StyledPopover = styled(Popover)`
  pointer-events: none;
  margin-left: ${({ theme }) => theme.spacing(2)};

  .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.grey[200]};
    pointer-events: auto;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    overflow: unset;
    position: relative;
    ::after {
      position: absolute;
      width: 0px;
      height: 0px;
      border-top: 10px solid #18183d00;
      border-right: 10px solid #ffffff00;
      top: 50%;
      border-left: 10px solid ${(props) => props.theme.palette.grey[200]};
      border-bottom: 10px solid ${(props) => props.theme.palette.grey[200]};
      left: -10px;
      content: "";
      box-shadow: -5px 5px 6px -2px rgb(0 0 0 / 9%);
      transform: rotate(45deg);
      margin-top: -10px;
    }
  }
`;
