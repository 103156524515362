import { Navigate, Outlet, useParams } from "react-router-dom";
import { useSelectedThemeContext } from "../../hooks/themeHooks";

export function LocationGuard() {
  const { locationId } = useParams();
  const { themes, currentTheme } = useSelectedThemeContext();
  const locationIdFormatted = +(locationId || "");
  
  return (
    <>
      {!locationIdFormatted && <Navigate to={`/${currentTheme.id}`} />}
      {locationIdFormatted > 0 && <Outlet context={{ themes, currentTheme }} />}
    </>
  );
}
