import { Suspense } from "react";
import { styled } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";

import { LegendData } from "./LegendData";
import { ExtendedWMSParams, Layer } from "../../../types";
import { LegendItemSkeleton } from "../../skeletons/LegendItemSkeleton";
import { LegendItemErrorImageFallBack } from "../../error/LegendItemErrorImageFallBack";

interface Props {
  id: number;
  url: string;
  wmsParams: ExtendedWMSParams;
  layer: Layer;
}

export function LegendLoad(props: Props) {
  const { id, wmsParams, url, layer } = props;

  return (
    <LegendStyles className="legend-item" key={`legend-styles-${id}`}>
      <Suspense key={`legend-${id}`} fallback={<LegendItemSkeleton />}>
        <ErrorBoundary
          key={`legend-error-image-${id}`}
          fallback={<LegendItemErrorImageFallBack layer={layer} />}
        >
          <Suspense key={`legend-${id}`} fallback={<LegendItemSkeleton />}>
            <LegendData
              id={id}
              wms={wmsParams}
              url={url}
              shouldDisplayTitle={layer.type === "LAYERGROUP"}
            />
          </Suspense>
        </ErrorBoundary>
      </Suspense>
    </LegendStyles>
  );
}

const LegendStyles = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  .error-icon {
    max-width: 12px;
    max-height: 12px;
  }

  .image-fallback {
    align-self: flex-start;
  }
`;
