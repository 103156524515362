import { Button, Alert } from "@mui/material";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useQueryErrorResetBoundary } from "react-query";

export function DataPageErrorFallback(props: FallbackProps) {
  const { resetErrorBoundary } = props;
  const { reset } = useQueryErrorResetBoundary();
  const { t } = useTranslation();

  function onClick() {
    reset();
    resetErrorBoundary();
  }

  return (
    <Alert
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={onClick}>
          {t("label.tryAgain")}
        </Button>
      }
    >
      {t("label.unableToLoadData")}
    </Alert>
  );
}
