import { useContext, useEffect } from "react";
import { JoyrideContext } from "./JoyrideContext";

export function JoyrideActivator() {
  const { toggleJoyride, isJoyrideActive, runJoyride } =
    useContext(JoyrideContext);
  useEffect(() => {
    if (isJoyrideActive && !runJoyride) {
      toggleJoyride(true);
    }
  }, [toggleJoyride, isJoyrideActive, runJoyride]);
  return null;
}
