import { createContext, ReactNode, useState } from "react";
import { CallBackProps, Step, EVENTS } from "react-joyride";
import { useLocation } from "react-router-dom";
import { useJoyrideSteps } from "../../hooks/joyrideHooks";
import { useLocationNamed } from "../../hooks/locationHooks";
import { useNavigate } from "../../hooks/routerHooks";

interface JoyrideContextValues {
  runJoyride: boolean;
  toggleJoyride: (value: boolean) => void;
  activateJoyride: (value: boolean) => void;
  callbackFunction: (data: CallBackProps) => void;
  setNextIndex: (value: number) => void;
  isJoyrideActive: boolean;
  steps: Step[];
  stepIndex: number;
}

export const JoyrideContext = createContext<JoyrideContextValues>({
  runJoyride: false,
  isJoyrideActive: false,
  toggleJoyride: (value: boolean) => {},
  activateJoyride: (value: boolean) => {},
  callbackFunction: (data: CallBackProps) => {},
  setNextIndex: (value: number) => {},
  steps: [],
  stepIndex: 0,
});

interface Props {
  children: ReactNode;
}

export function JoyrideContextProvider(props: Props) {
  const { children } = props;

  const { pathname } = useLocation();
  const { data: locations } = useLocationNamed("mol", "gemeente", {
    enabled: true,
  });

  const navigate = useNavigate();
  const steps = useJoyrideSteps();

  const [joyrideLocation] = locations || [];

  const [runJoyride, setRunJoyride] = useState<boolean>(false);
  const [isJoyrideActive, setIsJoyrideActive] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  function activateJoyride(value: boolean) {
    setRunJoyride(value);
    setIsJoyrideActive(value);
  }

  function callbackFunction(data: CallBackProps) {
    if (data.type === EVENTS.TOUR_END || data.action === "close") {
      activateJoyride(false);
      return setNextIndex(0);
    }

    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
        data.type
      )
    ) {
      if (data.index === 2 && data.action === "next") {
        navigate(`${pathname}/location/${joyrideLocation?.id}`);
      }

      if (data.index === 3 && data.action === "prev") {
        setRunJoyride(false);
        navigate("/");
      }

      const nextIndex = stepIndex + (data.action === "prev" ? -1 : 1);

      setNextIndex(nextIndex);
    }
  }

  function setNextIndex(value: number) {
    setStepIndex(value);
  }

  function toggleJoyride(value: boolean) {
    setRunJoyride(value);
  }

  return (
    <JoyrideContext.Provider
      value={{
        runJoyride,
        steps,
        isJoyrideActive,
        stepIndex,
        activateJoyride,
        setNextIndex,
        callbackFunction,
        toggleJoyride,
      }}
    >
      {children}
    </JoyrideContext.Provider>
  );
}
