import { useQueryClient } from "react-query";
import { usePersonalMapSets } from "../../hooks/mapSetHooks";
import { MapSetFormData } from "../../types";

import {
  formatToMapSet,
  formatToMapSetFormData,
} from "../../utils/MapSetUtils";

import { MapSetForm } from "./MapSetForm";

interface Props {
  mapSetId: number;
  setIsOpen: (isOpen: boolean) => void;
}

export function MapSetEdit(props: Props) {
  const { setIsOpen, mapSetId } = props;
  const { data: fetchedMapsets } = usePersonalMapSets();
  const mapSets = fetchedMapsets || [];
  const queryClient = useQueryClient();

  const currentMapSet = mapSets.find((mapSet) => mapSet.id === mapSetId);

  function onDelete() {
    const filteredMapSets = mapSets.filter((mapSet) => mapSet.id !== mapSetId);
    localStorage.setItem("mapSets", JSON.stringify([...filteredMapSets]));
    queryClient.invalidateQueries("personalMapSets");
    setIsOpen(false);
  }

  function onSubmit(values: MapSetFormData) {
    const mapSet = formatToMapSet(values);

    const mapSetIndex = mapSets.findIndex((set) => set.id === mapSet.id);
    const updatedMapSet = { ...mapSets[mapSetIndex], ...mapSet };
    const updatedMapSets = [
      ...mapSets.slice(0, mapSetIndex),
      updatedMapSet,
      ...mapSets.slice(mapSetIndex + 1),
    ];
    localStorage.setItem("mapSets", JSON.stringify([...updatedMapSets]));
    queryClient.invalidateQueries("personalMapSets");
    setIsOpen(false);
  }

  return (
    <>
      {currentMapSet && (
        <MapSetForm
          initialValues={formatToMapSetFormData(currentMapSet)}
          onSubmit={onSubmit}
          isEditing={true}
          onDelete={onDelete}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}
