import { Suspense, useState } from "react";
import { styled } from "@mui/material";
import { Outlet } from "react-router-dom";

import { useDrawerKey, useDrawerUrlParams } from "../hooks/drawerHooks";
import { useSelectedThemeContext } from "../hooks/themeHooks";
import { useMapModeSidenavMapLayout } from "../hooks/mapHooks";

import { CollapsibleDrawer } from "../components/layout/CollapsibleDrawer";
import { MapThemeNavigation } from "../components/layout/MapThemeNavigation";
import { SideNav } from "../components/layout/SideNav";
import { MapContent } from "../components/map/MapContent";
import { LocationTypeSearchClickHandler } from "../components/map/LocationTypeSearch/LocationTypeSearchClickHandler";
import { FlyToLocation } from "../components/map/FlyToLocation";
import { LoadingBox } from "../components/map/LoadingBox";
import { SearchLocationPopup } from "../components/map/search/SearchLocationPopup";
import { LayerPointControl } from "../components/map/LayerPointControl";
import { AddressMarker } from "../components/map/AddressMarker";

interface Props {
  panelSize: number;
}

export function SideNavMapLayout(props: Props) {
  const { panelSize } = props;
  const { currentTheme, themes } = useSelectedThemeContext();

  const drawerKey = useDrawerKey();
  const { drawerTypeState, updateDrawerState } = useDrawerUrlParams(drawerKey);
  const { currentMapMode, updateMapMode } = useMapModeSidenavMapLayout();

  const [mapOverlayContainer, setMapOverlayContainer] =
    useState<HTMLDivElement | null>(null);

  function setPortalContainer(container: HTMLDivElement) {
    setMapOverlayContainer(container);
  }

  function togglePanel() {
    if (drawerKey) updateDrawerState(drawerKey);
  }

  function toggleActiveMapMode() {
    if (currentMapMode === "spi") return updateMapMode("slt");
    return updateMapMode("spi");
  }

  function onSearchLocationPopupAlertClick() {
    return updateMapMode("slt");
  }

  return (
    <Styles panelSize={panelSize}>
      <SideNav className="sidenav" />
      <CollapsibleDrawer
        className={`panel ${
          drawerTypeState ? "collapsed" : "open unset-overflow-reactdnd"
        } `}
        togglePanel={togglePanel}
        open={!!!drawerTypeState}
      >
        <Outlet context={{ currentTheme: currentTheme, themes: themes }} />
      </CollapsibleDrawer>
      <section className="map-section">
        <MapThemeNavigation className="calc-tab-width" />
        <MapContent>
          <AddressMarker />
          <LoadingBox setPortalContainer={setPortalContainer} />;
          {currentMapMode === "slt" && (
            <Suspense fallback={<></>}>
              <LocationTypeSearchClickHandler
                mapOverlayContainer={mapOverlayContainer}
              />
            </Suspense>
          )}
          <FlyToLocation mapOverlayContainer={mapOverlayContainer} />
          {currentMapMode === "som" && (
            <SearchLocationPopup
              onAlertClick={onSearchLocationPopupAlertClick}
            />
          )}
          <LayerPointControl
            position="topright"
            isActive={currentMapMode === "spi"}
            themes={themes}
            currentTheme={currentTheme}
            toggleActiveMapMode={toggleActiveMapMode}
          />
        </MapContent>
      </section>
    </Styles>
  );
}

interface PanelSizeProps {
  panelSize: number;
}

const Styles = styled("main", {
  shouldForwardProp: (prop) => !["panelSize"].includes(prop.toString()),
})<PanelSizeProps>`
  flex-grow: 1;
  display: flex;
  .calc-tab-width {
    width: calc(
      100vw - ${({ theme, panelSize }) => theme.spacing(panelSize + 8)}
    );
  }

  .panel {
    width: ${({ theme, panelSize }) => theme.spacing(panelSize)};
    transition: width 320ms ease-in-out;
    margin-left: ${({ theme }) => theme.spacing(8)};
    &.collapsed {
      width: ${({ theme }) => theme.spacing(0)};
    }
  }
  .map-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .unset-overflow-reactdnd {
    & > .MuiPaper-root {
      overflow-y: unset;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    .panel {
      width: ${({ theme }) => theme.spacing(51)};
    }
    .calc-tab-width {
      width: calc(100vw - ${({ theme, panelSize }) => theme.spacing(51 + 8)});
    }
  }
`;
