import { LegendSymbolizerPolygon } from "../../../hooks/legendHooks";

interface Props {
  symbolizer: LegendSymbolizerPolygon;
}

export function LegendSymbolizerItemPolygon(props: Props) {
  const { symbolizer } = props;

  return (
    <svg x="0" y="0" width="10" height="10">
      <rect {...symbolizer.Polygon} x="0" y="0" width="10" height="10" />
    </svg>
  );
}
