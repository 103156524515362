import {
  CircularProgress,
  styled,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IndicatorDataResponse } from "../../types";
import { round } from "../../utils/calcUtils";

interface Props {
  isLoading: boolean;
  tableData: IndicatorDataResponse;
}

export function DataTableBody(props: Props) {
  const { tableData, isLoading } = props;
  const { t } = useTranslation();
  return (
    <StyledTableBody>
      {!isLoading && tableData.indicatorDataPage.data.length === 0 && (
        <TableRow>
          <TableCell colSpan={8} align="center">
            {t("label.noDataFound")}
          </TableCell>
        </TableRow>
      )}
      {isLoading && (
        <TableRow>
          <TableCell colSpan={8} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}
      {!isLoading &&
        tableData.indicatorDataPage.data.length > 0 &&
        tableData.indicatorDataPage.data.map((row) => (
          <TableRow key={`${row.id}-${row.theme}`}>
            <TableCell>{row.theme}</TableCell>
            <TableCell>{row.indicator}</TableCell>
            <TableCell>{row.locationType}</TableCell>
            <TableCell>{row.locationNameAndCode}</TableCell>
            <TableCell>
              {row.period ? row.period : t("label.notApplicable")}
            </TableCell>
            <TableCell>{row.scenario}</TableCell>
            <TableCell>
              {row.value !== undefined ? round(row.value) : `-`}
            </TableCell>
            <TableCell className="unit-cell">{row.unit}</TableCell>
          </TableRow>
        ))}
    </StyledTableBody>
  );
}

const StyledTableBody = styled(TableBody)`
  .center-content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
  .MuiTableCell-root {
    max-width: 200px;
    padding-left: ${({ theme }) => theme.spacing(1)};
  }
  .unit-cell {
    max-width: ${({ theme }) => theme.spacing(10)};
  }
  tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;
