import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { ChangeEvent, Suspense, useState } from "react";
import { LayerSet, MapSetType, Selection } from "../../types";
import { MapSetOpacitySlider } from "./MapSetOpacitySlider";
import MapSetPopover from "./MapSetPopover";

interface Props {
  mapSet: LayerSet;
  mapSetSelections: Selection[];
  onAddMapSet: (mapSet: LayerSet, mapSetType: MapSetType) => void;
  onRemoveMapSet: (mapSetId: number) => void;
  onChangeMapSetOpacity: (mapSetId: number, opacity: number) => void;
}

export function MapSetOverviewAllItem(props: Props) {
  const {
    mapSet,
    mapSetSelections,
    onAddMapSet,
    onRemoveMapSet,
    onChangeMapSetOpacity,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const mapSetSelection = mapSetSelections.find(
    (mapSetSelection) => mapSetSelection.id === mapSet.id
  );

  const isActive = mapSetSelection !== undefined;

  function onChange(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      onAddMapSet(mapSet, "ms");
    } else {
      onRemoveMapSet(mapSet.id);
    }
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function onChangeOpacity(value: number | number[]) {
    if (Array.isArray(value)) return;
    return onChangeMapSetOpacity(mapSet.id, value);
  }

  return (
    <>
      <StyledDiv
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <FormControlLabel
          label={mapSet.name}
          control={
            <Checkbox
              onChange={onChange}
              checked={isActive}
              value={mapSet.id}
            />
          }
        />
        <Suspense fallback={<></>}>
          <MapSetPopover
            anchorEl={anchorEl}
            mapSet={mapSet}
            handlePopoverClose={handlePopoverClose}
          />
        </Suspense>
      </StyledDiv>
      {isActive && (
        <MapSetOpacitySlider
          sx={{ maxWidth: "300px", width: "100%" }}
          onChangeOpacity={onChangeOpacity}
          opacity={mapSetSelection.opacity}
        />
      )}
    </>
  );
}
const StyledDiv = styled("div")`
  width: fit-content;
  .opacity-slider {
    width: 200px;
  }
`;
