import { ReactNode, useState } from "react";
import { Alert, styled, Typography } from "@mui/material";
import { DomEvent } from "leaflet";

interface Props {
  isActive: boolean;
  alertText: string | ReactNode;
  onAlertClick: () => void;
}

export function ClickOnMapAlert(props: Props) {
  const { isActive, alertText, onAlertClick } = props;
  const [alertContainer, setAlertContainer] = useState<HTMLElement | null>(
    null
  );

  if (alertContainer) {
    DomEvent.disableClickPropagation(alertContainer);
    DomEvent.disableScrollPropagation(alertContainer);
  }

  return (
    <>
      {isActive && (
        <StyledAlert
          ref={(ref) => setAlertContainer(ref)}
          icon={false}
          variant="filled"
          onClose={onAlertClick}
        >
          <Typography>{alertText}</Typography>
        </StyledAlert>
      )}
    </>
  );
}

const StyledAlert = styled(Alert)`
  position: absolute;
  z-index: 999;
  left: 50%;
  .MuiAlert-message {
    margin-left: auto;
  }
  display: flex;
  justify-content: center;
  transform: translate(-50%, 0%);
  font-weight: bold;
  width: 100%;
  max-width: 30vw;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
