import axios from "axios";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Theme, ThemeScenarios } from "../types";

export const useScenarios = (themeId: number) => {
  async function fetch() {
    const { data } = await axios.get<ThemeScenarios>(
      `/api/themes/${themeId}/scenarios`
    );
    return data;
  }
  return useQuery(["scenarios", themeId], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useActiveScenario = (currentTheme: Theme) => {
  const { year } = useScenarioParams();
  const { data: themeScenarios } = useScenarios(currentTheme.id);
  const scenarios = themeScenarios?.scenarioList || [];

  function fetch() {
    const foundScenario = (scenarios || []).find(
      (scenario) => scenario.scenario.year === year
    );
    if (foundScenario) return Promise.resolve(foundScenario);

    return Promise.resolve(
      (scenarios || []).find(
        (scenario) => scenario.scenario.year === themeScenarios?.defaultYear
      )
    );
  }

  return useQuery(["scenarios", "active", year, currentTheme.id], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: scenarios !== undefined,
  });
};

export const useEnabledScenarios = (currentTheme: Theme) => {
  const { data: themeScenarios } = useScenarios(currentTheme.id);
  const scenarios = themeScenarios?.scenarioList || [];

  function fetch() {
    return Promise.resolve(
      (scenarios || []).filter((themeScenario) => themeScenario.enabled)
    );
  }

  return useQuery(["scenarios", "enabled", currentTheme.id], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: scenarios !== undefined,
  });
};

export const useMinMaxScenario = (currentTheme: Theme) => {
  const { data: enabledScenarios } = useEnabledScenarios(currentTheme);

  function fetch() {
    if (enabledScenarios) {
      const [first] = enabledScenarios;
      const [last] = enabledScenarios.reverse();

      return Promise.resolve({
        min: first.scenario.year,
        max: last.scenario.year,
      });
    }
    return Promise.resolve({ min: 0, max: 0 });
  }

  return useQuery(["scenarios", "min/max", currentTheme.id], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: enabledScenarios !== undefined,
  });
};

export const useScenarioParams = () => {
  const [params, setParams] = useSearchParams();
  const year = parseInt(params.get("year") || "");

  function updateScenarioParams(year: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("year", year.toString());
    setParams(newParams);
  }

  return { year, updateScenarioParams };
};
