import { useEffect } from "react";
import { useFormikContext } from "formik";
import { isEqual } from "lodash-es";
import { Location } from "../../types";

interface Props {
  initialValues: { searchResult: Location };
}
export function LocationInitialValuesCheck(props: Props) {
  const { initialValues, resetForm } = useFormikContext<{
    searchResult: Location;
  }>();
  useEffect(() => {
    if (isEqual(initialValues, props.initialValues)) return;
    resetForm({ values: props.initialValues });
  }, [props.initialValues, initialValues, resetForm]);
  return null;
}
