import { LatLng, LeafletMouseEvent } from "leaflet";
import { useMapEvents } from "react-leaflet";

interface Props {
  onClick: (latlng: LatLng) => void;
}

export const MapClickHandler = (props: Props) => {
  const { onClick } = props;

  useMapEvents({
    click: (event: LeafletMouseEvent) => {
      onClick(event.latlng);
    },
  });

  return null;
};
