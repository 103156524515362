import { ReactNode, Suspense } from "react";
import { styled } from "@mui/material";
import { MapContainer, MapContainerProps, ZoomControl } from "react-leaflet";

import { useCurrentMapBounds } from "../../hooks/mapHooks";
import { useSelectedThemeContext } from "../../hooks/themeHooks";

import { DownloadControl } from "./DownloadControl";
import { FullscreenControl } from "./FullScreenControl";
import { ShareControl } from "./ShareControl";
import { MapSizeObserver } from "./MapSizeObserver";
import { SelectBackgroundLayerControl } from "./background/SelectBackgroundLayerControl";
import { BackgroundLayerTiles } from "./background/BackgroundLayerTiles";
import { ThemeLayers } from "./ThemeLayers";
import { TimeLineControl } from "./TimeLineControl";
import { PersistMapPosition } from "./PersistMapPosition";
import { LegendControl } from "./legend/LegendControl";
import { AttributionAndScale } from "./attribution/AttributionAndScale";

import { LatLngBoundsLiteral } from "leaflet";
import { ThemeNotification } from "./ThemeNotification";

interface Props extends MapContainerProps {
  children?: ReactNode;
}

export function MapContent(props: Props) {
  const { children, ...mapContainerProps } = props;
  const { currentTheme, themes } = useSelectedThemeContext();
  const bounds = useCurrentMapBounds();

  const MAX_BOUNDS: LatLngBoundsLiteral = [
    [49.451196, 1.58137],
    [52.076186, 7.454654],
  ];

  return (
    <StylesMapContainer
      {...mapContainerProps}
      bounds={bounds}
      maxBounds={MAX_BOUNDS}
      zoomControl={false}
      attributionControl={false}
    >
      {currentTheme.notification && (
        <ThemeNotification
          position="topleft"
          notification={currentTheme.notification}
        />
      )}

      <AttributionAndScale position="bottomright" />
      <ZoomControl position="bottomright" />
      <SelectBackgroundLayerControl position="topright" />
      <FullscreenControl position="bottomright" />
      <DownloadControl position="topright" />
      <TimeLineControl position="bottomleft" currentTheme={currentTheme} />
      <ShareControl position="topright" />

      <MapSizeObserver />
      <PersistMapPosition />

      <Suspense fallback={<></>}>
        <ThemeLayers currentTheme={currentTheme} themes={themes} />
      </Suspense>
      <Suspense fallback={<></>}>
        <BackgroundLayerTiles currentTheme={currentTheme} themes={themes} />
      </Suspense>
      <Suspense fallback={<></>}>
        <LegendControl
          position="bottomright"
          currentTheme={currentTheme}
          themes={themes}
        />
      </Suspense>
      {children}
    </StylesMapContainer>
  );
}

const StylesMapContainer = styled(MapContainer)`
  flex-grow: 1;
  .leaflet-right .leaflet-control.flush-to-edge {
    margin-right: 0px;
  }
  .leaflet-bottom .leaflet-control.flush-to-edge {
    margin-bottom: 0px;
  }
  .leaflet-left .leaflet-control.flush-to-edge {
    margin-left: 0px;
  }
  .leaflet-top .leaflet-control.flush-to-edge {
    margin-top: 0px;
  }
  .leaflet-bottom .scenario-control {
    margin-bottom: ${({ theme }) => theme.spacing(4.5)};
  }

  .leaflet-top:has(> div.flush-to-edge) {
    z-index: 400;
  }

  .leaflet-control-zoom {
    border: unset;
    display: flex;
    flex-direction: column;

    a[role="button"] + a[role="button"] {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }

    a[role="button"] {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      outline: 0;
      border: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      text-decoration: none;
      font-weight: 700;
      font-size: 1rem;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-radius: 50%;
      width: ${({ theme }) => theme.spacing(5)};
      height: ${({ theme }) => theme.spacing(5)};
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
      color: rgba(0, 0, 0, 0.87);
      background-color: ${({ theme }) => theme.palette.common.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.grey[100]};
      }
    }
  }
`;
