import {
  IconButton,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ThemeScenario } from "../../../types";
import { Clear } from "@mui/icons-material";
import { useMapPoint } from "../../../hooks/mapHooks";

interface Props {
  scenarioList: ThemeScenario[];
}

export function InfoTableHeaders(props: Props) {
  const { scenarioList } = props;
  const { t } = useTranslation();
  const [, setPoint] = useMapPoint();
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {scenarioList.map((scenario) => (
          <TableCell key={scenario.scenario.year}>
            <Typography variant="h3">
              {scenario.scenario.present
                ? t("label.current")
                : scenario.scenario.year}
            </Typography>
          </TableCell>
        ))}
        <FlexCell>
          <Typography variant="h3">{t("label.unit")}</Typography>
          <IconButton size="small" onClick={() => setPoint(null)}>
            <Clear fontSize="small" />
          </IconButton>
        </FlexCell>
      </TableRow>
    </TableHead>
  );
}

const FlexCell = styled(TableCell)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .MuiIconButton-root {
    .MuiSvgIcon-root {
      font-size: 15px;
    }
  }
`;
