import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useMap } from "react-leaflet";
import { LatLngLiteral } from "leaflet";
import { FeatureCollection } from "geojson";
import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { round } from "lodash-es";

import { useActiveScenario } from "./scenarioHooks";

import {
  Layer,
  LayerSet,
  LayerWithAttribution,
  MapSetFormData,
  Scenario,
  Selection,
  Theme,
  ThemeScenario,
} from "../types";
import {
  formatLayerSetsToSelection,
  parseMapSetUrlParams,
} from "../utils/MapSetUtils";

export function useMapSetWithoutFilteredLayers(
  themes: Theme[],
  selectedMapSet: Selection
) {
  const { data: personalMapSets } = usePersonalMapSets();
  const mapSets = useAllMapSets(themes);
  const mergedMapSets = [...mapSets, ...(personalMapSets || [])];
  const mapSet = mergedMapSets.find(
    (mapSet) => mapSet.id === selectedMapSet.id
  );
  return mapSet;
}

//For layerinfo since we don't want to get personal maps
export const useVMMMapSets = (currentTheme: Theme, themes: Theme[]) => {
  const { mapSetSelections } = useMapSetSelection(currentTheme);
  return mapSetSelections.reduce(
    (mapSets: LayerSet[], selection: Selection) => {
      if (selection.type !== "ms") return [...mapSets];
      const [mapSet] = themes
        .map((theme) => theme.layerSets.find((set) => set.id === selection.id))
        .filter((set) => set !== undefined);
      if (mapSet) return [...mapSets, mapSet];

      return [...mapSets];
    },
    []
  );
};

export const useAllMapSets = (themes: Theme[]) => {
  const mapSets = themes.map((theme) => theme.layerSets).flat();
  return mapSets;
};

export const useFilteredMapSets = (themes: Theme[], currentTheme: Theme) => {
  const { mapSetSelections } = useMapSetSelection(currentTheme);
  const { data: activeScenario } = useActiveScenario(currentTheme);
  const { data: personalMapSets } = usePersonalMapSets();
  return mapSetSelections.reduce(
    (mapSets: LayerSet[], selection: Selection) => {
      if (selection.visible === 0) return [...mapSets];
      if (selection.type === "ms") {
        const [mapSet] = themes
          .map((theme) =>
            theme.layerSets.find((set) => set.id === selection.id)
          )
          .filter((set) => set !== undefined);
        if (mapSet) {
          const filteredMapSet = {
            ...mapSet,
            layers: mapSet.layers.filter(
              (layer) =>
                layer.scenario?.year === activeScenario?.scenario.year ||
                (layer.scenario?.present && activeScenario?.scenario.present)
            ),
          };
          return [...mapSets, filteredMapSet];
        }
      }
      if (selection.type === "pl") {
        const personalMapSet = personalMapSets?.find(
          (set) => set.id === selection.id
        );
        if (personalMapSet) return [...mapSets, personalMapSet];
      }
      return [...mapSets];
    },
    []
  );
};

export const useMapSets = (currentTheme: Theme, themes: Theme[]) => {
  const { mapSetSelections } = useMapSetSelection(currentTheme);
  const { data: personalMapSets } = usePersonalMapSets();
  return mapSetSelections.reduce(
    (mapSets: LayerSet[], selection: Selection) => {
      if (selection.type === "ms") {
        const [mapSet] = themes
          .map((theme) =>
            theme.layerSets.find((set) => set.id === selection.id)
          )
          .filter((set) => set !== undefined);
        if (mapSet) return [...mapSets, mapSet];
      }
      if (selection.type === "pl") {
        const personalMapSet = personalMapSets?.find(
          (set) => set.id === selection.id
        );

        if (personalMapSet) return [...mapSets, personalMapSet];
      }
      return [...mapSets];
    },
    []
  );
};

export const useLayers = (currentTheme: Theme, themes: Theme[]) => {
  const mapSets = useMapSets(currentTheme, themes);
  const { data: activeScenario } = useActiveScenario(currentTheme);
  const { mapSetSelections } = useMapSetSelection(currentTheme);

  return mapSets.flatMap((set) => {
    const mapSetSelection = mapSetSelections.find(
      (selection) => selection.id === set.id
    );
    if (!activeScenario || !mapSetSelection) return [];
    return filterLayers(
      set.layers,
      mapSetSelection,
      activeScenario.scenario,
      set.attribution
    );
  });
};

export function filterLayers(
  layers: Layer[],
  mapSetSelection: Selection,
  activeScenario: Scenario,
  attribution: string
) {
  return layers.reduce((layers: LayerWithAttribution[], layer: Layer) => {
    if (mapSetSelection.visible === 0) return [...layers];
    if (mapSetSelection.type === "pl")
      return [
        ...layers,
        {
          ...layer,
          attribution: attribution,
          wms: { ...layer.wms, opacity: mapSetSelection.opacity },
        },
      ];
    if (
      layer.scenario?.year === activeScenario.year ||
      (layer.scenario?.present && activeScenario?.present)
    )
      return [
        ...layers,
        {
          ...layer,
          attribution: attribution,
          wms: { ...layer.wms, opacity: mapSetSelection.opacity },
        },
      ];
    return [...layers];
  }, []);
}

export const useMapSetSelection = (currentTheme: Theme) => {
  const [params, setParams] = useSearchParams();
  const mapSetsParams = params.get("ms") ? params.getAll("ms") : null;

  const defaultThemes = formatLayerSetsToSelection(
    currentTheme.layerSets.filter((set) => set.defaultSelection)
  );

  const mapSetSelections = mapSetsParams
    ? mapSetsParams
        .filter((value) => value !== " ")
        .map((mapSet) => parseMapSetUrlParams(mapSet))
    : defaultThemes;

  function generateNewMapSetParams(mapSetSelection: Selection[]) {
    const newParams = new URLSearchParams(params);
    newParams.delete("ms");
    if (mapSetSelection.length !== 0) {
      mapSetSelection.forEach((selection) =>
        newParams.append(
          "ms",
          `${selection.id}|${selection.opacity}|${selection.type}|${selection.added}|${selection.visible}`
        )
      );
    } else {
      newParams.set("ms", " ");
    }
    return newParams;
  }

  function updateMapSetSelection(mapSetSelection: Selection[]) {
    setParams(generateNewMapSetParams(mapSetSelection));
  }

  return {
    mapSetSelections,
    mapSetsParams,
    defaultThemes,
    generateNewMapSetParams,
    updateMapSetSelection,
  };
};

export const usePersonalMapSets = () => {
  async function fetch(): Promise<LayerSet[]> {
    const personalMapSetsString = localStorage.getItem("mapSets");
    const personalMapSets: LayerSet[] = personalMapSetsString
      ? JSON.parse(personalMapSetsString)
      : [];
    return Promise.resolve(personalMapSets);
  }
  return useQuery(["personalMapSets"], fetch);
};

export const EMPTY_LAYER: MapSetFormData = {
  id: 0,
  name: "Eigen WMS kaart: Digitaal Hoogtemodel Vlaanderen",
  url: "https://geo.api.vlaanderen.be/DHMV/wms?",
  layerName: "DHMVII_DTM_1m",
  legendUrl:
    "https://geo.api.vlaanderen.be/DHMV/wms?request=GetLegendGraphic&version=1.3.0&format=image%2Fpng&layer=DHMVI_DTM_5m",
};

export const useMapSetValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    name: Yup.string().required(
      t("validation.requiredField", { field: t("label.mapName") })
    ),
    url: Yup.string().required(
      t("validation.requiredField", { field: t("label.wmsUrl") })
    ),
    layerName: Yup.string().required(
      t("validation.requiredField", { field: t("label.wmsLayerName") })
    ),
  });
};

export const useLayerInfo = (point: LatLngLiteral, layer?: Layer) => {
  const map = useMap();
  const queryClient = useQueryClient();

  async function fetch(signal?: AbortSignal) {
    const size = map.getSize();
    const containerPoint = map.latLngToContainerPoint(point);

    if (!layer) return null;
    queryClient.cancelQueries(["layerInfo", layer.id], { exact: false });
    const params = {
      layers: layer.wms.layers,
      query_layers: layer.wms.layers,
      version: "1.1.1",
      request: "GetFeatureInfo",
      service: "WMS",
      srs: "EPSG:4326",
      transparent: true,
      styles: "",
      format: "image/png",
      x: round(containerPoint.x),
      y: round(containerPoint.y),
      bbox: map.getBounds().toBBoxString(),
      width: size.x,
      height: size.y,
      info_format: "application/json",
    };
    const response = await axios.get<FeatureCollection | undefined | string>(
      layer.wms.url,
      {
        params,
        signal,
      }
    );

    if (response.data === undefined || typeof response.data === "string")
      return null;

    const featureMap = deserializeToFeatureMap(
      serializeLayerInfo(response.data)
    );

    if (featureMap.size === 0) return null;
    const grayIndex = getFeatureInfoMapVal(featureMap, "GRAY_INDEX");
    if (grayIndex !== undefined && grayIndex !== null) return grayIndex;
    const value = getFeatureInfoMapVal(featureMap, "waarde");
    if (value !== undefined && value !== null) return value;
    const secondValue = getFeatureInfoMapVal(featureMap, "value");
    if (secondValue !== undefined && secondValue !== null) return secondValue;
    return null;
  }
  return useQuery(
    ["layerInfo", layer?.id, point.lat, point.lng],
    ({ signal }) => fetch(signal),
    {
      enabled: layer !== undefined,
    }
  );
};

function deserializeToFeatureMap(
  data: [string, string | number][]
): Map<string, string | number> {
  const keyMap = new Map<string, string | number>();
  for (const [key, value] of data) {
    keyMap.set(key, value);
  }
  return keyMap;
}

function getFeatureInfoMapVal(map: Map<string, string | number>, key: string) {
  const value = map.get(key);
  return value !== undefined ? value : null;
}

function serializeLayerInfo(data: FeatureCollection | undefined | string) {
  if (typeof data === "string" || !data || !data.features) return [];

  return data.features.reduce(
    (keyValuePairs: [string, string | number][], feature) => {
      if (feature.properties) {
        return [...keyValuePairs, ...Object.entries(feature.properties)];
      }
      return [...keyValuePairs];
    },
    []
  );
}

// We only use this for the reflect call, so we can always show something
export function useActiveLayerWithFallback(
  mapSet: LayerSet,
  activeScenario?: ThemeScenario
) {
  const selectedLayer = mapSet.layers.find(
    (layer) =>
      layer.scenario?.year === activeScenario?.scenario.year ||
      (layer.scenario?.present && activeScenario?.scenario.present)
  );
  const defaultLayer = mapSet.layers.find(
    (layer) => layer.scenario?.year === 2050
  );
  const [fallbackLayer] = mapSet.layers;

  return {
    layer: selectedLayer || defaultLayer || fallbackLayer,
    isFallback: !selectedLayer,
  };
}

export function useLayerSetsWithoutGroups(layerSets: LayerSet[]) {
  return layerSets.filter((layerSet) => {
    return !layerSet.layers.some((layer) => layer.type === "LAYERGROUP");
  });
}
