import { Suspense, useState } from "react";
import {
  Checkbox,
  IconButton,
  Paper,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear, RemoveCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Draggable } from "react-beautiful-dnd";

import { useMapSetWithoutFilteredLayers } from "../../hooks/mapSetHooks";
import { Selection, Theme } from "../../types";
import { ReactComponent as Adjust } from "../../assets/icons/adjust.svg";
import { MapSetOpacitySlider } from "./MapSetOpacitySlider";
import { useActiveScenario } from "../../hooks/scenarioHooks";
import MapSetPopover from "./MapSetPopover";

interface Props {
  mapSetSelection: Selection;
  mapSetSelections: Selection[];
  themes: Theme[];
  index: number;
  currentTheme: Theme;
  updateMapSetSelection: (mapSetSelection: Selection[]) => void;
  isPersonalMapSet: boolean;
}

export function MapSetHorizontalCard(props: Props) {
  const {
    mapSetSelection,
    mapSetSelections,
    currentTheme,
    updateMapSetSelection,
    themes,
    isPersonalMapSet,
    index,
  } = props;
  const { t } = useTranslation();
  const { data: activeScenario } = useActiveScenario(currentTheme);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // const mapSet = useMapSet(themes, mapSetSelection, activeScenario);
  const mapSet = useMapSetWithoutFilteredLayers(themes, mapSetSelection);

  const isPresent = activeScenario?.scenario.climateScenario === "Huidig";

  const hasActiveLayer = !isPersonalMapSet
    ? mapSet?.layers.find(
        (layer) =>
          layer?.scenario?.year === activeScenario?.scenario.year ||
          (layer.scenario?.present && activeScenario?.scenario.present)
      ) !== undefined
    : true;

  const [toggleOpacity, setToggleOpacity] = useState<boolean>(false);

  const isVisible = mapSetSelection.visible === 1;

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  function onDeleteMapSet() {
    updateMapSetSelection(
      mapSetSelections.filter(
        (selection) => selection.id !== mapSetSelection.id
      )
    );
  }

  function onChangeVisibility() {
    updateMapSetSelection(
      mapSetSelections.map((selection) => {
        if (selection.id === mapSetSelection.id) {
          return {
            ...selection,
            visible: selection.visible === 0 ? 1 : 0,
          };
        }
        return selection;
      })
    );
  }

  function onToggleOpacity() {
    setToggleOpacity((prev) => !prev);
  }

  function onChangeOpacity(value: number | number[]) {
    if (Array.isArray(value)) return;
    const updatedMapSelection: Selection[] = mapSetSelections.map((selection) =>
      selection.id === mapSetSelection.id
        ? { ...selection, opacity: value / 100 }
        : selection
    );
    updateMapSetSelection(updatedMapSelection);
  }
  return (
    <>
      {mapSet && (
        <Draggable draggableId={`${index}`} index={index} key={index}>
          {(provided, snapshot) => (
            <>
              <StyledCard
                ref={provided.innerRef}
                {...provided.draggableProps}
                elevation={0}
              >
                <div
                  className="checkbox-container"
                  {...provided.dragHandleProps}
                >
                  <Checkbox
                    className="checkbox-position"
                    onChange={onChangeVisibility}
                    checked={isVisible}
                    value={mapSet.id}
                  />
                </div>
                <div className="content-container">
                  <div {...provided.dragHandleProps}>
                    <div className="content-header">
                      <div
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        className="content-header-title"
                      >
                        <Typography
                          variant="h6"
                          color={!hasActiveLayer ? "error" : ""}
                        >
                          {mapSet.name}
                        </Typography>
                        {!hasActiveLayer && (
                          <Tooltip
                            title={
                              isPresent
                                ? t<string>(
                                    "label.weCouldntFindALayerForThisScenarioPresent",
                                    {
                                      scenarioName:
                                        activeScenario?.scenario
                                          .climateScenario,
                                    }
                                  )
                                : t<string>(
                                    "label.weCouldntFindALayerForThisScenario",
                                    {
                                      scenarioName:
                                        activeScenario?.scenario
                                          .climateScenario,
                                      scenarioYear:
                                        activeScenario?.scenario.year,
                                    }
                                  )
                            }
                          >
                            <RemoveCircleOutline
                              color="error"
                              fontSize="small"
                            />
                          </Tooltip>
                        )}
                        {mapSetSelection.type !== "pl" && (
                          <Suspense fallback={<></>}>
                            <MapSetPopover
                              handlePopoverClose={handlePopoverClose}
                              anchorEl={anchorEl}
                              mapSet={mapSet}
                            />
                          </Suspense>
                        )}
                      </div>
                      <IconButton onClick={onDeleteMapSet} size="small">
                        <Clear fontSize="small" />
                      </IconButton>
                    </div>

                    <div className="action-buttons">
                      <IconButton
                        onClick={onToggleOpacity}
                        className="align-right"
                      >
                        <Adjust className={toggleOpacity ? "active" : ""} />
                      </IconButton>
                    </div>
                  </div>
                  {toggleOpacity && (
                    <MapSetOpacitySlider
                      opacity={mapSetSelection.opacity}
                      onChangeOpacity={onChangeOpacity}
                    />
                  )}
                </div>
              </StyledCard>
            </>
          )}
        </Draggable>
      )}
    </>
  );
}

const StyledCard = styled(Paper)`
  display: flex;

  .MuiCheckbox-root {
    padding: 0;
  }
  .checkbox-container {
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-top: 3px;
  }
  .content-container {
    width: 100%;
  }
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
    width: 100%;
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .align-right {
    margin-left: auto;
  }

  .active {
    fill: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiLink-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .content-header-title {
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
  }
`;
