import { ChangeEvent, useState } from "react";
import {
  IconButton,
  Typography,
  styled,
  FormControlLabel,
  Checkbox,
  Dialog,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { LayerSet, MapSetType, Selection } from "../../types";
import { MapSetEdit } from "./MapSetEdit";
import { MapSetOpacitySlider } from "./MapSetOpacitySlider";
import { useQueryClient } from "react-query";

interface Props {
  mapSet: LayerSet;
  mapSetSelections: Selection[];
  personalMapSets: LayerSet[];
  onAddMapSet: (mapSet: LayerSet, mapSetType: MapSetType) => void;
  onRemoveMapSet: (mapSetId: number) => void;
  onChangeMapSetOpacity: (mapSetId: number, opacity: number) => void;
}

export function MapSetPersonalItem(props: Props) {
  const {
    mapSet,
    mapSetSelections,
    onAddMapSet,
    onRemoveMapSet,
    onChangeMapSetOpacity,
    personalMapSets,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const mapSetSelection = mapSetSelections.find(
    (mapSetSelection) => mapSetSelection.id === mapSet.id
  );

  const isActive = mapSetSelection !== undefined;

  function onChange(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      onAddMapSet(mapSet, "pl");
    } else {
      onRemoveMapSet(mapSet.id);
    }
  }

  function onChangeOpacity(value: number | number[]) {
    if (Array.isArray(value)) return;
    return onChangeMapSetOpacity(mapSet.id, value);
  }

  function resetScrollIntoView() {
    localStorage.setItem(
      "mapSets",
      JSON.stringify([
        ...personalMapSets.map((mapSet) => ({ ...mapSet, scrollTo: false })),
      ])
    );
    queryClient.invalidateQueries("personalMapSets");
  }

  return (
    <>
      <Styles
        ref={(ref) => {
          if (!ref) return;
          if (!mapSet.scrollTo) return;
          ref.scrollIntoView({ behavior: "smooth" });
          resetScrollIntoView();
        }}
      >
        <FormControlLabel
          label={mapSet.name}
          control={
            <Checkbox
              onChange={onChange}
              checked={isActive}
              value={mapSet.id}
            />
          }
        />
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <Edit fontSize="small" />
        </IconButton>
        <StyledDialog open={isOpen} onClose={() => setIsOpen(false)}>
          <Typography variant="h2" component="h2">
            {t("label.addNewLayer")}
          </Typography>
          <MapSetEdit setIsOpen={setIsOpen} mapSetId={mapSet.id} />
        </StyledDialog>
      </Styles>
      {isActive && (
        <MapSetOpacitySlider
          sx={{ maxWidth: "300px", width: "100%" }}
          onChangeOpacity={onChangeOpacity}
          opacity={mapSetSelection.opacity}
        />
      )}
    </>
  );
}

const Styles = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(2, 2)};
  }
`;
