import { Typography } from "@mui/material";
import { LegendDefinition } from "../../../hooks/legendHooks";
import { LegendRuleItem } from "./LegendRuleItem";

interface Props {
  definition: LegendDefinition;
  shouldDisplayTitle: boolean;
}

export function LegendItem(props: Props) {
  const { definition, shouldDisplayTitle } = props;
  return (
    <>
      {shouldDisplayTitle && (
        <Typography gutterBottom>{definition.title}</Typography>
      )}
      {definition.rules.map((rule, i) => (
        <LegendRuleItem
          rule={rule}
          key={`rule-${definition.layerName}-${rule.name}-${i}`}
        />
      ))}
    </>
  );
}
