import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { useSorting } from "../../hooks/sortHooks";
import { SortingList } from "../../types";

interface Props {
  sortPropertyName: string;
  sortingList: SortingList[];
  isLoading: boolean;
  onSort: (sortingList: SortingList[]) => void;
}

export function DataTableSortAction(props: Props) {
  const { sortPropertyName, sortingList, isLoading, onSort } = props;
  const { property, onSortColumn } = useSorting(sortingList, sortPropertyName);

  function onClickSort() {
    const updatedSortingList = onSortColumn();
    return onSort(updatedSortingList);
  }

  return (
    <StyledIconButton disabled={isLoading} onClick={onClickSort} size="small">
      {!property && <ArrowDropUp />}
      {property?.direction === "asc" && <ArrowDropUp color="success" />}
      {property?.direction === "desc" && <ArrowDropDown color="success" />}
    </StyledIconButton>
  );
}

const StyledIconButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  .MuiSvgIcon-root {
    font-size: 25px;
  }
`;
