import { AppBar, Link, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ReactComponent as FlemishLionLogo } from "../../assets/icons/Vlaanderen_leeuwtje-01.svg";

export function VMMHeader() {
  const { t } = useTranslation();
  return (
    <StyledAppBar position="relative">
      <FlemishLionLogo />
      <Link
        className="title link"
        href="https://www.vlaanderen.be/nl"
        target="_blank"
      >
        {t("label.flanderswebsite")}
      </Link>
      <span className="slash "></span>
      <Link
        className="host link"
        href="https://klimaat.vmm.be/"
        target="_blank"
      >
        {t("label.climatePortalFlanders")}
      </Link>
    </StyledAppBar>
  );
}

const StyledAppBar = styled(AppBar)`
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .title {
    margin-right: 20px;
    font-weight: 600;
    font-size: 18px;
  }

  .link {
    color: #333332;
    line-height: 1;
    text-decoration: none;
    :hover {
      text-decoration: underline;
      color: #0055cc;
    }
  }

  .host {
    color: #333332;
    margin-left: 10px;
    font-size: 14px;
    text-transform: upperc
    text-decoration: none;
  }



  svg {
    width: auto;
    height: 43px;
    margin-right: 8px;
    margin-left: 10px;
    fill: #333332;
  }

  .slash {
    content: "";
    min-height: 24px;
    min-width: 1px;
    background: #cbd2da;
    transform: rotate(-16deg);
    -ms-transform: rotate(-18deg);
  }
`;
