import { ErrorBoundary } from "react-error-boundary";
import { LayerSet } from "../../../types";
import { LegendLoad } from "./LegendLoad";
import { LegendItemErrorImageFallBack } from "../../error/LegendItemErrorImageFallBack";

interface Props {
  mapSet: LayerSet;
}

export function LegendListItem(props: Props) {
  const { mapSet } = props;
  const [layer] = mapSet.layers;

  return (
    <>
      {(layer.legend?.url || layer.legend?.layers) && (
        <LegendItemErrorImageFallBack layer={layer} />
      )}
      {!layer.legend?.url && !layer.legend?.layers && (
        <ErrorBoundary
          fallback={<LegendItemErrorImageFallBack layer={layer} />}
        >
          <LegendLoad
            id={layer.id}
            url={layer.wms.url}
            wmsParams={layer.wms}
            layer={layer}
          />
        </ErrorBoundary>
      )}
    </>
  );
}
