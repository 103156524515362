import { Suspense } from "react";
import { MapSetsOverviewAll } from "../components/mapSets/MapSetsOverviewAll";

export function MapSetsAllPage() {
  return (
    <Suspense fallback={<></>}>
      <MapSetsOverviewAll />
    </Suspense>
  );
}
