import { FilterAlt, Search } from "@mui/icons-material";
import { MouseEvent, useState } from "react";
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { DataFilter, IndicatorDataFilter } from "../../types";

import { DataFilterMenuList } from "./DataFilterMenuList";
import { isEqual } from "lodash-es";

interface Props {
  filterValues: DataFilter;
  distinctValues: string[];
  filterPropertyName: keyof IndicatorDataFilter;
  onToggleFilter: (
    value: DataFilter,
    property: keyof IndicatorDataFilter
  ) => void;
}

export function DataFilterMenu(props: Props) {
  const { filterValues, distinctValues, filterPropertyName, onToggleFilter } =
    props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  const [currentFilterState, setCurrentFilterState] =
    useState<DataFilter>(filterValues);

  function onClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setSearchValue("");
  }

  function onClose() {
    setAnchorEl(null);
    setCurrentFilterState(filterValues);
  }

  function checkAll(checked: boolean) {
    if (checked) {
      return setCurrentFilterState({ filter: [], selectAll: true });
    }
    return setCurrentFilterState({ filter: [], selectAll: false });
  }

  function onClickApply() {
    setAnchorEl(null);
    onToggleFilter({ ...currentFilterState }, filterPropertyName);
  }

  function handleFilter(value: string) {
    if (currentFilterState.filter.indexOf(value) > -1) {
      return setCurrentFilterState({
        selectAll: false,
        filter: [
          ...currentFilterState.filter.filter(
            (filterValue) => filterValue !== value
          ),
        ],
      });
    }

    if (currentFilterState.selectAll) {
      return setCurrentFilterState({
        selectAll: false,
        filter: [
          ...distinctValues.filter((filterValue) => filterValue !== value),
        ],
      });
    }

    const updatedFilterValues = [...currentFilterState.filter, value];
    return setCurrentFilterState({
      selectAll: isEqual(updatedFilterValues, distinctValues),
      filter: [...updatedFilterValues],
    });
  }

  return (
    <>
      <StyledIconButton onClick={onClick} size="small">
        <FilterAlt
          fontSize="small"
          color={filterValues.filter.length !== 0 ? "success" : "inherit"}
        />
      </StyledIconButton>
      <StyledPopover anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
        <TextField
          variant="outlined"
          value={searchValue}
          placeholder={t("label.search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <ListItem>
          <Checkbox
            indeterminate={
              !currentFilterState.selectAll &&
              currentFilterState.filter.length !== 0
            }
            checked={
              currentFilterState.selectAll ||
              isEqual(currentFilterState, distinctValues)
            }
            onChange={(e, checked) => checkAll(checked)}
          />
          <ListItemText
            disableTypography
            primary={
              <Typography sx={{ fontWeight: 600 }}>
                {t("label.selectAll")}
              </Typography>
            }
          />
        </ListItem>
        <DataFilterMenuList
          distinctValues={distinctValues.filter((val) =>
            val.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
          )}
          selectAll={currentFilterState.selectAll}
          filterValues={currentFilterState.filter}
          handleFilter={handleFilter}
        />
        <Stack flexDirection="row" justifyContent="space-between">
          <Button onClick={onClose}>{t("label.cancel")}</Button>
          <Button
            onClick={onClickApply}
            disabled={
              currentFilterState.filter.length === 0 &&
              !currentFilterState.selectAll
            }
          >
            {t("label.apply")}
          </Button>
        </Stack>
      </StyledPopover>
    </>
  );
}

const StyledIconButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;

const StyledPopover = styled(Popover)`
  max-height: 550px;

  .MuiTextField-root {
    width: 100%;
  }
`;
