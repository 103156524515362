import axios from "axios";
import { useQuery } from "react-query";

export function useConfigParameters() {
  async function fetch() {
    const response = await axios.get<{
      mapCatalogueUrl: string;
    }>(`/api/configparameters`);
    return response.data.mapCatalogueUrl;
  }

  return useQuery(["configParameters"], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
