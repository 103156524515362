import { useMatch, useParams, useSearchParams } from "react-router-dom";

export const useDrawerUrlParams = (panelKey?: string) => {
  const [params, setParams] = useSearchParams();
  const drawerTypeState = params.get(panelKey || "");

  function updateDrawerState(panelKey: string) {
    const newParams = new URLSearchParams(params);
    if (drawerTypeState) newParams.delete(panelKey);
    if (!drawerTypeState) newParams.set(panelKey, "0");

    setParams(newParams);
  }

  return { drawerTypeState, updateDrawerState };
};

export const useDrawerKey = () => {
  const { themeId, locationId } = useParams();
  const isEffectsAndImpactsPage = useMatch(`${themeId}`);
  const isGraphsPage = useMatch(`${themeId}/location/${locationId}/graphs`);
  const isLayersPage = useMatch(`${themeId}/location/${locationId}/layers/*`);
  const isInfoPage = useMatch(`${themeId}/location/${locationId}/info/*`);

  if (isEffectsAndImpactsPage) return "homeDrawer";
  if (isGraphsPage) return "graphDrawer";
  if (isLayersPage) return "layerDrawer";
  if (isInfoPage) return "infoDrawer";
};
