import { LegendRule } from "../../../hooks/legendHooks";

import { LegendSymbolizerItem } from "./LegendSymbolizerItem";

interface Props {
  rule: LegendRule;
}

export function LegendRuleItem(props: Props) {
  const { rule } = props;

  return (
    <>
      {rule.symbolizers.map((symbolizer, i) => (
        <LegendSymbolizerItem
          symbolizer={symbolizer}
          key={`rule-item-${i}`}
          title={rule.title || rule.name}
        />
      ))}
    </>
  );
}
