import { Fragment } from "react";
import { Link, List, ListItem, styled, Typography } from "@mui/material";
import { LatLngLiteral } from "leaflet";
import {
  useLocationFlanders,
  useLocationLatLng,
  useUrlLocationType,
} from "../../../hooks/locationHooks";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  point: LatLngLiteral;
}

export function SearchList(props: Props) {
  const { point } = props;
  const { t } = useTranslation();
  const { data: locations } = useLocationLatLng(point);
  const { data: flanders } = useLocationFlanders();

  const { pathname } = useLocation();
  const { themeId } = useParams();
  const { getUpdatedLocationParams } = useUrlLocationType();

  function generateNavUrl(locationType: string, locationId: number) {
    const correctLocationType = getCorrectLocationTypeKey(locationType);
    const params = getUpdatedLocationParams(correctLocationType);
    if (!pathname.includes("location"))
      return `/${themeId}/location/${locationId}/graphs?${params.toString()}`;
    const [, , , , ...restPath] = pathname.split("/");
    return `${[`/${themeId}/location/${locationId}`, ...restPath].join(
      "/"
    )}?${params.toString()}`;
  }

  function getCorrectLocationTypeKey(locationType: string) {
    if (
      locationType === "lokaalwaterlichaam" ||
      locationType === "vlaamswaterlichaam"
    )
      return "waterlichaam";
    return locationType;
  }

  return (
    <StyledList>
      {locations &&
        locations.map((location) => (
          <Fragment key={location.id}>
            <Typography variant="h3">{location.type.name}</Typography>
            <ListItem disableGutters key={location.id}>
              <Link
                component={NavLink}
                to={generateNavUrl(location.type.key, location.id)}
              >
                {location.name}
              </Link>
            </ListItem>
          </Fragment>
        ))}
      {flanders && (
        <>
          <Typography variant="h3"> {t("label.flanders")}</Typography>
          <ListItem disableGutters>
            <Link
              component={NavLink}
              to={generateNavUrl(flanders.type.key, flanders.id)}
            >
              {t("label.orViewFlanders")}
            </Link>
          </ListItem>
        </>
      )}
    </StyledList>
  );
}

const StyledList = styled(List)`
  &.MuiTypography-h3 {
    margin: 0;
  }
  &.MuiList-root {
  }
`;
