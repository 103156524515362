import { Layers } from "@mui/icons-material";
import { Box, Card, CardContent, Link, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useConfigParameters } from "../../hooks/configHooks";
import { useActiveLayerWithFallback } from "../../hooks/mapSetHooks";
import { LayerSet, ThemeScenario } from "../../types";
import { StyledMarkdown } from "../layout/StyledMarkdown";
import { MapSetCardMediaLoader } from "./MapSetCardMediaLoader";

interface Props {
  mapSet: LayerSet;
  scenario?: ThemeScenario;
}

export function MapSetVerticalCard(props: Props) {
  const { mapSet, scenario } = props;
  const { t } = useTranslation();
  const { layer, isFallback } = useActiveLayerWithFallback(mapSet, scenario);
  const { data: url } = useConfigParameters();
  function generatePreviewUrl() {
    return `${layer.wms.url}/reflect?layers=${layer.wms.layers}&height=200`;
  }

  return (
    <StyledCard elevation={0} className="iscard">
      <MapSetCardMediaLoader
        defaultIcon={<Layers />}
        height="200"
        image={generatePreviewUrl()}
      />
      <CardContent className="description-container">
        {isFallback && (
          <Box className="info">
            {t("label.shownPreview", {
              year: layer.scenario?.present
                ? layer.scenario?.climateScenario
                : layer.scenario?.year,
            })}
          </Box>
        )}
        <StyledMarkdown content={mapSet.description} />
        <Link
          target="_blank"
          rel="refferer noopener"
          href={`${url}?search=${mapSet.name}`}
        >
          {t("label.moreInfo")}
        </Link>
      </CardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 500px;
  flex-grow: 1;
  background-color: inherit;

  .MuiCardMedia-root {
    background-color: ${(props) => props.theme.palette.grey[200]};
    max-height: ${({ theme }) => theme.spacing(25)};
    width: 100%;
    flex-grow: 1;
    padding: ${({ theme }) => theme.spacing(1)};
  }

  .info {
    color: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    top: ${({ theme }) => theme.spacing(1)};
  }
`;
