import { LatLngBoundsLiteral } from "leaflet";
import { createContext, ReactNode, useState } from "react";

interface BoundsContextTypes {
  locBounds: LatLngBoundsLiteral | null;
  setLocBounds: (bounds: LatLngBoundsLiteral | null) => void;
}

export const BoundsContext = createContext<BoundsContextTypes>({
  locBounds: null,
  setLocBounds: () => {},
});

interface Props {
  children: ReactNode;
}
export function BoundsContextProvider(props: Props) {
  const { children } = props;
  const [locBounds, setLocBounds] = useState<LatLngBoundsLiteral | null>(null);
  return (
    <BoundsContext.Provider value={{ locBounds, setLocBounds }}>
      {children}
    </BoundsContext.Provider>
  );
}
