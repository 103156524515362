import { ReactElement, useEffect, useState } from "react";
import { Button, ButtonProps, styled, SvgIconProps } from "@mui/material";

import { Loop } from "@mui/icons-material";

import { useDownloadData } from "../../hooks/indicatorDataHooks";

import { DownloadState, IndicatorDataFilter } from "../../types";

import { DownloadExcel } from "./DownloadExcel";
import { DownloadProgress } from "./DownloadProgress";

interface Props extends ButtonProps {
  label: string;
  icon: ReactElement<SvgIconProps>;
  filterData: IndicatorDataFilter;
}

export function DownloadButton(props: Props) {
  const { label, icon, filterData, ...buttonProps } = props;
  const [downloadState, setDownloadState] = useState<DownloadState>("OFF");
  const { data: progressId, isError } = useDownloadData(filterData, {
    enabled: downloadState === "PROCESSING",
  });
  function toggleDownloadState(downloadValue: DownloadState) {
    setDownloadState(downloadValue);
  }

  useEffect(() => {
    if (isError && downloadState === "PROCESSING") {
      setDownloadState("OFF");
    }
  }, [isError, downloadState]);

  return (
    <Styles>
      <Button
        variant="textSmall"
        {...buttonProps}
        onClick={() => toggleDownloadState("PROCESSING")}
        disabled={downloadState !== "OFF"}
        startIcon={downloadState !== "OFF" ? <Loop className="rotate" /> : icon}
      >
        {label}
      </Button>
      {progressId && downloadState === "PROCESSING" && (
        <DownloadProgress
          progressId={progressId}
          toggleDownloadState={toggleDownloadState}
        />
      )}
      {progressId && downloadState === "FINISHED" && (
        <DownloadExcel
          progressId={progressId}
          toggleDownloadState={toggleDownloadState}
        />
      )}
    </Styles>
  );
}

const Styles = styled("div")`
  .rotate {
    animation: rotation 1s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
