import { Link, List, ListItem, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Location } from "../../../types";

interface Props {
  locations: Location[];
  resetLatLng: () => void;
}

export function LocationTypeSearchResultList(props: Props) {
  const { locations, resetLatLng } = props;
  const location = useLocation();
  const { themeId, locationId } = useParams();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const filteredLocation = locations.filter(
    (location) => location.id.toString() !== locationId
  );

  function generatePath(locationId: number) {
    const newSearchParams = new URLSearchParams(params);
    newSearchParams.delete("addressMarker");
    const [, , , , ...restPath] = location.pathname.split("/");
    return `${[`/${themeId}/location/${locationId}`, ...restPath].join(
      "/"
    )}?${newSearchParams.toString()}`;
  }
  function handleNavigate(locationId: number) {
    resetLatLng();
    return navigate(generatePath(locationId));
  }
  return (
    <StyledList>
      {filteredLocation.map((location) => (
        <Fragment key={location.id}>
          <Typography variant="h3">{location.type.name}</Typography>
          <ListItem disableGutters key={location.id}>
            <Link onClick={() => handleNavigate(location.id)}>
              {location.name}
            </Link>
          </ListItem>
        </Fragment>
      ))}
    </StyledList>
  );
}

const StyledList = styled(List)`
  &.MuiTypography-h3 {
    margin: 0;
  }
  &.MuiList-root {
  }
  .MuiLink-root {
    cursor: pointer;
  }
`;
