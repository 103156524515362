import { Suspense } from "react";
import { Skeleton, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDrawerKey, useDrawerUrlParams } from "../hooks/drawerHooks";
import { useSelectedThemeContext } from "../hooks/themeHooks";
import { useMapModeMapLayout } from "../hooks/mapHooks";

import { MapContent } from "../components/map/MapContent";
import { CollapsibleDrawer } from "../components/layout/CollapsibleDrawer";
import { LocationSearchSection } from "../components/location/LocationSearchSection";
import { LocationFlandersLink } from "../components/location/LocationFlandersLink";
import { SearchLocationPopup } from "../components/map/search/SearchLocationPopup";
import { LayerPointControl } from "../components/map/LayerPointControl";
import { JoyrideActivator } from "../components/joyride/JoyrideActivator";

export function MapLayout() {
  const { currentTheme, themes } = useSelectedThemeContext();
  const { t } = useTranslation();
  const drawerKey = useDrawerKey();
  const { drawerTypeState, updateDrawerState } = useDrawerUrlParams(drawerKey);
  const { currentMapMode, updateMapMode } = useMapModeMapLayout();

  function togglePanel() {
    if (drawerKey) updateDrawerState(drawerKey);
  }

  function toggleActiveMapMode() {
    if (currentMapMode === "spi") return updateMapMode("som");
    return updateMapMode("spi");
  }

  return (
    <Styles>
      <CollapsibleDrawer
        className={`panel ${
          drawerTypeState ? "collapsed" : "open unset-overflow-reactdnd"
        }`}
        togglePanel={togglePanel}
        open={!!!drawerTypeState}
      >
        <JoyrideActivator />
        <LocationSearchSection
          data-tour="step-3"
          sectionTitle={t("label.areaInDetail")}
          extraOption={
            <Suspense fallback={<Skeleton width="auto" />}>
              <LocationFlandersLink />
            </Suspense>
          }
        />

        <Outlet context={{ currentTheme: currentTheme, themes: themes }} />
      </CollapsibleDrawer>
      <MapContent>
        {currentMapMode === "som" && <SearchLocationPopup />}
        <LayerPointControl
          position="topright"
          isActive={currentMapMode === "spi"}
          themes={themes}
          currentTheme={currentTheme}
          toggleActiveMapMode={toggleActiveMapMode}
        />
      </MapContent>
    </Styles>
  );
}

const Styles = styled("main")`
  display: flex;
  flex-grow: 1;
  .MuiSkeleton-root {
    flex-grow: 1;
  }
  .overflow-container {
    overflow: auto;
  }

  .panel {
    width: ${({ theme }) => theme.spacing(95)};
    transition: width 320ms ease-in-out;

    &.collapsed {
      width: ${({ theme }) => theme.spacing(0)};
    }
  }
  .unset-overflow-reactdnd {
    & > .MuiPaper-root {
      overflow-y: unset;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    .panel {
      width: ${({ theme }) => theme.spacing(51)};
    }
  }
`;
