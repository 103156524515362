import { useState } from "react";
import { Drawer, DrawerProps, Portal, styled } from "@mui/material";
import { CollapsePanelButton } from "./CollapsePanelButton";

interface Props extends DrawerProps {
  togglePanel: () => void;
  drawerRef?: HTMLDivElement | null;
}

export function CollapsibleDrawer(props: Props) {
  const { children, open, togglePanel, ...drawerProps } = props;
  const [drawerRef, setDrawerRef] = useState<HTMLDivElement | null>(null);

  return (
    <StyledDrawer
      variant="persistent"
      open={open}
      {...drawerProps}
      ref={(ref) => setDrawerRef(ref)}
    >
      {children}
      <Portal container={drawerRef}>
        <CollapsePanelButton togglePanel={togglePanel} open={open} />
      </Portal>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  position: relative;
  & > .MuiPaper-root {
    position: absolute;
    right: 0;
    overflow-x: visible;
    padding: ${({ theme }) => theme.spacing(4, 4)};
  }
`;
