import { useEventListener } from "../../hooks/useEventListener";

interface Props {
  onToggle: () => void;
}

export function FullScreenGuard(props: Props) {
  const { onToggle } = props;

  useEventListener("fullscreenchange", onFullscreenChange, document);

  function onFullscreenChange() {
    onToggle();
  }

  return null;
}
