import { Suspense } from "react";
import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useSelectedThemeContext } from "../hooks/themeHooks";

import { ThemeSelectionList } from "../components/themes/ThemeSelectionList";
import { ErrorBoundary } from "../components/boundary/ErrorBoundry";
import { ListSkeleton } from "../components/skeletons/ListSkeleton";

export function EffectsAndImpactsPage() {
  const { t } = useTranslation();
  const { themes, currentTheme } = useSelectedThemeContext();

  return (
    <Styles data-tour="step-1">
      <Typography variant="h1">{t("label.themes")}</Typography>
      <ErrorBoundary>
        <Suspense fallback={<ListSkeleton />}>
          <ThemeSelectionList
            themes={themes}
            currentTheme={currentTheme}
            navigationPath="/"
            resetURLStateOnNavigate={true}
          />
        </Suspense>
      </ErrorBoundary>
    </Styles>
  );
}

const Styles = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto !important;
  margin-right: -${({ theme }) => theme.spacing(4)};

  .action-button {
    padding: ${({ theme }) => theme.spacing(1, 2, 1, 2)};
    margin: ${({ theme }) => theme.spacing("auto", 1.5, 0, 1.5)};
    text-align: center;
  }
`;
