import { styled, Tab, Tabs, TabsProps } from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useMapSetSelection } from "../../hooks/mapSetHooks";
import { useNavigate as useCustomNavigate } from "../../hooks/routerHooks";
import { useSelectedThemeContext } from "../../hooks/themeHooks";

import { Theme } from "../../types";

import { MapThemeNavigationIconImage } from "./MapThemeNavigationIconImage";

interface Props extends TabsProps {}

export function MapThemeNavigation(props: Props) {
  const { ...tabProps } = props;
  const { themeId, locationId } = useParams();
  const [params] = useSearchParams();
  const location = useLocation();
  const { currentTheme, themes } = useSelectedThemeContext();

  function generateThemePath(themeId: string) {
    const [, , , , ...restPath] = location.pathname.split("/");

    return [`/${themeId}/location/${locationId}`, ...restPath].join("/");
  }

  const navigate = useNavigate();
  const customNavigate = useCustomNavigate();

  const { mapSetsParams } = useMapSetSelection(currentTheme);

  function navigateTo(theme: Theme) {
    const searchParams = new URLSearchParams(params);
    searchParams.delete("ms");

    const url = generateThemePath(theme.id.toString());
    if (mapSetsParams) return navigate(`${url}?${searchParams.toString()}`);
    return customNavigate(url);
  }

  return (
    <StyledTabs
      value={themeId}
      variant="scrollable"
      scrollButtons="auto"
      {...tabProps}
    >
      {themes.map((theme) => (
        <Tab
          value={theme.id.toString()}
          key={theme.id}
          onClick={() => navigateTo(theme)}
          icon={
            <MapThemeNavigationIconImage
              iconUrl={theme.iconUrl}
              key={theme.iconUrl}
            />
          }
          iconPosition="start"
          label={theme.name}
        />
      ))}
    </StyledTabs>
  );
}

const StyledTabs = styled(Tabs)`
  .MuiTabScrollButton-root.Mui-disabled {
    display: none;
  }

  .MuiButtonBase-root.MuiTab-root {
    min-height: ${({ theme }) => theme.spacing(6)};
    font-weight: 600;
  }
  .MuiTabs-scrollButtons {
    background-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.common.white};
  }

  .icon {
    visibility: hidden;
    max-width: ${({ theme }) => theme.spacing(2)};
    max-height: ${({ theme }) => theme.spacing(2)};
    margin-right: ${({ theme }) => theme.spacing(0.5)};

    &.completed {
      visibility: unset;
    }
  }
`;
