import axios from "axios";

import { useQuery } from "react-query";
import { useMemo } from "react";
import { max } from "lodash-es";
import { ExtendedWMSParams, Layer } from "../types";

interface LegendJSON {
  Legend: LegendJSONParent[];
}

interface LegendJSONParent {
  layerName: string;
  title: string;
  rules: LegendJSONRule[];
}

interface LegendJSONRule {
  name: string;
  title: string;
  abstract: string;
  filter: string;
  ElseFilter: string; //bool true|false
  symbolizers: LegendJSONSymbolizer[];
}

/**
 * Reference: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute
 */
interface LegendJSONSymbolizerFill {
  fill: string;
  "fill-opacity": string;
}

/**
 * Reference: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute
 */
interface LegendJSONSymbolizerStroke {
  stroke: string;
  "stroke-width": string;
  "stroke-opacity": string;
  "stroke-linecap": "butt" | "round" | "square"; //fixed strings? butt|...
  "stroke-linejoin": "round" | "bevel" | "miter" | "inherit" | undefined; //fixed strings? miter|...
}

interface LegendJSONSymbolizerFillStroke
  extends LegendJSONSymbolizerFill,
    LegendJSONSymbolizerStroke {}

type LegendJSONSymbolizer =
  | LegendJSONSymbolizerPolygon
  | LegendJSONSymbolizerRaster
  | LegendJSONSymbolizerRasterRamp
  | LegendJSONSymbolizerRasterValues
  | LegendJSONSymbolizerLine
  | LegendJSONSymbolizerPoint;

interface LegendJSONSymbolizerPolygon {
  Polygon: LegendJSONSymbolizerFillStroke;
}

interface LegendJSONSymbolizerRaster {
  Raster: { opacity: string };
}

interface LegendJSONSymbolizerRasterRamp {
  Raster: {
    opacity: string;
    colormap: {
      entries: { color: string; label: string; quantity: string }[];
      type: "ramp";
    };
  };
}
interface LegendJSONSymbolizerRasterValues {
  Raster: {
    opacity: string;
    colormap: {
      entries: {
        color: string;
        label: string;
        quantity: string;
        opacity: string;
      }[];
      type: "values";
    };
  };
}

interface LegendJSONSymbolizerLine {
  Line: LegendJSONSymbolizerStroke;
}

interface LegendJSONSymbolizerPoint {
  Point: {
    title: string;
    abstract: string;
    url: string;
    size: string;
    opacity: string;
    rotation: string;
    graphics: LegendJSONPointGraphics[];
  };
}

interface LegendJSONPointGraphics extends LegendJSONSymbolizerFill {
  mark:
    | "square"
    | "circle"
    | "triangle"
    | "star"
    | "cross"
    | "x"
    | "shape://vertline"
    | "shape://horline"
    | "shape://slash"
    | "shape://backslash"
    | "shape://dot"
    | "shape://plus"
    | "shape://times"
    | "shape://oarrow"
    | "shape://carrow";
}

/*************************************************************************************************************************************************
 *************************************************************************************************************************************************
 *************************************************************  Parsed Legend types **************************************************************
 *************************************************************************************************************************************************
 *************************************************************************************************************************************************/

export interface LegendDefinition {
  layerName: string;
  title: string;
  rules: LegendRule[];
}

export interface LegendRule {
  name: string;
  title: string;
  abstract: string;
  filter: string;
  ElseFilter: boolean; //bool true|false
  symbolizers: LegendSymbolizer[];
}

/**
 * Reference: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute
 */
export interface LegendSymbolizerFill {
  fill: string;
  fillOpacity: string;
}

/**
 * Reference: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute
 */
export interface LegendSymbolizerStroke {
  stroke: string;
  strokeWidth: string;
  strokeOpacity: string;
  strokeLinecap: "butt" | "round" | "square"; //fixed strings? butt|...
  strokeLinejoin: "round" | "bevel" | "miter" | "inherit" | undefined; //fixed strings? miter|...
}

export interface LegendSymbolizerFillStroke
  extends LegendSymbolizerFill,
    LegendSymbolizerStroke {}

export type LegendSymbolizer =
  | LegendSymbolizerPolygon
  | LegendSymbolizerRaster
  | LegendSymbolizerRasterRamp
  | LegendSymbolizerRasterValues
  | LegendSymbolizerLine
  | LegendSymbolizerPoint;

export interface LegendSymbolizerPolygon {
  Polygon: LegendSymbolizerFillStroke;
}

export interface LegendSymbolizerRaster {
  Raster: { opacity: number };
}

export interface LegendSymbolizerRasterRamp {
  Raster: { opacity: number } & LegendSymbolizerRasterColorMapRamp;
}

export interface LegendSymbolizerRasterColorMapRamp {
  colormap: LegendColorMapRamp;
  type: "ramp";
}

export interface LegendColorMapRampEntry {
  color: string;
  label: string;
  quantity: number;
}

export interface LegendColorMapRamp {
  entries: LegendColorMapRampEntry[];
}
export interface LegendSymbolizerRasterValues {
  Raster: { opacity: number } & LegendSymbolizerRasterColorMapValues;
}
export interface LegendColorMapValues {
  entries: LegendColorMapValuesEntry[];
}
export interface LegendSymbolizerRasterColorMapValues {
  colormap: LegendColorMapValues;
  type: "values";
}

export interface LegendColorMapValuesEntry {
  color: string;
  label: string;
  quantity: number;
  opacity: number;
}

export interface LegendSymbolizerLine {
  Line: LegendSymbolizerStroke;
}

export interface LegendSymbolizerPoint {
  Point: {
    title: string;
    abstract: string;
    url: string;
    size: number;
    opacity: number;
    rotation: number;
    graphics: LegendPointGraphics[];
  };
}

export interface LegendPointGraphics
  extends Omit<LegendJSONPointGraphics, "fill-opacity"> {
  fillOpacity: string;
}

function deserializeLegend(data: LegendJSON): LegendDefinition[] {
  return data.Legend.map(deserializeLegendAttribute);
}

function deserializeLegendAttribute(data: LegendJSONParent): LegendDefinition {
  return { ...data, rules: data.rules.map(deserializeRule) };
}

function deserializeRule(data: LegendJSONRule): LegendRule {
  return {
    ...data,
    ElseFilter: data.ElseFilter === "true",
    symbolizers: data.symbolizers.map(deserializeLegendSymbolizer),
  };
}

function deserializeLegendSymbolizer(
  data: LegendJSONSymbolizer
): LegendSymbolizer {
  if (isLegendJSONSymbolizerRaster(data)) {
    if (isLegendJSONSymbolizerRasterRamp(data)) {
      return {
        ...data,
        Raster: { ...data.Raster, opacity: parseFloat(data.Raster.opacity) },
      };
    }
    if (isLegendJSONSymbolizerRasterValues(data)) {
      return {
        ...data,
        Raster: {
          ...data.Raster,
          opacity: parseFloat(data.Raster.opacity),
          colormap: {
            ...data.Raster.colormap,
            entries: data.Raster.colormap.entries.map((entry) => ({
              ...entry,
              opacity: entry.opacity ? parseFloat(entry.opacity) : "1",
              quantity: parseFloat(entry.quantity),
            })),
          },
        },
      };
    }
    return {
      ...data,
      Raster: { opacity: parseFloat(data.Raster.opacity) },
    };
  }
  if (isLegendJSONSymbolizerPoint(data)) {
    const { graphics, ...restPoint } = data.Point;
    return {
      ...data,
      Point: {
        ...restPoint,
        graphics: graphics.map((graphics) => {
          const { "fill-opacity": fillOpacity, ...restGraphics } = graphics;
          return { fillOpacity, ...restGraphics };
        }),
        opacity: parseFloat(data.Point.opacity),
        size: parseFloat(data.Point.size),
        rotation: parseFloat(data.Point.rotation),
      },
    };
  }
  if (isLegendJSONSymbolizerPolygon(data)) {
    const {
      "fill-opacity": fillOpacity,
      "stroke-width": strokeWidth,
      "stroke-opacity": strokeOpacity,
      "stroke-linecap": strokeLinecap,
      "stroke-linejoin": strokeLinejoin,
      ...restPolygon
    } = data.Polygon;

    return {
      ...data,
      Polygon: {
        ...restPolygon,
        fillOpacity,
        strokeWidth,
        strokeOpacity,
        strokeLinecap,
        strokeLinejoin,
      },
    };
  }

  const {
    "stroke-width": strokeWidth,
    "stroke-opacity": strokeOpacity,
    "stroke-linecap": strokeLinecap,
    "stroke-linejoin": strokeLinejoin,
    ...restLine
  } = data.Line;

  return {
    ...data,
    Line: {
      ...restLine,
      strokeWidth,
      strokeOpacity,
      strokeLinecap,
      strokeLinejoin,
    },
  };
}

function isLegendJSONSymbolizerPolygon(
  data: LegendJSONSymbolizer
): data is LegendJSONSymbolizerPolygon {
  return data.hasOwnProperty("Polygon");
}
function isLegendJSONSymbolizerRaster(
  data: LegendJSONSymbolizer
): data is LegendJSONSymbolizerRaster {
  return data.hasOwnProperty("Raster");
}

function isLegendJSONSymbolizerRasterRamp(
  data: LegendJSONSymbolizer
): data is LegendJSONSymbolizerRasterRamp {
  return (
    isLegendJSONSymbolizerRaster(data) &&
    data.Raster.hasOwnProperty("colormap") &&
    //@ts-ignore
    data.Raster.colormap.type === "ramp"
  );
}
function isLegendJSONSymbolizerRasterValues(
  data: LegendJSONSymbolizer
): data is LegendJSONSymbolizerRasterValues {
  return (
    isLegendJSONSymbolizerRaster(data) &&
    data.Raster.hasOwnProperty("colormap") &&
    //@ts-ignore
    (data.Raster.colormap.type === "values" ||
      //@ts-ignore
      //FIXME: Added intervals to ramp temporary

      data.Raster.colormap.type === "intervals")
  );
}
// function isLegendJSONSymbolizerLine(
//   data: LegendJSONSymbolizer
// ): data is LegendJSONSymbolizerLine {
//   return data.hasOwnProperty("Line");
// }
function isLegendJSONSymbolizerPoint(
  data: LegendJSONSymbolizer
): data is LegendJSONSymbolizerPoint {
  return data.hasOwnProperty("Point");
}

export function isLegendSymbolizerPolygon(
  data: LegendSymbolizer
): data is LegendSymbolizerPolygon {
  return data.hasOwnProperty("Polygon");
}
export function isLegendSymbolizerRaster(
  data: LegendSymbolizer
): data is LegendSymbolizerRaster {
  return data.hasOwnProperty("Raster");
}
export function isLegendSymbolizerRasterRamp(
  data: LegendSymbolizer
): data is LegendSymbolizerRasterRamp {
  return (
    isLegendSymbolizerRaster(data) &&
    data.Raster.hasOwnProperty("colormap") &&
    //@ts-ignore
    data.Raster.colormap.type === "ramp"
  );
}
export function isLegendSymbolizerRasterValues(
  data: LegendSymbolizer
): data is LegendSymbolizerRasterValues {
  return (
    isLegendSymbolizerRaster(data) &&
    data.Raster.hasOwnProperty("colormap") &&
    //@ts-ignore
    (data.Raster.colormap.type === "values" ||
      //@ts-ignore
      // FIXME: Added intervals
      data.Raster.colormap.type === "intervals")
  );
}
export function isLegendSymbolizerLine(
  data: LegendSymbolizer
): data is LegendSymbolizerLine {
  return data.hasOwnProperty("Line");
}
export function isLegendSymbolizerPoint(
  data: LegendSymbolizer
): data is LegendSymbolizerPoint {
  return data.hasOwnProperty("Point");
}

function isLegendJSON(data: LegendJSON | string): data is LegendJSON {
  return data.hasOwnProperty("Legend");
}

export interface LegendSymbolizerPolygon {
  Polygon: LegendSymbolizerFillStroke;
}

export interface LegendSymbolizerRaster {
  Raster: { opacity: number };
}

export interface LegendSymbolizerLine {
  Line: LegendSymbolizerStroke;
}

type LegendFormatJSON = "application/json";
type LegendFormatIMG = "image/png";

export const useLegend = (
  url: string,
  wms: ExtendedWMSParams,
  format: LegendFormatJSON = "application/json"
) => {
  async function fetch() {
    const params = new URLSearchParams();
    params.set("version", "1.1.0");
    params.set("request", "GetLegendGraphic");
    params.set("layer", wms.layers || "");
    params.set("format", format);
    params.set("style", wms.styles || "");

    const response = await axios.get<LegendJSON | string>(
      `${url}?${params.toString()}`
    );

    if (isLegendJSON(response.data)) {
      return deserializeLegend(response.data);
    }
    return Promise.reject("Unable to load Legend JSON file!");
  }

  return useQuery(["legend", wms.layers], fetch);
};

export const useLegendImage = (
  url: string,
  wms: ExtendedWMSParams,
  format: LegendFormatIMG = "image/png"
) => {
  async function fetch() {
    const params = new URLSearchParams();
    params.set("version", "1.1.0");
    params.set("request", "GetLegendGraphic");
    params.set("layer", wms.layers || "");
    params.set("format", format);
    params.set("style", wms.styles || "");

    if (url.indexOf("?") > -1)
      return Promise.resolve(`${url}&${params.toString()}`);
    return Promise.resolve(`${url}?${params.toString()}`);
    // return Promise.reject("Unable to load Legend image!");
  }

  return useQuery(["legend", "image", wms.layers, format], fetch);
};

export const useCustomLegend = (layer: Layer) => {
  if (layer.legend?.url) return layer.legend?.url;
  if (layer.legend?.layers)
    return `${layer.wms.url}?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layer.legend?.layers}`;
  return `${layer.wms.url}?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layer.wms.layers}`;
};

export const useCustomUrlLegendImage = (url: string) => {
  async function fetch() {
    return Promise.resolve(`${url}`);
  }

  return useQuery(["legend", "image", url], fetch);
};

export function useTextMaxWidth(
  textItems: string[],
  fontSize: string | number,
  fontFamily: string
) {
  return useMemo(() => {
    function calculateTextWidth(text: string) {
      const canvas =
        document.querySelector<HTMLCanvasElement>("canvas#text-measure") ||
        document.createElement("canvas");
      canvas.id = "text-measure";
      const context = canvas.getContext("2d");

      if (context) {
        const mappedFontSize =
          typeof fontSize === "string" ? fontSize : `${fontSize}px`;
        const mappedFontFamily = fontFamily
          .split(", ")
          .map((family) =>
            family.indexOf(" ") > -1 ? `"${family.trim()}"` : family
          )
          .join(", ")
          .replace(";", "");
        context.font = `${mappedFontSize} ${mappedFontFamily}`;
        return Math.ceil(context.measureText(text).width);
      }

      return 0;
    }

    return max(textItems.map((text) => calculateTextWidth(text))) || 40;
  }, [textItems, fontSize, fontFamily]);
}

// //  if (isLegendJSONSymbolizerRaster(data)) {
//   if (isLegendJSONSymbolizerRasterRamp(data)) {
//     return {
//       ...data,
//       Raster: { ...data.Raster, opacity: parseFloat(data.Raster.opacity) },
//     };
//   }
//   if (isLegendJSONSymbolizerRasterValues(data)
