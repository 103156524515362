import { Box, styled, Tooltip, Typography } from "@mui/material";
import { GraphLegendEntry } from "../../types";
interface Props {
  graphLegendItems: GraphLegendEntry[];
}
export function CustomLegend(props: Props) {
  const { graphLegendItems } = props;

  return (
    <StyledBox>
      {graphLegendItems.map((item, i) => {
        return (
          <Box className="legend-item" key={`${item.label}-${i}`}>
            {item.type === "LINE" && (
              <svg width="20" height="12">
                <line
                  x1="0"
                  y1="6"
                  x2="20"
                  y2="6"
                  stroke={item.color}
                  stroke-width="3"
                />
              </svg>
            )}
            {item.type === "BAR" && (
              <svg width="20" height="12" viewBox="0 0 20 12">
                <rect width="20" height="12" fill={item.color} rx="2" ry="2" />
              </svg>
            )}

            <Tooltip title={item.label}>
              <Typography className="overflow-label">{item.label}</Typography>
            </Tooltip>
          </Box>
        );
      })}
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  .legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .overflow-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      flex-shrink: 0;
    }
  }
`;
