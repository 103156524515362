import { Suspense } from "react";
import { Link, Skeleton, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router-dom";

import { useMapModeSidenavMapLayout } from "../hooks/mapHooks";

import { LocationSearchSection } from "../components/location/LocationSearchSection";
import { LocationFlandersLink } from "../components/location/LocationFlandersLink";

export function SearchLayout() {
  const { t } = useTranslation();
  const outletContext = useOutletContext();

  const { currentMapMode, updateMapMode } = useMapModeSidenavMapLayout();

  function toggleMapMode() {
    if (currentMapMode === "som") return updateMapMode("slt");
    return updateMapMode("som");
  }

  return (
    <Styles>
      <LocationSearchSection
        sectionTitle={t("label.KeyFiguresAndGraphs")}
        extraActions={[
          <Link onClick={() => toggleMapMode()}>{t("label.searchOnMap")}</Link>,
        ]}
        extraOption={
          <Suspense fallback={<Skeleton />}>
            <LocationFlandersLink />
          </Suspense>
        }
      />

      <Outlet context={outletContext} />
    </Styles>
  );
}

const Styles = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  .MuiLink-root {
    cursor: pointer;
  }
  .MuiSkeleton-root {
    flex-grow: 1;
  }
`;
