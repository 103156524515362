import { Suspense } from "react";
import { useParams } from "react-router-dom";

import { useSelectedThemeContext } from "../hooks/themeHooks";

import { Graphs } from "../components/graphs/Graphs";
import { GraphSkeleton } from "../components/skeletons/GraphSkeleton";

export function GraphsPage() {
  const { locationId } = useParams();
  const { themes, currentTheme } = useSelectedThemeContext();

  return (
    <Suspense fallback={<GraphSkeleton />}>
      {locationId && (
        <Graphs
          currentTheme={currentTheme}
          themes={themes}
          locationId={+locationId}
        />
      )}
    </Suspense>
  );
}
