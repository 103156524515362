import { Button, List, Link, styled, PaginationItem } from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  totalCount: number;
  offset: number;
  limit: number;
  onPaginate: (event: ChangeEvent<unknown>, page: number) => void;
}

export function DataPagination(props: Props) {
  const { totalCount, offset, limit, onPaginate } = props;
  const { t } = useTranslation();
  const { items } = usePagination({
    count: calculatePagesCount(limit, totalCount),
    page: offset + 1,
    onChange: onPaginate,
  });

  function calculatePagesCount(limit: number, totalCount: number) {
    return totalCount < limit ? 1 : Math.ceil(totalCount / limit);
  }

  return (
    <StyledNav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          if (type === "start-ellipsis" || type === "end-ellipsis")
            return <li key={index}>...</li>;
          if (type === "page")
            return (
              <li key={index}>
                <PaginationItem {...item} page={page} selected={selected} />
              </li>
            );
          return (
            <li key={index}>
              <Link component={Button} {...item}>
                {t(`label.${type}`)}
              </Link>
            </li>
          );
        })}
      </List>
    </StyledNav>
  );
}

const StyledNav = styled("nav")`
  .MuiButton-root {
    text-transform: none;
  }
  padding: ${({ theme }) => theme.spacing(2, 2)};

  .MuiList-root {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(1)};
    justify-content: center;
    align-items: center;
    li:first-of-type {
      margin-right: auto;
    }
    li:last-child {
      margin-left: auto;
    }
  }
`;
