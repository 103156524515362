import { Button, ButtonProps, styled } from "@mui/material";
import { IndicatorFilterData } from "../../types";
import { FilterAltOff } from "@mui/icons-material";

interface Props extends ButtonProps {
  setFilterData: (filterData: IndicatorFilterData) => void;
}

export function ResetDataFilterButton(props: Props) {
  const { setFilterData, ...buttonProps } = props;
  const resetValues: IndicatorFilterData = {
    indicatorDataFilter: {
      themeFilter: {
        filter: [],
        selectAll: true,
      },
      indicatorFilter: {
        filter: [],
        selectAll: true,
      },
      locationTypeFilter: {
        filter: [],
        selectAll: true,
      },
      locationNameAndCodeFilter: {
        filter: [],
        selectAll: true,
      },
      scenarioFilter: {
        filter: [],
        selectAll: true,
      },
      periodFilter: {
        filter: [],
        selectAll: true,
      },
      unitFilter: { filter: [], selectAll: true },
    },
    pageIndex: 0,
    pageSize: 20,
    sortingList: [
      {
        property: "theme",
        direction: "asc",
      },
    ],
  };
  return (
    <StyledButton
      variant="textSmall"
      {...buttonProps}
      startIcon={<FilterAltOff fontSize="small" />}
      onClick={() => setFilterData(resetValues)}
    >
      Reset filters
    </StyledButton>
  );
}

const StyledButton = styled(Button)``;
