import { ReactNode } from "react";
import { useTheme } from "@mui/material";
import { ceil, max, round } from "lodash-es";
import {
  LegendColorMapRampEntry,
  LegendColorMapValuesEntry,
  LegendSymbolizerRasterValues,
} from "../../../hooks/legendHooks";

interface WrappedTextProps {
  label: ReactNode;
  totalHeight: number;
}

interface Props {
  symbolizer: LegendSymbolizerRasterValues;
}

export function LegendSymbolizerItemRasterValues(props: Props) {
  const {
    symbolizer: { Raster },
  } = props;
  const {
    colormap: { entries },
  } = Raster;
  const theme = useTheme();
  const fontSize = theme.typography.fontSize;
  const fontFamily = theme.typography.fontFamily;
  const actualWidth: number[] = [];
  const legendLabels = processEntries(entries);

  function renderLabel(entry: LegendColorMapRampEntry, index: number) {
    const parts = new Array<string>();
    parts.push(entry.label || round(entry.quantity, 2).toLocaleString());
    return parts.join(" ");
  }

  function wrapLabelText(
    entry: LegendColorMapValuesEntry,
    totalHeight: number = 0,
    index: number,
    maxWidth: number,
    x: string,
    y: string
  ): WrappedTextProps {
    const label = renderLabel(entry, 0);
    const words = label.split(" ");
    let lineHeight = 1.5;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Could not get canvas context");
    ctx.font = `${fontSize}px ${fontFamily}`;

    const lineCollection: string[] = words.reduce(
      (lines: string[], word: string) => {
        const [lastLine, ...restLines] = lines.reverse();
        const testLine = `${lastLine} ${word}`;
        const testWidth = ctx.measureText(testLine).width + 70;
        if (testWidth > maxWidth) {
          return [...lines, word];
        }
        actualWidth.push(testWidth);
        return [...restLines.reverse(), testLine];
      },
      [""]
    );
    const tspans = lineCollection.map((line, i) => (
      <tspan key={`tspan-${i}`} x={x} dy={i === 0 ? 10 : 20}>
        {line}
      </tspan>
    ));
    const entryHeight = fontSize * lineCollection.length * lineHeight + 5;
    const wrappedText = (
      <g transform={`translate(0,${totalHeight})`} key={entry.label}>
        <rect color={entry.color} x="0" y="0" width="10" height="10" />
        <text
          key={`${index}-label`}
          x={`${x}px`}
          y={`0`}
          fontFamily={fontFamily}
          fontSize={`${fontSize}px`}
        >
          {tspans}
        </text>
      </g>
    );
    return { label: wrappedText, totalHeight: entryHeight };
  }

  function processEntries(
    entries: LegendColorMapValuesEntry[],
    maxWidth: number = 250
  ) {
    const wrappedTextItems = entries.reduce(
      (
        acc: {
          wrappedItems: WrappedTextProps[];
          totalHeight: number;
        },
        entry,
        i
      ) => {
        const wrappedItem = wrapLabelText(
          entry,
          acc.totalHeight,
          i,
          maxWidth,
          "25",
          "0"
        );
        return {
          totalHeight: acc.totalHeight + wrappedItem.totalHeight,
          wrappedItems: [...acc.wrappedItems, wrappedItem],
        };
      },
      {
        wrappedItems: [],
        totalHeight: 0,
      }
    );

    return wrappedTextItems;
  }

  return (
    <svg
      x="0"
      y="0"
      height={`${legendLabels.totalHeight}px`}
      width={`${ceil(max(actualWidth) || 300) + 25}px`}
    >
      {legendLabels.wrappedItems.map((item) => item.label)}
    </svg>
  );
}
