import { LegendPointGraphics } from "../../../hooks/legendHooks";

interface Props {
  graphics: LegendPointGraphics;
}

export function LegendSymbolizerItemPointGraphics(props: Props) {
  const { graphics } = props;
  const { mark } = graphics;

  return (
    <>
      {mark === "circle" && <circle {...graphics} cx="5" cy="5" r="5" />}
      {mark === "square" && (
        <rect {...graphics} x="0" y="0" width="10" height="10" />
      )}
      {["cross", "x"].includes(mark) && (
        <path
          d="M0 10 L10 0 M10 10 L0 0"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "triangle" && (
        <path
          d="M5,0 L10,10 L0,10 Z"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "star" && (
        <path
          d="M5,0 L6.5,3.5 L10,3.75 L7.5,6.5 L8,10 L5,8 L2,10 L2.5,6.5 L0,3.75 L 3.5,3.5Z"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "x" && (
        <path
          d="M2,2 L8,8 M2,8 L8,2"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://vertline" && (
        <path
          d="M2,0 L2,10 M8,0 L8,10"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://horline" && (
        <path
          d="M0,2 L10,2 M0,8 L10,8"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://slash" && (
        <path
          d="M3,0 L0,3 M7.5,0 L0,7.5 M10,2.5 L2.5,10 M10,7 L7,10"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://backslash" && (
        <path
          d="M0,5 L5,10 M10,10 L0,0 M5,0 L10,5"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://dot" && (
        <>
          <circle {...graphics} cx="2.5" cy="2.5" r="1.5" />
          <circle {...graphics} cx="7.5" cy="7.5" r="1.5" />
          <circle {...graphics} cx="2.5" cy="7.5" r="1.5" />
          <circle {...graphics} cx="7.5" cy="2.5" r="1.5" />
        </>
      )}
      {mark === "shape://plus" && (
        <path
          d="M2,0 L2,10 M7,0 L7,10 M0,2 L10,2 M0,7 L10,7"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://times" && (
        <path
          d="M0,3 L7,10 M3,0 L10,7 M0,6 L6,0 M3,10 L10,3"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://oarrow" && (
        <path
          d="M3,3 L8,5 L3,7"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
      {mark === "shape://carrow" && (
        <path
          d="M3,3 L8,5 L3,7 Z"
          {...graphics}
          x="0"
          y="0"
          width="10"
          height="10"
        />
      )}
    </>
  );
}
