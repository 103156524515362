import axios from "axios";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { BackgroundLayer } from "../types";
import { convertStringArrToNumberArr } from "../utils/ArrayUtils";
import { mapURLSearchParams } from "../utils/SearchParamUtils";

export const useBackgroundLayerParams = () => {
  const [params, setParams] = useSearchParams();
  const selectedBackgroundLayerId = +(params.get("bgl") || "");

  function updateBackgroundLayerParam(backgroundLayerId: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("bgl", backgroundLayerId.toString());

    setParams(newParams);
  }

  return { selectedBackgroundLayerId, updateBackgroundLayerParam };
};

export const useSelectedBackgroundLayer = (
  backgroundLayers: BackgroundLayer[]
) => {
  const { selectedBackgroundLayerId } = useBackgroundLayerParams();
  const backgroundLayerUrl = backgroundLayers.find(
    (backgroundLayer) => backgroundLayer.id === selectedBackgroundLayerId
  );
  const [firstBackgroundLayer] = backgroundLayers || [];
  const activeBackgroundLayer = backgroundLayerUrl
    ? backgroundLayerUrl
    : firstBackgroundLayer;

  return activeBackgroundLayer;
};

export const useBackgroundLayers = () => {
  const fetch = async () => {
    const { data } = await axios.get<BackgroundLayer[]>(
      `/api/layers/background`,
      {
        headers: {
          Prefer: "Prefer: code=200, example=example-1",
        },
      }
    );
    return data;
  };
  return useQuery(["backgroundLayers"], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useBorderLayerParams = () => {
  const [params, setParams] = useSearchParams();
  const selectedBorderLayerIds = convertStringArrToNumberArr(
    params.getAll("bl")
  );

  function updateBorderLayerParam(borderLayerId: number, checked: boolean) {
    const newParams = checked
      ? new URLSearchParams(params)
      : mapURLSearchParams(params, ["bl"]);

    if (checked) {
      newParams.append("bl", borderLayerId.toString());
    }

    if (!checked) {
      const filteredBorderLayerIds = selectedBorderLayerIds.filter(
        (value) => value !== borderLayerId
      );
      filteredBorderLayerIds.forEach((id) =>
        newParams.append("bl", id.toString())
      );
    }

    setParams(newParams);
  }

  return { selectedBorderLayerIds, updateBorderLayerParam };
};

export const useSelectedBorderLayers = (borderLayers: BackgroundLayer[]) => {
  const { selectedBorderLayerIds } = useBorderLayerParams();
  const activeBorderLayers = borderLayers?.filter(
    (borderLayer) => selectedBorderLayerIds.indexOf(borderLayer.id) !== -1
  );
  return activeBorderLayers;
};
