import { useContext } from "react";
import { AppBar, Link, styled, Toolbar } from "@mui/material";
import { useMatch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useLocation } from "../../hooks/locationHooks";
import { useNavigate } from "../../hooks/routerHooks";

import { AppBreadcrumbs } from "../AppBreadcrumbs";
import { JoyrideContext } from "../joyride/JoyrideContext";

interface Props {}

export function Header(props: Props) {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { data: location } = useLocation(+(locationId || "0"), {
    enabled: locationId !== undefined && locationId !== "",
  });
  const { themeId } = useParams();

  const matchedLocation = useMatch(`/${themeId}`);

  const { activateJoyride } = useContext(JoyrideContext);

  const navigate = useNavigate();

  function startJoyride() {
    if (!matchedLocation) navigate(`./${themeId}`);
    setTimeout(() => activateJoyride(true), 500);
  }

  return (
    <StyledToolbar position="relative" elevation={0}>
      <Toolbar>
        <AppBreadcrumbs currentLocation={location} />
        <Link className="how-to-link" onClick={startJoyride}>
          {t("label.howDoIUseThisApplication")}
        </Link>
      </Toolbar>
    </StyledToolbar>
  );
}

const StyledToolbar = styled(AppBar)`
  .how-to-link {
    font-weight: 700;
  }
`;
