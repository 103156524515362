import { Button, DrawerProps, styled, Tooltip } from "@mui/material";
import { Equalizer, Layers, TableView } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import { InlineDrawer } from "../drawers/InlineDrawer";
import { NavLink } from "../navigation/NavLink";
import { useLocation } from "react-router-dom";

interface Props extends DrawerProps {}

export function SideNav(props: Props) {
  const { ...drawerProps } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <NavigationDrawer
      variant="permanent"
      open={true}
      elevation={0}
      {...drawerProps}
    >
      <span data-tour="step-4">
        <Tooltip title={t<string>("label.graphsOverview")} placement="right">
          <Button to={`../graphs`} component={NavLink}>
            <Equalizer />
          </Button>
        </Tooltip>
        <Tooltip title={t<string>("label.layerOverview")} placement="right">
          <Button
            to={`../layers/all`}
            component={NavLink}
            className={pathname.includes("layers/") ? "active" : ""}
          >
            <Layers />
          </Button>
        </Tooltip>
        <Tooltip title={t<string>("label.tableOverview")} placement="right">
          <Button to={`../overview`} component={NavLink}>
            <TableView />
          </Button>
        </Tooltip>
      </span>
    </NavigationDrawer>
  );
}

const NavigationDrawer = styled(InlineDrawer)`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-right: 0;
    width: ${({ theme }) => theme.spacing(8)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .MuiButton-root {
      height: ${({ theme }) => theme.spacing(8)};

      &.active {
        background-color: ${({ theme }) => theme.palette.primary.main};
        & > .MuiSvgIcon-root {
          color: ${({ theme }) => theme.palette.common.white};
        }
      }
    }
    .push-bottom {
      margin-top: auto;
    }
  }
`;
