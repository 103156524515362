import { WMSTileLayer } from "react-leaflet";
import { useLayers } from "../../hooks/mapSetHooks";
import { ExtendedWMSParams, Theme, WMS } from "../../types";

interface Props {
  currentTheme: Theme;
  themes: Theme[];
}

export function ThemeLayers(props: Props) {
  const { currentTheme, themes } = props;
  const layers = useLayers(currentTheme, themes);
  function getExtendedWMSParams(wmsParams: WMS): ExtendedWMSParams {
    return { ...wmsParams, tiled: true };
  }
  return (
    <>
      {layers.length !== 0 &&
        layers
          .reverse()
          .map((layer, index) => (
            <WMSTileLayer
              key={layer.id}
              {...getExtendedWMSParams(layer.wms)}
              attribution={layer.attribution}
              zIndex={index + 2}
            />
          ))}
    </>
  );
}
