import { useRef } from "react";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";

import { AppLayout } from "../layout/AppLayout";
import { MapLayout } from "../layout/MapLayout";
import { SideNavLayout } from "../layout/SideNavLayout";
import { SearchLayout } from "../layout/SearchLayout";
import { SideNavMapLayout } from "../layout/SideNavMapLayout";
import { LayerOverviewLayout } from "../layout/LayerOverviewLayout";

import { Outlet } from "../components/navigation/Outlet";
import { Navigate } from "../components/navigation/Navigate";
import { SelectedThemeGuard } from "../components/guards/SelectedThemeGuard";
import { ThemeGuard } from "../components/guards/ThemeGuard";
import { DefaultThemeGuard } from "../components/guards/DefaultThemeGuard";
import { LocationGuard } from "../components/guards/LocationGuard";

import { EffectsAndImpactsPage } from "./EffectsAndImpactsPage";
import { GraphsPage } from "./GraphsPage";
import { DataPage } from "./DataPage";
import { MapSetsActivePage } from "./MapSetsActivePage";
import { MapSetsAllPage } from "./MapSetsAllPage";

export function RootPage() {
  const notistackRef = useRef<SnackbarProvider | null>(null);

  function onClickDismiss(key: SnackbarKey) {
    if (notistackRef !== null && notistackRef.current !== null) {
      notistackRef.current.closeSnackbar(key);
    }
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={(key: SnackbarKey) => (
        <IconButton
          style={{ color: "#fff" }}
          onClick={() => onClickDismiss(key)}
        >
          <Close />
        </IconButton>
      )}
    >
      <Routes>
        <Route path="*" element={<ThemeGuard />}>
          <Route path="*" element={<AppLayout />}>
            <Route path="*" element={<DefaultThemeGuard />} />
            <Route path=":themeId" element={<SelectedThemeGuard />}>
              <Route path="*" element={<MapLayout />}>
                <Route index element={<EffectsAndImpactsPage />}></Route>
              </Route>
              <Route path="location" element={<Outlet />}>
                <Route path=":locationId" element={<LocationGuard />}>
                  <Route
                    path="*"
                    element={<SideNavMapLayout panelSize={100} />}
                  >
                    <Route path="*" element={<SearchLayout />}>
                      <Route path="graphs" element={<GraphsPage />} />
                      <Route path="layers" element={<LayerOverviewLayout />}>
                        <Route path="all" element={<MapSetsAllPage />} />
                        <Route path="active" element={<MapSetsActivePage />} />
                        <Route path="*" element={<Navigate to="all" />} />
                      </Route>
                      <Route path="*" element={<Navigate to="graphs" />} />
                    </Route>
                  </Route>
                  <Route path="overview" element={<SideNavLayout />}>
                    <Route index element={<DataPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </SnackbarProvider>
  );
}
