import { Fab, Hidden } from "@mui/material";
// import { Download } from "@mui/icons-material";
import { ControlOptions } from "leaflet";
import { ReactComponent as Download } from "../../assets/icons/download.svg";

import { MapControlBase } from "./MapControlBase";

interface Props extends ControlOptions {}

export function DownloadControl(props: Props) {
  const { ...controlProps } = props;

  return (
    <Hidden xsUp>
      <MapControlBase {...controlProps}>
        <Fab size="small" variant="circular">
          <Download />
        </Fab>
      </MapControlBase>
    </Hidden>
  );
}
