import axios from "axios";
import { partition, sortBy } from "lodash-es";
import { useQuery } from "react-query";
import { GraphData, Indicator, LayerSet, Theme } from "../types";
import { useMapSets } from "./mapSetHooks";

export const useGraphData = (indicatorId: number, locationId: number) => {
  async function fetch() {
    const { data } = await axios.get<GraphData>(
      `/api/indicators/${indicatorId}/locations/${locationId}/data`
    );
    return data;
  }
  return useQuery(["location", indicatorId, locationId], fetch);
};

export const useIndicatorRepresentations = (
  currentTheme: Theme,
  themes: Theme[]
) => {
  const selectedMapSets = useMapSets(currentTheme, themes);

  const selectedMapSetIndicators = selectedMapSets.reduce(
    (indicators: Indicator[], layerSet: LayerSet) => {
      if (layerSet.indicator) return [...indicators, layerSet.indicator];
      return [...indicators];
    },
    []
  );
  const sortedThemeIndicators = sortBy(
    [...currentTheme.indicators],
    "serialNumber"
  );
  const filteredMapSetIndicators = selectedMapSetIndicators.filter(
    (indicator) =>
      !sortedThemeIndicators.some(
        (themeIndicator) => themeIndicator.id === indicator.id
      )
  );
  const combinedIndicators = [
    ...filteredMapSetIndicators,
    ...sortedThemeIndicators,
  ];
  const [effectsIndicators, impactsIndicators] = partition(combinedIndicators, {
    type: "Effecten",
  });
  return { effectsIndicators, impactsIndicators };
};
