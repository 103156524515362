import axios from "axios";
import { uniq } from "lodash-es";
import { useSnackbar } from "notistack";
import { useQuery, UseQueryOptions } from "react-query";
import {
  DownloadProgress,
  IndicatorDataFilter,
  IndicatorDataResponse,
  IndicatorFilterData,
  LayerSet,
  Location,
  Scenario,
  Selection,
  Theme,
} from "../types";
import { customPost } from "../utils/AxiosUtils";
import { deserializeErrors } from "../utils/ErrorUtils";
import { useMapSetSelection } from "./mapSetHooks";
import { useActiveScenario } from "./scenarioHooks";

interface DownloadAllResponse {
  excelUrl: string;
}

function formatScenario(scenario: Scenario) {
  if (scenario.present) return scenario.climateScenario;
  return `${scenario.climateScenario} ${scenario.year}`;
}

export const useInitialFilterData = (
  currentTheme: Theme,
  location: Location,
  themes: Theme[]
): IndicatorFilterData => {
  const initialIndicators = useAllIndicatorData(currentTheme, themes);
  const { data: scenario } = useActiveScenario(currentTheme);
  const { data: indicators } = useInitialExportIndicators(currentTheme);
  const extraThemeIndicators = indicators || [];


  return {
    indicatorDataFilter: {
      themeFilter: {
        filter: [],
        selectAll: true,
      },
      indicatorFilter: {
        filter: [...initialIndicators, ...extraThemeIndicators],
        selectAll: false,
      },
      locationTypeFilter: {
        filter: [location.type.name],
        selectAll: false,
      },
      locationNameAndCodeFilter: {
        filter: [location.nameAndCodeFilter],
        selectAll: false,
      },
      scenarioFilter: {
        filter: [scenario ? formatScenario(scenario.scenario) : ""],
        selectAll: false,
      },
      periodFilter: {
        filter: [],
        selectAll: true,
      },
      unitFilter: { filter: [], selectAll: true },
    },
    pageIndex: 0,
    pageSize: 20,
    sortingList: [
      {
        property: "theme",
        direction: "asc",
      },
    ],
  };
};

export function useIndicatorData(indicatorFilterData: IndicatorFilterData) {
  async function fetch() {
    const { data } = await customPost<IndicatorDataResponse>(
      `/api/indicatordata/export`,
      { ...indicatorFilterData }
    );
    return data;
  }
  return useQuery(["IndicatorData", indicatorFilterData], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
  });
}

export const useDownloadData = (
  indicatorDataFilter: IndicatorDataFilter,
  options: Pick<UseQueryOptions, "enabled">
) => {
  const { enqueueSnackbar } = useSnackbar();

  async function fetch() {
    const { data } = await customPost<number>(
      `/api/indicatordata/export/excel`,
      {
        ...indicatorDataFilter,
      }
    );
    return data;
  }
  return useQuery(["IndicatorDataDownload", indicatorDataFilter], fetch, {
    onError: (err) => {
      const errors = deserializeErrors(err);
      errors.map((er) => {
        return enqueueSnackbar(er.message, {
          variant: "error",
          preventDuplicate: true,
        });
      });
    },
    cacheTime: Infinity,
    retry: 0,
    staleTime: Infinity,
    suspense: false,
    ...options,
  });
};

export const useDownloadProgress = (progressId: number) => {
  async function fetch() {
    const { data } = await axios.get<DownloadProgress>(
      `/api/indicatordata/export/progress/${progressId}`
    );
    return data;
  }
  return useQuery(["IndicatorDownloadProgress", progressId], fetch, {
    onSuccess: () => {},
    refetchInterval: 1000,
    suspense: false,
  });
};

export const useDownloadExcel = (progressId: number) => {
  async function fetch() {
    const data = await axios.get(
      `/api/indicatordata/export/progress/${progressId}/download`,
      { responseType: "arraybuffer" }
    );
    return data;
  }
  return useQuery(["ExcelDownload", progressId], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: false,
  });
};

export const useDownloadAllExcel = () => {
  async function fetch() {
    const { data } = await axios.get<DownloadAllResponse>(
      `/api/indicatordata/export/download/all`
    );
    return data;
  }
  return useQuery(["ExcelAllDownload"], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: false,
  });
};

export function useAllIndicatorData(currentTheme: Theme, themes: Theme[]) {
  const { mapSetSelections } = useMapSetSelection(currentTheme);

  const indicatorCollection = mapSetSelections.reduce(
    (indicatorCollection: string[], layerSetSelection: Selection) => {
      const layerSetIndicators = themes.flatMap((theme, i) => {
        return theme.layerSets.filter(
          (layerSet) => layerSet.id === layerSetSelection.id
        );
      });
      const indicatorNameCollection = layerSetIndicators.reduce(
        (indicatorNames: string[], layerSet: LayerSet) => {
          if (layerSet.indicator)
            return [...indicatorNames, layerSet.indicator.name];
          return [...indicatorNames];
        },
        []
      );
      return [...indicatorCollection, ...indicatorNameCollection];
    },
    []
  );

  const combinedIndicators = [
    ...indicatorCollection,
    ...currentTheme.indicators.map((i) => i.name),
  ];

  return uniq(combinedIndicators);
}

export function useInitialExportIndicators(theme: Theme) {
  async function fetch() {
    const { data } = await axios.get<{
      indicatorList: { id: number; name: string }[];
    }>(`/api/themes/${theme.id}/exportindicators`);
    return data.indicatorList.map((i) => i.name);
  }

  return useQuery(["exportindicators", theme.id], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
