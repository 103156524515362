import { alpha, Paper, styled } from "@mui/material";
import { ControlOptions } from "leaflet";

import { MapControlBase } from "./MapControlBase";

interface Props extends ControlOptions {
  notification: string;
}

export function ThemeNotification(props: Props) {
  const { notification, ...controlProps } = props;

  return (
    <MapControlBase className="flush-to-edge" {...controlProps}>
      <Styles elevation={0}>{notification}</Styles>
    </MapControlBase>
  );
}

const Styles = styled(Paper)`
  display: flex;
  gap: 10px;

  padding: ${({ theme }) => theme.spacing(0.5, 2, 0.2, 2)};
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.8)};

  .MuiLink-root {
    cursor: pointer;
  }
`;
