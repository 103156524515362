import { Box, CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { ProcessTheme } from "../themes/ProcessTheme";

export function ThemeGuard() {
  const { t } = useTranslation();
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: " translate(-50%, 0%)",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <CircularProgress />
          {t("label.loading")}...
        </Box>
      }
    >
      <ProcessTheme />
    </Suspense>
  );
}
