import { useTranslation } from "react-i18next";
import { Step } from "react-joyride";
export function useJoyrideSteps(): Step[] {
  const { t } = useTranslation();

  return [
    {
      target: '[data-tour="step-1"]',
      title: t("label.step1Title"),
      content: t("label.step1Content"),

      hideCloseButton: true,
      disableOverlayClose: true,
      disableBeacon: true,
      spotlightClicks: false,
      spotlightPadding: 10,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '[data-tour="step-2"]',
      title: t("label.step2Title"),
      content: t("label.step2Content"),
      hideCloseButton: true,
      disableOverlayClose: true,
      disableBeacon: true,
      placement: "top",
      spotlightClicks: false,
      spotlightPadding: 10,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '[data-tour="step-3"]',
      title: t("label.step3Title"),
      content: t("label.step3Content"),
      hideCloseButton: true,
      disableOverlayClose: true,
      disableBeacon: true,
      spotlightClicks: false,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '[data-tour="step-4"]',
      title: t("label.step4Title"),
      content: t("label.step4Content"),
      hideCloseButton: true,
      disableOverlayClose: true,
      disableBeacon: true,
      spotlightClicks: false,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '[data-tour="step-5"]',
      title: t("label.step5Title"),
      content: t("label.step5Content"),
      hideCloseButton: true,
      disableOverlayClose: true,
      disableBeacon: true,
      placement: "left",
      spotlightClicks: false,
      spotlightPadding: 5,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
  ];
}
