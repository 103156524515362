import { Fragment, ReactNode, useContext } from "react";
import { PaperProps, styled, Typography } from "@mui/material";

import { PaddedPaper } from "../surfaces/PaddedPaper";
import { LocationSearch } from "./LocationSearch";
import { LocationTypeRadioForm } from "./LocationTypeRadioForm";
import { Location } from "../../types";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { formatSearchLocationUrl } from "../../utils/LocationUtils";
import { BoundsContext } from "../store/BoundsContext";
import { useNavigate } from "react-router";
interface Props extends PaperProps {
  sectionTitle: string;
  extraActions?: ReactNode[];
  extraOption?: ReactNode;
}

export function LocationSearchSection(props: Props) {
  const { sectionTitle, extraActions, extraOption, ...paperProps } = props;
  const { pathname } = useLocation();
  const { themeId } = useParams();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { setLocBounds } = useContext(BoundsContext);

  function onSubmitLocation(location: Location) {
    const formattedUrl = formatSearchLocationUrl(
      pathname,
      location.id,
      themeId
    );
    const newParams = new URLSearchParams(params);
    if (!location.boundingBox) return;
    const isSinglePoint =
      location.boundingBox[0][0] === location.boundingBox[1][0] &&
      location.boundingBox[0][1] === location.boundingBox[1][1];
    newParams.delete("addressMarker");
    if (isSinglePoint) {
      newParams.delete("point");
      newParams.set(
        "addressMarker",
        `${location.boundingBox[0][0]},${location.boundingBox[0][1]}`
      );
    }

    setLocBounds(location.boundingBox);
    navigate(`${formattedUrl}?${newParams.toString()}`);
  }

  return (
    <StyledPaper elevation={0} {...paperProps}>
      <Typography variant="h2" gutterBottom>
        {sectionTitle}
      </Typography>
      <div className="location-container">
        <LocationSearch
          onSubmitLocation={onSubmitLocation}
          components={{
            type: (props) => (
              <LocationTypeRadioForm {...props} extraOption={extraOption} />
            ),
            typeRender: "after",
          }}
        />
      </div>
      <div className="extra-actions-container">
        {extraActions &&
          extraActions.map((action, index) => (
            <Fragment key={index}>{action}</Fragment>
          ))}
      </div>
    </StyledPaper>
  );
}

const StyledPaper = styled(PaddedPaper)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(-4, -4, 2, -4)};
  padding: ${({ theme }) => theme.spacing(3, 4, 2, 4)};

  .location-container {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
  }

  .extra-actions-container {
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;
