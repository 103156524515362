import { styled, Table, TableContainer } from "@mui/material";
import {
  IndicatorDataResponse,
  IndicatorDataFilter,
  SortingList,
  DataFilter,
} from "../../types";
import { DataTableBody } from "./DataTableBody";
import { DataTableHeader } from "./DataTableHeader";

interface Props {
  tableData: IndicatorDataResponse;
  sortingList: SortingList[];
  isLoading: boolean;
  filterData: IndicatorDataFilter;
  onSort: (sortingList: SortingList[]) => void;
  onToggleFilter: (
    value: DataFilter,
    property: keyof IndicatorDataFilter
  ) => void;
}
export function DataTable(props: Props) {
  const {
    tableData,
    sortingList,
    isLoading,
    filterData,
    onToggleFilter,
    onSort,
  } = props;
  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <DataTableHeader
          isLoading={isLoading}
          tableData={tableData}
          filterData={filterData}
          sortingList={sortingList}
          onToggleFilter={onToggleFilter}
          onSort={onSort}
        />
        <DataTableBody tableData={tableData} isLoading={isLoading} />
      </Table>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)`
  overflow-x: "initial";
  max-height: 100%;
  .MuiTableCell-root {
    font-size: 13px;
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;
