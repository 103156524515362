import { Suspense } from "react";
import { CircularProgress, styled, Table, TableContainer } from "@mui/material";
import { LatLngLiteral } from "leaflet";
import {
  useLayerSetsWithoutGroups,
  useVMMMapSets,
} from "../../../hooks/mapSetHooks";
import { useScenarios } from "../../../hooks/scenarioHooks";
import { Theme } from "../../../types";
import { InfoTableHeaders } from "./InfoTableHeaders";
import { InfoTableRows } from "./InfoTableRows";

interface Props {
  point: LatLngLiteral;
  currentTheme: Theme;
  themes: Theme[];
}

export function InfoTable(props: Props) {
  const { point, currentTheme, themes } = props;

  const { data: themeScenarios } = useScenarios(currentTheme.id);
  const mapSets = useVMMMapSets(currentTheme, themes);
  const filteredMapSets = useLayerSetsWithoutGroups(mapSets);

  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <Suspense fallback={<CircularProgress />}>
          <InfoTableHeaders scenarioList={themeScenarios?.scenarioList || []} />
        </Suspense>
        <InfoTableRows
          mapSets={filteredMapSets}
          scenarioList={themeScenarios?.scenarioList || []}
          point={point}
        />
      </Table>
    </StyledTableContainer>
  );
}
const StyledTableContainer = styled(TableContainer)`
  max-height: ${({ theme }) => theme.spacing(50)};
  max-width: ${({ theme }) => theme.spacing(100)};
`;
