import { Paper, styled, Tab, Tabs } from "@mui/material";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";

import { NavLink } from "../components/navigation/NavLink";

export function LayerOverviewLayout() {
  const outletContext = useOutletContext();
  const { pathname } = useLocation();
  const tabValue = pathname.substring(pathname.lastIndexOf("/") + 1);

  return (
    <Styles>
      <Tabs
        value={tabValue}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="all" label="Alle kaarten" component={NavLink} to="all" />
        <Tab
          value="active"
          label="Actieve Kaarten"
          component={NavLink}
          to="active"
        />
      </Tabs>
      <Paper className="overflow-container" elevation={0}>
        <Outlet context={outletContext} />
      </Paper>
    </Styles>
  );
}

const Styles = styled("div")`
  margin-right: -${({ theme }) => theme.spacing(4)};
  .MuiTabs-root {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
  display: flex;
  flex-direction: column;
  overflow: auto;
  .overflow-container {
    overflow: auto;
    padding-right: ${({ theme }) => theme.spacing(4)};
  }
`;
