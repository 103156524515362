import { TileLayer, WMSTileLayer } from "react-leaflet";
import { useSelectedBackgroundLayer } from "../../hooks/backgroundLayersHooks";
import { BackgroundLayer } from "../../types";
import { getExtendedWMSParams } from "../../utils/LayerUtils";

interface Props {
  backgroundLayers: BackgroundLayer[];
}
export function BackgroundLayerRenderer(props: Props) {
  const { backgroundLayers } = props;
  const activeBackgroundLayer = useSelectedBackgroundLayer(backgroundLayers);

  return (
    <>
      {activeBackgroundLayer && (
        <>
          {activeBackgroundLayer.wms.type === "TILE" && (
            <TileLayer
              z-index={0}
              url={activeBackgroundLayer.wms.url}
              key={activeBackgroundLayer.id}
            />
          )}
          {activeBackgroundLayer.wms.type === "WMS" && (
            <WMSTileLayer
              {...getExtendedWMSParams(activeBackgroundLayer.wms)}
              key={activeBackgroundLayer.id}
              z-index={0}
            />
          )}
        </>
      )}
      {!activeBackgroundLayer && (
        <TileLayer
          key="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          z-index={0}
        />
      )}
    </>
  );
}
