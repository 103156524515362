import { Fragment } from "react";
import {
  Avatar,
  Collapse,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { NavLink, useSearchParams } from "react-router-dom";

import { Theme } from "../../types";

import { NavLink as CustomNavLink } from "../navigation/NavLink";
import { PaddedPaper } from "../surfaces/PaddedPaper";
import { StyledMarkdown } from "../layout/StyledMarkdown";

interface Props {
  theme: Theme;
  selected: boolean;
  navigationPath: string;
  resetURLStateOnNavigate: boolean;
  showDivider: boolean;
}

export function ThemeListItem(props: Props) {
  const {
    theme,
    selected,
    navigationPath,
    resetURLStateOnNavigate,
    showDivider,
  } = props;
  const [params] = useSearchParams();

  return (
    <Fragment key={theme.id}>
      <ListItemButton
        component={resetURLStateOnNavigate ? NavLink : CustomNavLink}
        to={`${navigationPath}${theme.id}?${params.toString()}`}
        disabled={selected}
      >
        <ListItemAvatar>
          <Avatar src={theme.iconUrl} variant="square" />
        </ListItemAvatar>
        <ListItemText primary={theme.name} />
        <ExpandMore className={selected ? "rotate-180" : "rotate-none"} />
      </ListItemButton>
      {selected && (
        <StyledCollapse in={selected} unmountOnExit appear={true} exit={true}>
          <PaddedPaper elevation={0} sx={{ paddingTop: 0 }}>
            <StyledMarkdown content={theme.description} />
          </PaddedPaper>
        </StyledCollapse>
      )}
      {showDivider && !selected && <Divider />}
    </Fragment>
  );
}

const StyledCollapse = styled(Collapse)`
  padding-top: 0;
  .MuiPaper-root {
    padding-top: 0;
  }
`;
