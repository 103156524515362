import { Suspense } from "react";
import {
  Skeleton,
  styled,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LatLngLiteral } from "leaflet";

import { LayerSet, ThemeScenario } from "../../../types";
import { InfoTableRow } from "./InfoTableRow";

interface Props {
  mapSets: LayerSet[];
  scenarioList: ThemeScenario[];
  point: LatLngLiteral;
}

export function InfoTableRows(props: Props) {
  const { mapSets, scenarioList, point } = props;
  const { t } = useTranslation();

  return (
    <StyledTableBody>
      {mapSets.length === 0 && (
        <TableRow>
          <TableCell colSpan={scenarioList.length + 2} align="center">
            <Typography>{t("label.noMapSetsFoundWithPointInfo")}</Typography>
          </TableCell>
        </TableRow>
      )}
      {mapSets.length > 0 &&
        mapSets.map((mapSet) => (
          <Suspense
            key={mapSet.id}
            fallback={
              <TableRow>
                <TableCell colSpan={scenarioList.length + 2}>
                  <Skeleton width="auto" />
                </TableCell>
              </TableRow>
            }
          >
            <InfoTableRow
              key={mapSet.id}
              mapSet={mapSet}
              themeScenarios={scenarioList}
              point={point}
            />
          </Suspense>
        ))}
    </StyledTableBody>
  );
}

const StyledTableBody = styled(TableBody)``;
