import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate as useNavigateBase,
} from "react-router-dom";

export function useNavigate() {
  const location = useLocation();
  const navigateBase = useNavigateBase();
  function navigate(to: To, options?: NavigateOptions) {
    const newLocation =
      typeof to === "string"
        ? { ...location, pathname: to }
        : { ...location, ...to };
    return navigateBase(newLocation, options);
  }

  return navigate;
}
