import { styled, TableCell, Typography } from "@mui/material";
import { LatLngLiteral } from "leaflet";
import { useLayerInfo } from "../../../hooks/mapSetHooks";
import { Layer } from "../../../types";

interface Props {
  layer?: Layer;
  point: LatLngLiteral;
  nbDigits: number;
}

export function InfoTableCell(props: Props) {
  const { layer, point, nbDigits } = props;
  const { data: value } = useLayerInfo(point, layer);

  function round(val: number | string | null, precision: number) {
    if (
      val === -9999 ||
      val === -999 ||
      val === null ||
      typeof val === "string"
    )
      return "-";
    if (val.toString().includes("e+38")) return "-";
    const factor = Math.pow(10, precision);
    return Math.round(val * factor) / factor;
  }

  return (
    <StyledTableCell>
      {value !== undefined && <Typography>{round(value, nbDigits)}</Typography>}
      {value === undefined && <Typography>-</Typography>}
    </StyledTableCell>
  );
}

const StyledTableCell = styled(TableCell)``;
