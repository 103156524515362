import { useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";

import { useDownloadExcel } from "../../hooks/indicatorDataHooks";

import { DownloadState } from "../../types";

interface Props {
  progressId: number;
  toggleDownloadState: (downloadValue: DownloadState) => void;
}
interface BlobFile {
  filename: string;
  fileUrl: string;
}

export function DownloadExcel(props: Props) {
  const { progressId, toggleDownloadState } = props;
  const { data } = useDownloadExcel(progressId);
  const [excelFile, setExcelFile] = useState<BlobFile>();
  const linkRef = useRef<HTMLAnchorElement>(null!);

  function getFilenameFromResponse(response: AxiosResponse): string | null {
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );
    if (filename) return filename[1].replace(/["]/g, "");
    else return null;
  }

  useEffect(() => {
    if (!data) return;

    const objectUrl = window.URL.createObjectURL(
      new Blob([data.data], {
        type: data.headers["content-type"],
      })
    );

    const filename = getFilenameFromResponse(data);
    setExcelFile({
      filename: filename || "",
      fileUrl: objectUrl,
    });
    setTimeout(() => {
      linkRef.current.click();
      toggleDownloadState("OFF");
    }, 1);
  }, [data, toggleDownloadState]);

  return (
    <a
      href={excelFile?.fileUrl || ""}
      ref={linkRef}
      style={{ display: "none" }}
      key={excelFile?.filename}
      download={excelFile?.filename}
    >
      download
    </a>
  );
}
