import { AppBar, Skeleton, Toolbar } from "@mui/material";
import { Suspense } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { Header } from "../components/layout/Header";
import { VMMHeader } from "../components/layout/VMMHeader";
import { VMMFooter } from "../components/layout/VMMFooter";

export function AppLayout() {
  const context = useOutletContext();
  return (
    <>
      <VMMHeader />
      <Suspense
        fallback={
          <AppBar elevation={0}>
            <Toolbar>
              <Skeleton width="100%" />
            </Toolbar>
          </AppBar>
        }
      >
        <Header />
      </Suspense>
      {/* <Toolbar /> */}
      <Outlet context={context} />
      <VMMFooter />
    </>
  );
}
