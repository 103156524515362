import axios from "axios";
import { LatLng, LatLngBoundsExpression, LatLngLiteral } from "leaflet";
import { Geometry, MultiPolygon } from "geojson";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { Location, LocationType } from "../types";
import { BackendLocation } from "../types/backend";
import { useSearchParams } from "react-router-dom";
import { difference, multiPolygon, Polygon, Position } from "@turf/turf";
import { formatBackendBboxToArray } from "../utils/LocationUtils";

export const useLocationTypes = () => {
  const fetch = async () => {
    const { data } = await axios.get<LocationType[]>(`/api/locations/types`, {
      headers: {
        Prefer: "Prefer: code=200, example=example-1",
      },
    });
    return data;
  };
  return useQuery(["locationTypes"], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useLocationNamed = (
  query: string,
  type: string,
  options: Pick<UseQueryOptions, "enabled">
) => {
  async function fetch() {
    const { data } = await axios.get<BackendLocation[]>(
      `/api/locations/names`,
      {
        params: {
          query: query.trim(),
          type: type,
        },
      }
    );
    return data.map(deserializeNamedLocation);
  }
  return useQuery(["locationNames", query, type], fetch, {
    suspense: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    ...options,
  });
};

function isMultiPolygon(geometry: Geometry): geometry is MultiPolygon {
  return geometry.type === "MultiPolygon";
}

function deserializeNamedLocation(data: BackendLocation): Location {
  return {
    ...data,
    boundingBox: data.boundingBox
      ? formatBackendBboxToArray(data.boundingBox)
      : undefined,
  };
}

function deserializeLocation(data: BackendLocation): Location {
  const { geometry_4326: geometry, ...restGeometry } = data;
  const inverseGeometry = { ...geometry };

  if (isMultiPolygon(inverseGeometry)) {
    const invertedGeojson = invertGeometry(inverseGeometry.coordinates);
    return {
      ...restGeometry,
      geometry: geometry,
      inverseGeometry: invertedGeojson?.geometry,
      boundingBox: data.boundingBox
        ? formatBackendBboxToArray(data.boundingBox)
        : undefined,
    };
  }

  return {
    ...restGeometry,
    geometry: geometry,
    inverseGeometry: inverseGeometry,
    boundingBox: data.boundingBox
      ? formatBackendBboxToArray(data.boundingBox)
      : undefined,
  };
}

export const useLocation = (
  locationId: number,
  options?: Pick<UseQueryOptions, "enabled">
) => {
  const fetch = async () => {
    const { data } = await axios.get<BackendLocation>(
      `/api/locations/${locationId}`
    );
    return deserializeLocation(data);
  };

  return useQuery(["location", locationId], fetch, {
    ...options,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useLocationLatLng = (
  coordinates: LatLng | LatLngLiteral,
  locationType?: string,
  prevLatLng?: LatLng
) => {
  const queryClient = useQueryClient();

  const fetch = async (signal?: AbortSignal) => {
    if (prevLatLng) {
      queryClient.cancelQueries(["location", prevLatLng]);
    }

    const { data } = await axios.get<Location[]>(`/api/locations/points`, {
      params: {
        lat: coordinates.lat,
        lon: coordinates.lng,
        type: locationType,
      },
      signal,
    });
    return data;
  };

  return useQuery(["location", coordinates], ({ signal }) => fetch(signal), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useLocationFlanders = () => {
  async function fetch() {
    const { data } = await axios.get<Location>(`/api/locations/flanders`);
    return data;
  }
  return useQuery(["flandersLocation"], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useUrlLocationType = () => {
  const [params, setParams] = useSearchParams();
  const locationType = params.get("locType");

  function getUpdatedLocationParams(locType: string): URLSearchParams {
    const newParams = new URLSearchParams(params);
    newParams.set("locType", locType);
    return newParams;
  }

  function updateUrlLocationType(locType: string) {
    setParams(getUpdatedLocationParams(locType));
  }

  return { locationType, updateUrlLocationType, getUpdatedLocationParams };
};

export const useClickableLocationType = () => {
  const { locationType } = useUrlLocationType();
  const availableTypes = ["gemeente", "statistischesector", "waterlichaam"];
  if (availableTypes.includes(locationType || "")) return locationType;
  return "gemeente";
};

export function invertGeometry(geojson: Position[][][]) {
  const polygon = multiPolygon(geojson);
  const WORLD_COORDS = [
    [-180, -90],
    [180, -90],
    [180, 90],
    [-180, 90],
    [-180, -90],
  ];
  const outerFeature: Polygon = {
    coordinates: [WORLD_COORDS],
    type: "Polygon",
  };

  const invertedGeojson = difference(outerFeature, polygon);
  return invertedGeojson;
}

export function useUrlLocationBboxParams() {
  const [params] = useSearchParams();

  const urlBbox = params.get("locBbox");

  const [SELng, SELat, NWLng, NWLat] =
    urlBbox?.split(",").map(parseFloat) || [];
  const formattedLocationBbox: LatLngBoundsExpression = [
    [NWLng, NWLat],
    [SELng, SELat],
  ];

  const locationBbox = urlBbox ? formattedLocationBbox : null;

  return { locationBbox };
}

export function useAddressLatLng() {
  const [params] = useSearchParams();
  const addressMarker = params.get("addressMarker");
  const latlngUrl: number[] | null =
    addressMarker?.split(",").map(Number) || null;

  if (
    latlngUrl === null ||
    (isNaN(latlngUrl[0]) && isNaN(latlngUrl[1])) ||
    latlngUrl.length !== 2
  ) {
    return null;
  }
  return new LatLng(latlngUrl[0], latlngUrl[1]);
}
