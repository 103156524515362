import axios from "axios";
import { useQuery } from "react-query";
import { useOutletContext, useParams } from "react-router-dom";
import { Theme } from "../types";
import { orderBy } from "lodash-es";

export const useThemes = () => {
  async function fetch() {
    const { data } = await axios.get<Theme[]>(`/api/themes`);
    return reorganizeThemeLayers(data);
  }
  return useQuery(["themes"], fetch, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

function reorganizeThemeLayers(themes: Theme[]) {
  return themes.map((theme) => ({
    ...theme,
    layerSets: orderBy(theme.layerSets, "serialNumber"),
  }));
}

export const useTheme = (themes: Theme[], themeId: number) => {
  return themes.find((theme) => theme.id === themeId);
};

interface ThemesContext {
  themes: Theme[];
}

export const useThemesContext = () => {
  return useOutletContext<ThemesContext>();
};

interface SelectedThemesContext {
  themes: Theme[];
  currentTheme: Theme;
}

export const useSelectedThemeContext = () => {
  return useOutletContext<SelectedThemesContext>();
};

//Function to get the currently selected Theme
//1. check if theme is in the url
//2. check if there is a default theme
//3. take the first theme
export const useSelectedTheme = (themes: Theme[]): Theme => {
  const { themeId } = useParams();
  const currentThemeId = parseInt(themeId || "");
  const [firstTheme] = themes;

  const currentTheme = themes.find((theme) => theme.id === currentThemeId);
  if (currentTheme) return currentTheme;

  const defaultTheme = themes.find((theme) => theme.defaultSelection);
  if (defaultTheme) return defaultTheme;

  return firstTheme;
};
