import { Slider, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useActiveScenario,
  useEnabledScenarios,
  useMinMaxScenario,
  useScenarioParams,
  useScenarios,
} from "../../hooks/scenarioHooks";
import { Theme } from "../../types";

interface Props {
  currentTheme: Theme;
}

export function TimeLineSlider(props: Props) {
  const { currentTheme } = props;
  const { data: enabledScenarios } = useEnabledScenarios(currentTheme);
  const { data: scenario } = useActiveScenario(currentTheme);
  const { data: themeScenarios } = useScenarios(currentTheme.id);
  const { data: { min, max } = { min: 0, max: 0 } } =
    useMinMaxScenario(currentTheme);
  const { updateScenarioParams } = useScenarioParams();
  const { t } = useTranslation();
  const isDisabled = (enabledScenarios || []).length === 0 || !scenario;
  function onChange(year: number | number[]) {
    if (!Array.isArray(year)) updateScenarioParams(year);
  }

  useEffect(() => {
    if (!enabledScenarios || !scenario || !themeScenarios) return;
    if (
      enabledScenarios.some(
        (enabledScenario) =>
          enabledScenario.scenario.year === scenario.scenario.year
      )
    )
      return;
    updateScenarioParams(themeScenarios.defaultYear);
  }, [enabledScenarios, scenario, themeScenarios, updateScenarioParams]);

  return (
    <Styles>
      <Slider
        disabled={isDisabled}
        onChangeCommitted={(e, value) => onChange(value)}
        marks={(enabledScenarios || []).map((scenario) => {
          return {
            value: scenario.scenario.year,
            label: !scenario.scenario.present
              ? scenario.scenario.year
              : t("label.current"),
          };
        })}
        value={scenario ? scenario.scenario.year : undefined}
        step={null}
        min={min}
        max={max}
      />
      {scenario && !isDisabled && (
        <Typography variant="body2" component="strong">
          {scenario.scenario.climateScenario}
        </Typography>
      )}
      {isDisabled && (
        <Typography variant="body2" component="strong">
          {t("label.noScenariosFound")}
        </Typography>
      )}
    </Styles>
  );
}

const Styles = styled(Stack)`
  flex-direction: column;
  align-items: center;

  .MuiSlider-rail {
    color: ${({ theme }) => theme.palette.grey[300]};

    &:before,
    &:after {
      display: block;
      content: "";
      background-color: inherit;
      height: inherit;
      width: ${({ theme }) => theme.spacing(1.5)};
      position: absolute;
    }
    &:before {
      left: ${({ theme }) => theme.spacing(-1.5)};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:after {
      right: ${({ theme }) => theme.spacing(-1.5)};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .MuiSlider-thumb:before {
    box-shadow: unset;
  }
  .MuiSlider-track {
    display: none;
  }
  .MuiSlider-mark {
    height: ${({ theme }) => theme.spacing(2.5)};
    background-color: ${({ theme }) => theme.palette.grey[600]};
    transform: translate(-1px, 0%);
    top: calc(50% - 2px);
  }
  .MuiSlider-root {
    flex-grow: 1;
    min-width: ${({ theme }) => theme.spacing(30)};
  }
`;
