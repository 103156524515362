import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export async function customPost<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  try {
    const response: R = await axios.post(url, data, config);
    return Promise.resolve(response);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      throw error.response.data;
    }
    return Promise.reject(error);
  }
}

export async function customPut<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  try {
    const response: R = await axios.put(url, data, config);
    return Promise.resolve(response);
  } catch (error) {
    if (axios.isAxiosError(error)) throw error.response?.data;
    return Promise.reject(error);
  }
}

export async function customDelete<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  try {
    const response: R = await axios.delete(url, config);
    return Promise.resolve(response);
  } catch (error) {
    if (axios.isAxiosError(error)) throw error.response?.data;
    return Promise.reject(error);
  }
}

export async function customGet<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  try {
    const response: R = await axios.get(url, config);
    return Promise.resolve(response);
  } catch (error) {
    if (axios.isAxiosError(error)) throw error.response?.data;
    return Promise.reject(error);
  }
}
