import { useTheme } from "@mui/material";
import { round } from "lodash-es";
import {
  LegendColorMapRampEntry,
  LegendSymbolizerRasterRamp,
} from "../../../hooks/legendHooks";

interface Props {
  symbolizer: LegendSymbolizerRasterRamp;
}

export function LegendSymbolizerItemRasterRamp(props: Props) {
  const {
    symbolizer: { Raster },
  } = props;
  const theme = useTheme();
  const fontSize = theme.typography.fontSize;
  const fontFamily = theme.typography.fontFamily;
  const {
    colormap: { entries },
    opacity,
  } = Raster;
  const { length } = entries;
  const lastIndex = length - 1;
  const height = fontSize * length * 1.5;
  const entryHeight = height / length;
  const hash = entries.reduce((hash: string, current) => {
    return `${hash}-${current.color.substring(1, 7)}-${current.quantity}`;
  }, "ramp");

  function renderLabel(entry: LegendColorMapRampEntry, index: number) {
    const parts = new Array<string>();

    parts.push(entry.label || round(entry.quantity, 2).toLocaleString());
    return parts.join(" ");
  }

  return (
    <svg x="0" y="0" height={`${height}px`}>
      <defs>
        <linearGradient id={hash} x1="0" x2="0" y1="0" y2="1" opacity={opacity}>
          {entries.map((entry, i) => (
            <stop
              key={`${entry.quantity}-stop`}
              offset={(i / lastIndex) * 100 + "%"}
              stopColor={entry.color}
            />
          ))}
        </linearGradient>
      </defs>
      <rect
        x="0"
        y="0"
        width="40"
        height={`${height}px`}
        fill={`url(#${hash})`}
        id="legendOriginal"
      />
      {entries.map((entry, i) => (
        <text
          key={`${entry.quantity}-label`}
          x="50"
          y={`${entryHeight * (i + 0.75)}px`}
          fontFamily={fontFamily}
          fontSize={`${fontSize}px`}
        >
          {renderLabel(entry, i)}
        </text>
      ))}
    </svg>
  );
}
