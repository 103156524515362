import { ReactElement, useState, SyntheticEvent } from "react";
import { CardMedia, CardMediaProps, Skeleton, styled } from "@mui/material";

interface Props extends CardMediaProps<"img"> {
  defaultIcon: ReactElement;
}

export function MapSetCardMediaLoader(props: Props) {
  const { defaultIcon, src, image, ...mediaProps } = props;
  const source = src || image || "";
  const [loadState, setLoadState] = useState<"init" | "error" | "completed">(
    source ? "init" : "error"
  );

  return (
    <Styles sx={{ height: mediaProps.height + "px" }} className={loadState}>
      {loadState === "init" && (
        <Skeleton
          variant="rectangular"
          height={mediaProps.height}
          width="100%"
        />
      )}
      {loadState !== "completed" && defaultIcon}
      {source !== "" && source !== undefined && (
        <CardMedia
          component="img"
          className={loadState}
          color="primary"
          onLoad={(event: SyntheticEvent<HTMLImageElement>) =>
            setLoadState("completed")
          }
          onError={(event: SyntheticEvent<HTMLImageElement>) =>
            setLoadState("error")
          }
          src={source}
          {...mediaProps}
        />
      )}
    </Styles>
  );
}

const Styles = styled("div")`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  justify-content: center;
  align-items: center;

  &.init {
    position: relative;
    .MuiSkeleton-root {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.error {
    .MuiSkeleton-root {
      display: none;
    }
  }

  img {
    will-change: opacity;
    &.init {
      position: absolute;
      opacity: 0;
      transition: opacity 320ms ease-in;
    }
    &.error {
      display: none;
    }
    &.completed {
      opacity: 1;
      transition: opacity 320ms ease-in;
    }
  }
`;
