import { useRef, useState } from "react";
import {
  Button,
  Fab,
  IconButton,
  Link,
  Popover,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear, Link as LinkIcon } from "@mui/icons-material";
import { ControlOptions } from "leaflet";
import { useSnackbar } from "notistack";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

import { MapControlBase } from "./MapControlBase";
import { useTranslation } from "react-i18next";

interface Props extends ControlOptions {}

export function ShareControl(props: Props) {
  const { ...controlProps } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const SelectLayerRef = useRef<HTMLButtonElement>(null!);
  const link = window.location.href;

  function copyUrlToClipboard() {
    navigator.clipboard.writeText(link);
    enqueueSnackbar(t("label.LinkSuccessfullyCopied"), { variant: "success" });
  }

  return (
    <MapControlBase {...controlProps}>
      <Tooltip placement="left" title={t<string>("label.share")}>
        <Fab
          size="small"
          variant="circular"
          onClick={() => setIsOpen((prev) => !prev)}
          ref={SelectLayerRef}
          color={isOpen ? "primary" : "default"}
        >
          <LinkIcon />
        </Fab>
      </Tooltip>

      <StyledPopover
        disablePortal
        className="transform-popover"
        anchorEl={SelectLayerRef.current}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsOpen((prev) => !prev)}
      >
        <div className="title-bar">
          <Typography variant="subtitle2">{t("label.shareThisMap")}</Typography>
          <IconButton onClick={() => setIsOpen((prev) => !prev)}>
            <Clear fontSize="small" />
          </IconButton>
        </div>
        <div className="field-bar">
          <TextField
            variant="outlined"
            aria-readonly
            size="small"
            value={link}
          />
          <Link component={Button} onClick={copyUrlToClipboard}>
            Kopieer
          </Link>
        </div>
        <div className="socials">
          <TwitterShareButton url={link}>
            <TwitterIcon size={32} />
          </TwitterShareButton>
          <FacebookShareButton url={link}>
            <FacebookIcon size={32} />
          </FacebookShareButton>
          <LinkedinShareButton url={link}>
            <LinkedinIcon size={32} />
          </LinkedinShareButton>
        </div>
      </StyledPopover>
    </MapControlBase>
  );
}

const StyledPopover = styled(Popover)`
  transform: translateX(-30px);

  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .field-bar {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
    justify-content: space-between;
  }
  .MuiPaper-root {
    padding: ${({ theme }) => theme.spacing(1, 2)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
  }
  .socials {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(1)};
  }
`;
