import { Skeleton, styled } from "@mui/material";
import { random } from "lodash-es";

export function TimelineSkeleton() {
  return (
    <Styles>
      <Skeleton className="track" height={5} width="240px" />
      <Skeleton className="thumb" variant="circular" width={20} height={20} />
      <Skeleton className="info" height={15} width={100} />
    </Styles>
  );
}

const Styles = styled("div")`
  position: relative;
  height: 50px;
  width: 240px;

  .track {
    position: absolute;
    top: 7px;
  }
  .thumb {
    position: absolute;

    left: ${random(10, 240)}px;
  }
  .info {
    position: absolute;
    top: 25px;
    margin-left: 70px;
  }
`;
