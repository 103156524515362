import { styled, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { IndicatorDataFilter, IndicatorFilterData } from "../../types";

import { PaddedPaper } from "../surfaces/PaddedPaper";
import { DownloadButton } from "./DownloadButton";
import { DownloadAllButton } from "./DownloadAllButton";
import { ResetDataFilterButton } from "./ResetDataFilterButton";

interface Props {
  filterData: IndicatorDataFilter;
  setFilterData: (value: IndicatorFilterData) => void;
}

export function DataHeader(props: Props) {
  const { filterData, setFilterData } = props;
  const { t } = useTranslation();
  return (
    <StyledPaddedPaper elevation={0}>
      <Typography className="align-left" variant="h2">
        {t("label.dataTable")}
      </Typography>
      <ResetDataFilterButton setFilterData={setFilterData} />
      <DownloadButton
        icon={<Download fontSize="small" />}
        label={t("label.exportCurrentSelection")}
        filterData={filterData}
      />
      <DownloadAllButton />
    </StyledPaddedPaper>
  );
}

const StyledPaddedPaper = styled(PaddedPaper)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  .align-left {
    margin-right: auto;
  }
`;
