import { useState } from "react";
import { Fab, Tooltip } from "@mui/material";
import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ControlOptions } from "leaflet";
import { MapControlBase } from "./MapControlBase";
import { FullScreenGuard } from "../guards/FullScreenGuard";

interface Props extends ControlOptions {}

export function FullscreenControl(props: Props) {
  const { ...controlProps } = props;
  const { t } = useTranslation();
  const [fullscreenState, setFullscreenState] = useState<boolean>(false);

  const onToggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <MapControlBase {...controlProps}>
      <Tooltip
        placement="left"
        title={
          fullscreenState
            ? t<string>("label.disableFullScreen")
            : t<string>("label.fullScreen")
        }
      >
        <Fab size="small" variant="circular" onClick={onToggleFullscreen}>
          {!fullscreenState && <OpenInFull fontSize="small" />}
          {fullscreenState && <CloseFullscreen fontSize="small" />}
        </Fab>
      </Tooltip>

      <FullScreenGuard onToggle={() => setFullscreenState((prev) => !prev)} />
    </MapControlBase>
  );
}
