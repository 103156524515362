import { List, ListItem, Skeleton } from "@mui/material";

export function ListSkeleton() {
  return (
    <List dense>
      <ListItem>
        <Skeleton width="100%" />
      </ListItem>
      <ListItem>
        <Skeleton width="100%" />
      </ListItem>
      <ListItem>
        <Skeleton width="100%" />
      </ListItem>
      <ListItem>
        <Skeleton width="100%" />
      </ListItem>
      <ListItem>
        <Skeleton width="100%" />
      </ListItem>
    </List>
  );
}
