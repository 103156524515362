import { LatLngBoundsLiteral } from "leaflet";
import { BackendBoundingBox } from "../types/backend";

export function formatBackendBboxToArray(
  bbox: BackendBoundingBox
): LatLngBoundsLiteral {
  return [
    [bbox.UpperRight.Lat_WGS84, bbox.UpperRight.Lon_WGS84],
    [bbox.LowerLeft.Lat_WGS84, bbox.LowerLeft.Lon_WGS84],
  ];
}

export function formatSearchLocationUrl(
  pathname: string,
  locationId: number,
  themeId?: string
) {
  if (pathname.includes("/location/")) {
    const [, , , , ...restPath] = pathname.split("/");
    return `/${themeId}/location/${locationId}/${restPath.join("/")}`;
  }
  return `/${themeId}/location/${locationId}/graphs`;
}
