import { useMemo } from "react";
import { BoundsLiteral, LatLngLiteral } from "leaflet";
import { useSearchParams } from "react-router-dom";
import { MapModeMapLayout, MapModeSideNavMapLayout } from "../types";
import { mapURLSearchParams } from "../utils/SearchParamUtils";

export const initialBounds: BoundsLiteral = [
  [50.658897, 2.489921],
  [51.55559, 5.923148],
];

export const useMapPoint = (): [
  LatLngLiteral | null,
  (point: LatLngLiteral | null) => void
] => {
  const [params, setParams] = useSearchParams();
  const rawPoint = params.get("point");
  const point = useMemo(() => {
    if (!rawPoint) return null;
    return arrayToLatLng(rawPoint.split(",").map(parseFloat));
  }, [rawPoint]);

  function arrayToLatLng(pointArray: number[]): LatLngLiteral {
    const [lat, lng] = pointArray;
    return { lat, lng };
  }

  function updateParams(point: LatLngLiteral | null) {
    const newParams = mapURLSearchParams(params, ["point"]);
    if (point) {
      newParams.delete("addressMarker");
      newParams.set("point", [point.lat, point.lng].join(","));
    }

    setParams(newParams);
  }

  return [point, updateParams];
};

export function useCurrentMapBounds(initial = initialBounds): BoundsLiteral {
  const [queryParams] = useSearchParams();
  const bounds = queryParams.get("bounds");
  if (!bounds) return initial;
  const [SELng, SELat, NWLng, NWLat] = bounds?.split(",").map(parseFloat);
  return [
    [SELat, SELng],
    [NWLat, NWLng],
  ];
}

export function useMapModeMapLayout() {
  const [params, setParams] = useSearchParams();
  //mml - map mode layout
  //mapMode defaults to search on map
  const mapMode = params.get("mml") || "som";

  const currentMapMode = isMapLayoutMapMode(mapMode) ? mapMode : "som";

  function updateMapMode(mapMode: MapModeMapLayout) {
    const newParams = mapURLSearchParams(params, ["mml"]);
    newParams.set("mml", mapMode);
    newParams.delete("point");
    setParams(newParams);
  }

  return { currentMapMode, updateMapMode };
}

export function useMapModeSidenavMapLayout() {
  const [params, setParams] = useSearchParams();
  //mmsl - map mode sidenav layout
  //mapMode defaults to search by location type
  const mapMode = params.get("mmsl") || "slt";
  const currentMapMode = isSideNavLayoutMapMode(mapMode) ? mapMode : "slt";
  function updateMapMode(mapMode: MapModeSideNavMapLayout) {
    const newParams = mapURLSearchParams(params, ["mmsl"]);
    newParams.set("mmsl", mapMode);
    newParams.delete("point");
    setParams(newParams);
  }
  return { currentMapMode, updateMapMode };
}

function isSideNavLayoutMapMode(
  mapMode: string
): mapMode is MapModeSideNavMapLayout {
  const validMapModes = ["slt", "som", "spi"];
  return validMapModes.includes(mapMode);
}

function isMapLayoutMapMode(mapMode: string): mapMode is MapModeMapLayout {
  const validMapModes = ["som", "spi"];
  return validMapModes.includes(mapMode);
}
