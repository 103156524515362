import { ChangeEvent, useState } from "react";

import {
  useIndicatorData,
  useInitialFilterData,
} from "../../hooks/indicatorDataHooks";

import {
  DataFilter,
  IndicatorDataFilter,
  IndicatorFilterData,
  Location,
  SortingList,
  Theme,
} from "../../types";
import { PaddedPaper } from "../surfaces/PaddedPaper";

import { DataHeader } from "./DataHeader";
import { DataPagination } from "./DataPagination";
import { DataTable } from "./DataTable";

interface Props {
  currentTheme: Theme;
  themes: Theme[];
  location: Location;
}

export function LoadData(props: Props) {
  const { currentTheme, location, themes } = props;
  const initialValues = useInitialFilterData(currentTheme, location, themes);

  const [filterData, setFilterData] =
    useState<IndicatorFilterData>(initialValues);

  const { data: tableData, isFetching } = useIndicatorData(filterData);

  function onSort(sortingList: SortingList[]) {
    setFilterData({ ...filterData, sortingList: [...sortingList] });
  }

  function onToggleFilter(
    value: DataFilter,
    property: keyof IndicatorDataFilter
  ) {
    setFilterData((prev: IndicatorFilterData) => {
      return {
        ...prev,
        pageIndex: 0,
        indicatorDataFilter: {
          ...prev.indicatorDataFilter,
          [property]: { ...value },
        },
      };
    });
  }

  function onPaginate(event: ChangeEvent<unknown>, page: number) {
    setFilterData({ ...filterData, pageIndex: page - 1 });
  }

  return (
    <>
      {tableData && (
        <>
          <DataHeader
            filterData={filterData.indicatorDataFilter}
            setFilterData={setFilterData}
          />
          <PaddedPaper
            sx={{
              flexGrow: 1,
              overflow: "auto",
              paddingTop: 0,
              paddingBottom: 0,
            }}
            elevation={0}
          >
            <DataTable
              tableData={tableData}
              onToggleFilter={onToggleFilter}
              onSort={onSort}
              isLoading={isFetching}
              sortingList={filterData.sortingList}
              filterData={filterData.indicatorDataFilter}
            />
          </PaddedPaper>
          <DataPagination
            totalCount={tableData.indicatorDataPage.totalCount}
            offset={filterData.pageIndex}
            limit={tableData.indicatorDataPage.limit}
            onPaginate={onPaginate}
          />
        </>
      )}
    </>
  );
}
