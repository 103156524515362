import { useState } from "react";
import { styled } from "@mui/material";
import { Help } from "@mui/icons-material";

interface Props {
  iconUrl: string;
}

export function MapThemeNavigationIconImage(props: Props) {
  const { iconUrl } = props;
  const [iconState, setIconState] = useState<"init" | "completed" | "error">(
    "init"
  );
  return (
    <>
      {iconState !== "error" && (
        <StyledImage
          src={iconUrl}
          className={`icon ${iconState}`}
          aria-label="theme-icon"
          alt="theme-icon"
          onLoad={() => setIconState("completed")}
          onError={() => setIconState("error")}
        />
      )}
      {iconState === "error" && <Help />}
    </>
  );
}

const StyledImage = styled("img")`
  .how-to-link {
    margin-left: auto;
    text-decoration: underline;
    font-weight: bold;
  }
  a {
    text-decoration: none;
    font-size: 13px;
    color: ${({ theme }) => theme.palette.text.primary};
    &:hover {
      text-decoration: underline;
    }
    &.active {
      color: ${({ theme }) => theme.palette.primary.main};
      font-weight: bold;
    }
  }
`;
