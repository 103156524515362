import { Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useIndicatorRepresentations } from "../../hooks/graphHooks";

import { Theme } from "../../types";
import { PaddedPaper } from "../surfaces/PaddedPaper";
import { Graph } from "./Graph";
import { useMapSetSelection } from "../../hooks/mapSetHooks";

interface Props {
  currentTheme: Theme;
  themes: Theme[];
  locationId: number;
}

export function Graphs(props: Props) {
  const { currentTheme, themes, locationId } = props;
  const { t } = useTranslation();
  const { effectsIndicators, impactsIndicators } = useIndicatorRepresentations(
    currentTheme,
    themes
  );

  const { mapSetSelections, updateMapSetSelection } =
    useMapSetSelection(currentTheme);

  return (
    <StyledPaddedPaper elevation={0}>
      <Grid container spacing={6}>
        <Grid item container xs={12} xl={6} className="graph-column">
          <Grid item xs={12}>
            <Typography variant="h1" gutterBottom>
              {t("label.effects")}
            </Typography>
          </Grid>
          {effectsIndicators.length === 0 && (
            <Grid item xs={12}>
              <Typography>{t("label.noIndicatorsFound")}</Typography>
            </Grid>
          )}
          {effectsIndicators.length > 0 &&
            effectsIndicators.map((indicator, index) => (
              <Grid item xs={12} key={`${indicator.id}-${locationId}`}>
                <Graph
                  representation={indicator}
                  locationId={locationId}
                  mapSetSelections={mapSetSelections}
                  updateMapSetSelection={updateMapSetSelection}
                />
              </Grid>
            ))}
        </Grid>
        <Grid item container xs={12} xl={6} className="graph-column">
          <Grid item xs={12}>
            <Typography variant="h1" gutterBottom>
              {t("label.impacts")}
            </Typography>
          </Grid>
          {impactsIndicators.length === 0 && (
            <Grid item xs={12}>
              <Typography>{t("label.noIndicatorsFound")}</Typography>
            </Grid>
          )}
          {impactsIndicators.length > 0 &&
            impactsIndicators.map((indicator) => (
              <Grid item xs={12} key={`${indicator.id}-${locationId}`}>
                <Graph
                  mapSetSelections={mapSetSelections}
                  updateMapSetSelection={updateMapSetSelection}
                  representation={indicator}
                  locationId={locationId}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </StyledPaddedPaper>
  );
}

const StyledPaddedPaper = styled(PaddedPaper)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(-4)};
  overflow: auto;

  .graph-column {
    align-content: flex-start;

    & > .MuiGrid-item {
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
  }
`;
