import GlobalStyles from "@mui/material/GlobalStyles";
import { darken } from "@mui/system";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";

import FlandersSansBold from "../assets/fonts/flanders-sans-bold.ttf";
import FlandersSansLight from "../assets/fonts/flanders-sans-light.ttf";
// import FlandersSansMedium from "../assets/fonts/flanders-sans-medium.ttf";
import SansRegular from "../assets/fonts/2ca7342.woff2"; //"../assets/fonts/flanders-sans-regular.woff";
import SansMedium from "../assets/fonts/ee18de6.woff2"; //"../assets/fonts/flanders-sans-medium.woff";

export const AppGlobalStyles = () => {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={
        css`
          @font-face {
            font-family: "FlandersSans";
            font-style: "normal";
            font-display: "swap";
            font-weight: 700;
            src: local("flanders-sans-bold"),
              url(${FlandersSansBold}) format("truetype");
          }
          @font-face {
            font-family: "FlandersSans";
            font-style: "normal";
            font-display: "swap";
            font-weight: 300;
            src: local("flanders-sans-light"),
              url(${FlandersSansLight}) format("truetype");
          }
          @font-face {
            font-family: "FlandersSans";
            font-style: "normal";
            font-display: "swap";
            font-weight: 400;
            src: local("2ca7342"), url(${SansRegular}) format("woff2");
          }
          @font-face {
            font-family: "FlandersSans";
            font-style: "normal";
            font-display: "swap";
            font-weight: 600;
            src: local("ee18de6"), url(${SansMedium}) format("woff2");
          }

          ::-webkit-scrollbar {
            width: 0.5em;
            height: 0.5em;
            background-color: #f5f5f5;
          }
          ::-webkit-scrollbar-track {
            background-color: ${theme.palette.grey[300]};
          }
          ::-webkit-scrollbar-thumb {
            border-radius: 1em;
            width: 0.75em;
            background-color: ${theme.palette.primary.main};
            &:hover {
              background-color: ${darken(theme.palette.primary.main, 0.2)};
            }
          }
          ::selection {
            color: ${theme.palette.getContrastText(theme.palette.primary.main)};
            background: ${theme.palette.primary.main};
          }
          body,
          html {
            scrollbar-width: "thin";
            scrollbar-color: ${theme.palette.primary.main +
            " " +
            theme.palette.background.default};
          }
          svg {
            fill: currentColor;
          }
          body,
          #app,
          #root {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: stretch;
          }
          .MuiSkeleton-root {
            transform: unset;
            transform-origin: unset;
          }
          .leaflet-container {
            font: unset;
          }
          .MuiLink-root {
            cursor: pointer;
          }
          strong,
          strong.MuiTypography-root {
            font-weight: 900;
          }

          .sr-only {
            visibility: hidden;
          }
        `.styles
      }
    />
  );
};
