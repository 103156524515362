import { useState } from "react";
import {
  alpha,
  Collapse,
  Fab,
  IconButton,
  styled,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { Close, FormatListBulleted } from "@mui/icons-material";
import { ControlOptions } from "leaflet";
import { useMap } from "react-leaflet";
import { useTranslation } from "react-i18next";

import { LegendAccordion } from "./LegendAccordion";
import { MapControlBase } from "../MapControlBase";
import { PaddedPaper } from "../../surfaces/PaddedPaper";
import { useFilteredMapSets } from "../../../hooks/mapSetHooks";
import { Theme } from "../../../types";
import { useActiveScenario } from "../../../hooks/scenarioHooks";

interface Props extends ControlOptions {
  themes: Theme[];
  currentTheme: Theme;
}
export function LegendControl(props: Props) {
  const { themes, currentTheme, ...controlProps } = props;
  const map = useMap();
  const { t } = useTranslation();
  const [showLegend, setShowLegend] = useState<boolean>(true);
  const mapSize = map.getSize();
  const { data: activeScenario } = useActiveScenario(currentTheme);

  const mapSets = useFilteredMapSets(themes, currentTheme);

  function onToggleLegend() {
    setShowLegend((prev) => !prev);
  }

  return (
    <MapControlBase {...controlProps}>
      <Styles mapWidth={mapSize.x}>
        <Zoom in={!showLegend} unmountOnExit>
          <Tooltip placement="left" title={t<string>("label.legend")}>
            <Fab size="small" variant="circular" onClick={onToggleLegend}>
              <FormatListBulleted />
            </Fab>
          </Tooltip>
        </Zoom>
        <Collapse in={showLegend} className="panel-wrapper" unmountOnExit>
          <PaddedPaper className="container">
            <div className="header">
              <Typography variant="panel-title" gutterBottom>
                {t("label.legend")}
              </Typography>
              <IconButton onClick={onToggleLegend} edge="end" size="small">
                <Close fontSize="small" />
              </IconButton>
            </div>
            <div className="accordion-container">
              {mapSets.length > 0 &&
                activeScenario &&
                mapSets.map((mapSet) => {
                  return (
                    <LegendAccordion
                      key={mapSet.id}
                      mapSet={mapSet}
                      activeScenario={activeScenario}
                    />
                  );
                })}
              {mapSets.length === 0 && (
                <Typography>{t("label.noActiveLayers")}</Typography>
              )}
            </div>
          </PaddedPaper>
        </Collapse>
      </Styles>
    </MapControlBase>
  );
}

interface StyleProps {
  mapWidth: number;
}

const Styles = styled("div", {
  shouldForwardProp: (prop) => ["mapWidth"].includes(prop.toString()) === false,
})<StyleProps>`
  & > * {
    float: right;
  }
  .panel-wrapper {
    // transform: translate(
    //   ${({ theme }) => theme.spacing(-3)},
    //   ${({ theme, mapWidth }) => theme.spacing(mapWidth > 1100 ? 19 : 5)}
    // );
  }
  .container {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 1)};
    max-width: 350px;
  }
  .accordion-container {
    // max-height: 62vh;
    max-height: 35vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: ${({ theme }) => theme.spacing(-2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hidden {
  }

  .MuiCollapse-root {
    width: 100%;
  }
`;
