import {
  Navigate as NavigateBase,
  NavigateProps,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

interface Props extends NavigateProps {}

export function Navigate(props: Props) {
  const location = useLocation();
  const { to, ...rest } = props;
  const path = useResolvedPath(to);
  const mergedSearch = [
    path.search.split("?").join(""),
    location.search
      .split("?")
      .join("")
      .split("&")
      .filter(
        (param) => !param.startsWith("reffer") && !param.startsWith("?reffer")
      )
      .join("&"),
  ]
    .filter((queryParams) => !!queryParams)
    .join("&");

  const mergedLocation =
    typeof to === "string"
      ? {
          ...location,
          ...path,
          search: mergedSearch ? `?${mergedSearch}` : "",
        }
      : { ...location, ...to };

  return <NavigateBase to={{ ...mergedLocation }} {...rest} />;
}
