import { Suspense } from "react";
import {
  Alert,
  Link,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import Plot from "react-plotly.js";

import { useGraphData } from "../../hooks/graphHooks";

import { GraphData, Indicator, Selection } from "../../types";
import { GraphErrorFallback } from "../boundary/GraphErrorFallback";
import { useTranslation } from "react-i18next";
import { CustomLegend } from "./CustomLegend";
import { StyledMarkdown } from "../layout/StyledMarkdown";

interface Props {
  representation: Indicator;
  locationId: number;
  mapSetSelections: Selection[];
  updateMapSetSelection: (mapSetSelection: Selection[]) => void;
}

export function Graph(props: Props) {
  return (
    <ErrorBoundary FallbackComponent={GraphErrorFallback}>
      <Suspense
        fallback={
          <StyledStack spacing={1} direction="column">
            <Skeleton width="100%" height="20px" />
            <Skeleton width="100%" height="280px" />
          </StyledStack>
        }
      >
        <GraphLoad {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}

const StyledStack = styled(Stack)`
  .MuiSkeleton-root {
    transform: unset;
    transform-origin: unset;
  }
`;

function GraphLoad(props: Props) {
  const {
    representation,
    locationId,
    updateMapSetSelection,
    mapSetSelections,
  } = props;
  const { data } = useGraphData(representation.id, locationId);

  if (data)
    return (
      <GraphDisplay
        updateMapSetSelection={updateMapSetSelection}
        mapSetSelections={mapSetSelections}
        data={data}
        indicator={representation}
      />
    );
  return null;
}

interface DisplayProps {
  data: GraphData;
  indicator: Indicator;
  mapSetSelections: Selection[];
  updateMapSetSelection: (mapSetSelection: Selection[]) => void;
}

function GraphDisplay(props: DisplayProps) {
  const { data, indicator, updateMapSetSelection, mapSetSelections } = props;
  const { t } = useTranslation();

  function onAddMapSet(mapSetId: number) {
    if (!mapSetId) return;
    updateMapSetSelection([
      {
        id: mapSetId,
        opacity: 0.8,
        type: "ms",
        added: 1,
        visible: 1,
      },
      ...mapSetSelections,
    ]);
  }

  function onRemoveMapSet(mapSetId: number) {
    updateMapSetSelection(
      mapSetSelections.filter((selection) => selection.id !== mapSetId)
    );
  }

  const layerSet = indicator?.layerSet;
  const isActive = layerSet
    ? mapSetSelections.find(
        (mapSetSelection) => mapSetSelection.id === layerSet.id
      )
    : false;

  return (
    <Styles>
      {!data.chartdata && (
        <div className="no-data-found">
          <Tooltip
            title={<StyledMarkdown content={data.indicator.description} />}
          >
            <Typography className="graph-title" variant="h1" gutterBottom>
              {data.indicator.name}
            </Typography>
          </Tooltip>

          <Alert severity="info">{t("label.thisIndicatorHasNoData")}</Alert>
        </div>
      )}
      {data.chartdata && data.chartdata && (
        <div className="graph-container">
          <Tooltip
            title={<StyledMarkdown content={data.indicator.description} />}
          >
            <Typography className="graph-title" variant="h1" gutterBottom>
              {data.indicator.name}
            </Typography>
          </Tooltip>
          {layerSet && !isActive && (
            <Link gutterBottom onClick={() => onAddMapSet(layerSet.id)}>
              Bekijk op kaart
            </Link>
          )}
          {layerSet && isActive && (
            <Link gutterBottom onClick={() => onRemoveMapSet(layerSet.id)}>
              Verwijder van kaart
            </Link>
          )}
          <CustomLegend graphLegendItems={data.legendList} />
          <Plot
            useResizeHandler={true}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "300px",
              minHeight: "300px",
            }}
            data={data.chartdata.data}
            layout={{
              ...data.chartdata.layout,
              // bargap: 0.3,
              // bargroupgap: 0.5,

              autosize: true,

              title: {
                text: undefined,
              },
              yaxis: {
                ...data.chartdata.layout.yaxis,
                rangemode: "tozero",
                automargin: true,
                showline: true,
                showticklabels: true,
              },
              xaxis: {
                ...data.chartdata.layout.xaxis,
                automargin: true,
              },
              hoverlabel: {
                bgcolor: "#FFF",
                namelength: -1,
              },
              hovermode: "x unified",
              margin: { t: 0, b: 40, l: 10, r: 5 },
              showlegend: false,
            }}
            config={{
              autosizable: true,
              displayModeBar: false,
              showTips: false,
              scrollZoom: false,
              responsive: true,
            }}
          />
        </div>
      )}
    </Styles>
  );
}

const Styles = styled("div")`
  .graph-title {
    font-size: 13px;
  }

  .no-data-found {
    display: flex;
    flex-direction: column;
  }

  .MuiAlert-root {
    align-items: center;
    padding: 0;
    background-color: transparent;
  }

  .graph-container {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
  }
`;
