import { Room } from "@mui/icons-material";
import { styled } from "@mui/material";
import { LatLng } from "leaflet";
import { Popup } from "react-leaflet";
import { Location } from "../../../types";
import { LocationTypeSearchResultList } from "./LocationTypeSearchResultList";

interface Props {
  latLng: LatLng;
  locations: Location[];
  resetLatLng: () => void;
}
export function LocationTypePopup(props: Props) {
  const { latLng, locations, resetLatLng } = props;

  return (
    <StyledPop position={latLng}>
      <LocationTypeSearchResultList
        locations={locations}
        resetLatLng={resetLatLng}
      />
      <Room fontSize="large" color="success" />
    </StyledPop>
  );
}

const StyledPop = styled(Popup)`
  .leaflet-popup-tip-container {
    visibility: hidden;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    width: ${({ theme }) => theme.spacing(33)};
  }
  .MuiSvgIcon-root {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: -18px;
  }
`;
