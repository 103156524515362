import { SortingDirection, SortingList } from "../types";
import { cycleState } from "../utils/SortUtils";

export const useSorting = (
  sortingList: SortingList[],
  propertyValue: string
) => {
  const property = sortingList.find((prop) => prop.property === propertyValue);
  const direction = property ? property.direction : undefined;

  function onSortColumn(): SortingList[] {
    if (!property) {
      return [{ property: propertyValue, direction: "asc" }];
    }
    return [
      ...sortingList
        .map((item) => {
          if (item.property === property.property) {
            const updatedDirection: SortingDirection = cycleState(
              ["asc", "desc", undefined],
              direction
            );
            return {
              ...item,
              direction: updatedDirection,
            };
          }
          return item;
        })
        .filter(
          (item) =>
            item.direction !== undefined && item.property === property.property
        ),
    ];
  }
  return { property, onSortColumn };
};
