import { Suspense, useEffect, useState } from "react";
import { Paper, styled } from "@mui/material";
import { Control, ControlOptions } from "leaflet";
import { Theme } from "../../types";
import { TimeLineSlider } from "./TimeLineSlider";
import { MapControlBase } from "./MapControlBase";
import { TimelineSkeleton } from "../skeletons/TimelineSkeleton";

interface Props extends ControlOptions {
  currentTheme: Theme;
}
export function TimeLineControl(props: Props) {
  const { currentTheme, ...controlProps } = props;
  const [timelineContainer, setTimelineContainer] = useState<Control | null>(
    null
  );

  useEffect(() => {
    if (timelineContainer) {
      const container = timelineContainer.getContainer();
      if (container && container.parentElement) {
        container.parentElement.style.right = "0";
        container.style.left = "50%";
        container.style.transform = "translate(-50%, 0)";
      }
    }
  }, [timelineContainer]);

  return (
    <MapControlBase
      className="scenario-control"
      {...controlProps}
      ref={(ref) => setTimelineContainer(ref)}
    >
      <Styles elevation={0} data-tour="step-2">
        <Suspense fallback={<TimelineSkeleton />}>
          <TimeLineSlider currentTheme={currentTheme} />
        </Suspense>
      </Styles>
    </MapControlBase>
  );
}

const Styles = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2, 4.5)};
  border-radius: ${({ theme }) => theme.spacing(3)};
`;
