import { styled } from "@mui/material";
import { Map } from "leaflet";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  map: Map;
  maxWidth: number;
}

interface MetricMeasurements {
  label: string;
  controlWidth: number;
}

export function MetricScale(props: Props) {
  const { map, maxWidth } = props;
  const [measurements, setMeasurements] = useState<MetricMeasurements>({
    label: "1",
    controlWidth: maxWidth,
  });
  const { t } = useTranslation();
  const updateCallback = useCallback(update, [map, maxWidth]);

  useEffect(() => {
    map.on("move", updateCallback);
    map.whenReady(updateCallback);

    return () => {
      map.off("move", updateCallback);
    };
  }, [map, updateCallback]);

  function _getRoundNum(num: number) {
    const pow10 = Math.pow(10, (Math.floor(num) + "").length - 1),
      d = num / pow10;

    const calcedD = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

    return pow10 * calcedD;
  }

  function update() {
    const y = map.getSize().y / 2;

    const maxMeters = map.distance(
      map.containerPointToLatLng([0, y]),
      map.containerPointToLatLng([maxWidth, y])
    );
    const meters = _getRoundNum(maxMeters);
    const label = meters < 1000 ? meters + " m" : meters / 1000 + " km";
    setMeasurements({
      label,
      controlWidth: Math.round(maxWidth * (meters / maxMeters)),
    });
  }

  return (
    <Styles width={measurements.controlWidth}>
      {t("label.scale", measurements)}
      <div className="leaflet-control-scale-line"></div>
    </Styles>
  );
}

interface StyleProps {
  width: number;
}

const Styles = styled("div", {
  shouldForwardProp: (prop) => ["width"].includes(prop.toString()) === false,
})<StyleProps>`
  display: flex;
  gap: 5px;
  align-items: center;

  .leaflet-control-scale-line {
    height: 9px;
    width: ${({ width }) => `${width}px`};
  }
`;
