import { Suspense } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { useSelectedThemeContext } from "../hooks/themeHooks";
import { useLocation } from "../hooks/locationHooks";

import { LoadData } from "../components/data/LoadData";
import { DataPageErrorFallback } from "../components/boundary/DataPageErrorFallback";

export function DataPage() {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { currentTheme, themes } = useSelectedThemeContext();
  const { data: location } = useLocation(+(locationId || ""));

  return (
    <>
      {location && (
        <ErrorBoundary FallbackComponent={DataPageErrorFallback}>
          <Suspense
            fallback={
              <Stack
                justifyContent="center"
                flexGrow={1}
                alignItems="center"
                gap={2}
              >
                <CircularProgress />
                {t("label.loading")}...
              </Stack>
            }
          >
            <LoadData
              currentTheme={currentTheme}
              location={location}
              themes={themes}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </>
  );
}
